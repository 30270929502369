import {Slider} from "@mui/material";

type Props = {
  sasTokenLifetimeInHours: number,
  handleSasTokenLifetimeChange: (event: Event, newValue: number | number[]) => void,
  bandwidthInMB: number,
  handleBandwithChange: (event: Event, newValue: number | number[]) => void,
  isUpload: boolean,
};

const MaxTimeAndBandwidth = ({
                               sasTokenLifetimeInHours, handleSasTokenLifetimeChange, bandwidthInMB, handleBandwithChange, isUpload,
                             }: Props) => {

  const minSasTokenLifetimeInHours = 1;
  const maxSasTokenLifetimeInHours = 168;
  const sliderStep = 1;
  const minBandwidthInMB = 0; //0 is for unlimited bandwidth
  const maxBandwidthInMB = 100;
  const sliderStepBandwith = 10;

  return (
      <>
        <p>Max {isUpload ? 'upload' : 'download'} time: {sasTokenLifetimeInHours} hours</p>
        <Slider value={sasTokenLifetimeInHours} min={minSasTokenLifetimeInHours} max={maxSasTokenLifetimeInHours} step={sliderStep} aria-labelledby='label'
                onChange={handleSasTokenLifetimeChange} aria-label='max-upload-time'/>
        <p> Max bandwidth: {bandwidthInMB === 0 ? 'Unlimited' : bandwidthInMB + 'MB'}</p>
        <Slider value={bandwidthInMB} min={minBandwidthInMB} max={maxBandwidthInMB}
                step={sliderStepBandwith} aria-labelledby='label' onChange={handleBandwithChange} aria-label='max-bandwidth'/>

      </>
  );
};

export default MaxTimeAndBandwidth;