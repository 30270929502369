
import IconWarning from '@mui/icons-material/Warning';
import { CircularProgress, FormControl, NativeSelect, Tooltip } from '@mui/material';
import { ADVANCED, STANDARD } from 'components/constants/machine-template';
import { ConnectedProps, connect } from 'react-redux';
import { RootState } from 'store/reducers';
import { getSiteAvailableMachineTemplatesFromState } from 'store/selectors/machine-template-selectors';
import { AvailableMachineTemplateDTO, ResourceAccessNode } from 'types';
import { Row } from './manage-users-utils';
import styles from './manage-users.module.scss';


const mapState = (state: RootState) => ({
  getSiteAvailableMachineTemplatesFromState: (siteId: number) => getSiteAvailableMachineTemplatesFromState(state, siteId),
});

const connector = connect(mapState);

type ReduxProps = ConnectedProps<typeof connector>;

type OwnProps = {
  localData: Row[],
  updateUser: (rowData: Row) => void,
  value: number,
  isPending: boolean,
  data,
  row: Row,
  isConnectedUserManager: (resourcePath: string, siteList: any[]) => boolean,
  siteList: ResourceAccessNode[],
};

type Props = ReduxProps & OwnProps;

// eslint-disable-next-line
const MachineTemplateSelector = ({ localData, updateUser, value, isPending, data, row, isConnectedUserManager, siteList, getSiteAvailableMachineTemplatesFromState }: Props) => {

  const canEditDefaultMachineTemplate = (rowData: Row) => {
    return isConnectedUserManager(rowData.resourcePath, siteList);
  };

  const isTemplateValid = (machineTemplateId: number, templates: AvailableMachineTemplateDTO[]): boolean => {
    return templates && templates.filter(t => t.id === machineTemplateId).length > 0;
  };

  const handleMachineTemplateChange = (data, rowData: Row, newTemplate) => {
    // eslint-disable-next-line no-param-reassign
    rowData.machineTemplateId = newTemplate;
    // eslint-disable-next-line no-param-reassign
    localData[data.currentTableData[data.rowIndex].index].machineTemplateId = newTemplate;
    updateUser(rowData);
  };

  const getMachineTemplateOptionColor = (machineTemplateId: number, templates: AvailableMachineTemplateDTO[]) => {
    const template = templates?.find(t => t.id == machineTemplateId);
    if (!template) return styles.manage_user__darkRedOption;
    switch (template.name) {
      case ADVANCED:
        return styles.manage_user__lightBlueOption;
      case STANDARD:
        return styles.manage_user__darkSkyBlueOption;
      default:
        return styles.manage_user__darkBlueOption;
    }
  };

  return (
    <div className={styles.manage_user__categoryContainer}>
      <FormControl>
        <NativeSelect
          value={value}
          disabled={!canEditDefaultMachineTemplate(row) || isPending}
          classes={{
            root: getMachineTemplateOptionColor(value, getSiteAvailableMachineTemplatesFromState(row.siteId)),
            select: styles.manage_user__statusLabel,
            disabled: styles.manage_user__inputDisabled,
          }}
          className='user-authority-select'
          onChange={event => handleMachineTemplateChange(data, row, event.target.value)}>
          {getSiteAvailableMachineTemplatesFromState(row.siteId)?.map(machineTemplate => (
            <option value={machineTemplate.id} key={machineTemplate.id}>
              {machineTemplate.name.toUpperCase()}
            </option>
          ))}
        </NativeSelect>
      </FormControl>
      {isPending && (
        <CircularProgress
          size={24}
          style={{ marginLeft: 15, position: 'relative', top: 4 }} />
      )}
      <div className={styles.manage_user__warningCategoryIconContainer}>
        {!isTemplateValid(value, getSiteAvailableMachineTemplatesFromState(row.siteId)) && (
          <Tooltip title='Selected category does not exists for the corresponding site'>
            <IconWarning className={styles.manage_user__errorColor} />
          </Tooltip>
        )}
      </div>
    </div>
  );
};

export default connector(MachineTemplateSelector);