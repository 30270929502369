import { CreateProjectDTO, ProjectInfoDTO, ResourceAccessNode } from 'types';
import instance from './axios';

import toastService from './toast-service';

export default {
  addProject(body: CreateProjectDTO): Promise<ResourceAccessNode> {
    return instance
      .post('/api/resource/project', body)
      .then(res => {
        toastService.success('Project added successfully');
        return res.data;
      })
      .catch((error) => {
        toastService.error(error?.response?.data?.message);
        return Promise.reject();
      });
  },
  deleteProject(projectId) {
    return instance
      .delete(`/api/resource/project/${projectId}`)
      .then(() => {
        toastService.success('Project deleted successfully');
      })
      .catch((error) => {
        toastService.error(error?.response?.data?.message);
        return Promise.reject();
      });
  },
  getProjectInfo(projectId): Promise<ProjectInfoDTO> {
    return instance.get(`api/resource/project-info/${projectId}`).then(res => res.data);
  },
};
