import siteService from 'services/site-service';
import { AppThunk } from 'store/reducers';
import { BillingInfoLightDTO } from 'types';

export const ERROR_GET_USER_BILLING_LIGHT = 'ERROR_GET_USER_BILLING_LIGHT';
export const RECEIVE_USER_BILLING_LIGHT = 'RECEIVE_USER_BILLING_LIGHT';

export type ReceiveUserBillingLightAction = { 
  type: typeof RECEIVE_USER_BILLING_LIGHT, 
  userCost: BillingInfoLightDTO
};
export type ErrorGetUserBillingLightAction  = { 
  type: typeof ERROR_GET_USER_BILLING_LIGHT 
};

export const receiveUserBillingLightAction = (userCost: BillingInfoLightDTO): ReceiveUserBillingLightAction => ({ type: RECEIVE_USER_BILLING_LIGHT, userCost });
export const errorGetUserBillingLightAction = (): ErrorGetUserBillingLightAction => ({ type: ERROR_GET_USER_BILLING_LIGHT });

export const getUserBillingLightAction = (): AppThunk<Promise<void>> => async dispatch => {
  siteService
    .getUserCostInfo()
    .then(userCost => dispatch(receiveUserBillingLightAction(userCost)))
    .catch(() => dispatch(errorGetUserBillingLightAction()));
};

export type UserBillingActionType = ReceiveUserBillingLightAction | ErrorGetUserBillingLightAction;