import DatePickerIcon from '@mui/icons-material/CalendarToday';
import KeyBoardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyBoardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import IconWarning from '@mui/icons-material/Warning';
import MoreTime from '@mui/icons-material/MoreTime';
import { CircularProgress, IconButton, Tooltip } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { DateTime } from 'luxon';
import { expiresInNbMonths, isExpiredDate } from 'services/date-service';
import { Row } from './manage-users-utils';
import styles from './manage-users.module.scss';

type Props = {
  localData: Row[],
  updateUser: (rowData: Row) => void,
  value: number,
  isPending: boolean,
  data,
  row: Row,
  months,
  isConnectedUserManager: (resourcePath: string, siteList: any[]) => boolean,
  siteList: any[],
};

// eslint-disable-next-line
const CustomDatePicker = ({ localData, updateUser, value, isPending, data, row, months, isConnectedUserManager, siteList }: Props) => {
  const canEditExpirationDate = (rowData: Row): boolean => {
    //Manager can edit its own expiration date
    return isConnectedUserManager(rowData.resourcePath, siteList);
  };
  const maxExpirationDate = DateTime.local().plus({ months: months });

  const addThreeMonths = (row) => {
    let expirationDate = DateTime.fromISO(row.expirationDate).plus({ months: 3 });
    if (maxExpirationDate < expirationDate) {
      expirationDate = maxExpirationDate;
    }
    handleDateChange(data, row, expirationDate);
  }

  const handleDateChange = (data, rowData: Row, date) => {
    // eslint-disable-next-line no-param-reassign
    rowData.expirationDate = date;
    // eslint-disable-next-line no-param-reassign
    localData[data.currentTableData[data.rowIndex].index].expirationDate = date;
    updateUser(rowData);
  };

  return (
    <div className={styles.manage_user__dateContainer}>
      <DatePicker
        disabled={!canEditExpirationDate(row) || isPending}
        slots={{
          openPickerIcon: DatePickerIcon,
          leftArrowIcon: KeyBoardArrowLeft,
          rightArrowIcon: KeyBoardArrowRight,
        }}
        onChange={date => handleDateChange(data, row, date)}
        value={DateTime.fromISO(value)}
        maxDate={DateTime.local().plus({ months: months })}
        minDate={DateTime.local().plus({ days: 1 })}
        format='yyyy/MM/dd'
        timezone='Europe/Paris'
        className='user-authority-date-picker'
        slotProps={{
          textField: {
            label: '',
            classes: { root: styles.manage_user__datePickerInputField },
            sx: {
              '& .MuiInputBase-input.Mui-disabled': {
                class: styles.manage_user__datePickerInputFieldDisabled,
              },
              '.MuiOutlinedInput-input': { padding:"4px 0 4px 0"},
              boxShadow: 'none',
              '.MuiOutlinedInput-notchedOutline': { border: 0 },

            },
            InputLabelProps: { shrink: true, classes: { root: styles.manage_user__datePickerInputFieldLabel } },
          },
        }} />

      {canEditExpirationDate(row) && DateTime.fromISO(row.expirationDate) < maxExpirationDate && (
        <IconButton onClick={() => addThreeMonths(row)}>
          <Tooltip title='Extend user rights by 3 months'>
            <MoreTime className={styles.more_time__action_icon} />
          </Tooltip>
        </IconButton>
      )}

      {isPending &&
        <CircularProgress size={24} style={{ marginLeft: 15, position: 'relative', top: 4 }} />
      }

      <div className={styles.manage_user__warningIconContainer}>
        {expiresInNbMonths(row.expirationDate, 1) && !isExpiredDate(row.expirationDate) && (
          <Tooltip title='Permission will expire in less than 1 month'>
            <IconWarning className={styles.manage_user__warningColor} />
          </Tooltip>
        )}

        {isExpiredDate(row.expirationDate) && (
          <Tooltip title='Permission is expired'>
            <IconWarning className={styles.manage_user__errorColor} />
          </Tooltip>
        )}
      </div>
    </div>
  );
};

export default CustomDatePicker;