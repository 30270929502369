import * as siteActions from 'store/actions/resource-site-actions';
import { BillingInfoDTO, SiteInfoDTO } from 'types';

type SiteState = {
  siteInformation: SiteInfoDTO | undefined,
  isFetching: boolean,
  siteCostInfoList: BillingInfoDTO[],
};

const initialState: SiteState = {
  siteInformation: undefined,
  isFetching: false,
  siteCostInfoList: [],
};

const infoSiteReducer = (state = initialState, action: siteActions.SiteActions): SiteState => {
  switch (action.type) {
    case siteActions.REQUEST_SITE_INFORMATION:
    case siteActions.REQUEST_GET_SITE_COST_INFO_LIST:
      return {
        ...state,
        isFetching: true,
      };
    case siteActions.RECEIVE_SITE_INFORMATION:
      return {
        ...state,
        isFetching: false,
        siteInformation: action.siteInformation,
      };
    case siteActions.ERROR_FETCH_SITE_INFORMATION:
      return {
        ...state,
        isFetching: false,
        siteInformation: undefined,
      };
    case siteActions.RECEIVE_GET_SITE_COST_INFO_LIST:
      return {
        ...state,
        isFetching: false,
        siteCostInfoList: action.siteCostInfoList,
      };
    case siteActions.ERROR_GET_SITE_COST_INFO_LIST:
      return {
        ...state,
        isFetching: false,
        siteCostInfoList: [],
      };
    case siteActions.UPDATE_SITE_INFO_SUCCESS:
      return {
        ...state,
        siteCostInfoList: state.siteCostInfoList.map(siteCostInfo => (siteCostInfo.site.id !== action.siteId ? siteCostInfo : { ...siteCostInfo, site: { ...siteCostInfo.site, ...action.siteInfo } })),
        siteInformation: { ...state.siteInformation!, ...action.siteInfo },
      };
    default:
      return state;
  }
};

export default infoSiteReducer;