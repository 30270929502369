import ExtensionIcon from '@mui/icons-material/Extension';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import { IconButton, Menu, MenuItem, Tooltip } from '@mui/material';
import SismageLogo from 'assets/images/sismage-logo.png';
import Loader from 'components/loader/loader';
import { RUNNING } from 'constants/vm-status';
import { useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { bindActionCreators } from 'redux';
import vmInstanceService from 'services/vm-instance-service';
import { openVmExtraInstallsModal } from 'store/actions/vm-extra-installs-actions';
import { VmDTO } from 'types';
import styles from './vm-action-menu.module.scss';
import VmExtraInstalls from './vm-extra-installs';

const mapDispatch = (dispatch) => bindActionCreators(
  {
    openVmExtraInstallsModal,
  },
  dispatch
);

const connector = connect(null, mapDispatch);

type ReduxProps = ConnectedProps<typeof connector>;

type OwnProps = {
  vmInstance: VmDTO,
  loggedUserId: string
};

type Props = ReduxProps & OwnProps;

const VmActionMenu = ({ vmInstance, loggedUserId, openVmExtraInstallsModal }: Props) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [waitingSudo, setWaitingSudo] = useState(false);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openSmgInstallModal = () => {
    setAnchorEl(null);
    openVmExtraInstallsModal(vmInstance);
  };

  const isLoggedUserOwner = loggedUserId === vmInstance.ownerId;

  const sudoVM = () => {
    setWaitingSudo(true);
    return vmInstanceService
      .sudoVM(vmInstance.sessionId)
      .then(() => {
        setTimeout(() => setWaitingSudo(false), 5000);
      })
      .catch(() => setWaitingSudo(false));
  };

  // smgInstallsRegexp
  const NO_INSTALL = '^$';
  const hasAnyAction = vmInstance.smgInstallsRegexp !== NO_INSTALL || vmInstance.sudoer !== 'forbidden';
  return (
    <div>
      {hasAnyAction && isLoggedUserOwner && vmInstance.status === RUNNING && (
        <Tooltip title='Use advanced extensions' enterDelay={700} enterNextDelay={700} leaveDelay={75}>
          <IconButton
            aria-label='more'
            aria-controls='long-menu'
            aria-haspopup='true'
            onClick={handleClick}
            disabled={!hasAnyAction}>
            <ExtensionIcon key={vmInstance.name}/>
          </IconButton>
        </Tooltip>)}
      <Menu
        id='long-menu'
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            width: '40ch',
          },
        }}>
        {vmInstance.smgInstallsRegexp !== NO_INSTALL && (
          <MenuItem key='smgInstalls' onClick={openSmgInstallModal} className={styles.menuItem}>
            <img src={SismageLogo} alt='sismage' className={styles.smgIcon}/>
            Install Sismage non official versions
          </MenuItem>
        )}
        {vmInstance.sudoer === 'allowed' && (
          <MenuItem key='sudoers' onClick={sudoVM} className={styles.menuItem}>
            {waitingSudo ? <Loader className={styles.loaderWaitingSudo}/> :
              <SupervisorAccountIcon className={styles.sudoIcon}/>}
            Grant sudo (for 60mn)
          </MenuItem>
        )}
        {vmInstance.sudoer === 'activated' && (
          <MenuItem key='sudoed' className={styles.menuItem} disabled={true}>
            <SupervisedUserCircleIcon className={styles.sudoIcon}/>
            Sudo granted
          </MenuItem>
        )}
      </Menu>
      {isLoggedUserOwner && vmInstance.status === RUNNING ? (<VmExtraInstalls vmInstance={vmInstance}/>) : ''}

    </div>
  );
};


export default connector(VmActionMenu);