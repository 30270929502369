import { loginRequest } from 'constants/authConfig';
import { loginRedirect, msalInstance } from './authentification-service';
import { InteractionRequiredAuthError } from '@azure/msal-browser';

const getAccessToken = async (): Promise<string> => {
  if (process.env.NODE_ENV === 'test' || process.env.NODE_ENV === 'development') {
    return 'mocktoken';
  }
  const nullableAccount = msalInstance.getActiveAccount();
  const account = nullableAccount != null ? nullableAccount : undefined;

  return msalInstance.acquireTokenSilent({ ...loginRequest, account: account })
    .then(rep => rep.accessToken)
    .catch(error => {
      console.error(error);
      if (error instanceof InteractionRequiredAuthError) 
        loginRedirect(account);
      throw new Error('Not able to retreive token yet');
    });
};

export default getAccessToken;