/* eslint-disable no-undef */
import React from 'react';
import 'toastr/toastr.scss';

import { StyledEngineProvider, ThemeProvider, createTheme } from '@mui/material';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { HashRouter } from 'react-router-dom';

import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';

import 'core-js/features/array/flat-map';
import App from './components/app/app';
import store from './store';

import { MsalProvider } from '@azure/msal-react';
import { msalInstance } from 'services/authentification-service';
import 'styles/styles.scss';


const theme = createTheme({
  components: {
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: '4px 4px',
        },
      },
    },
  },
});

const Root = () => (
  <StyledEngineProvider injectFirst>
    <LocalizationProvider dateAdapter={AdapterLuxon}>
      <ThemeProvider theme={theme}>
        <Provider store={store}>
          <HashRouter>
            {
              (
                process.env.NODE_ENV !== 'development' ?
                  <MsalProvider instance={msalInstance}>
                    <App />
                  </MsalProvider>
                  : <App />
              )
            }
          </HashRouter>
        </Provider>
      </ThemeProvider>
    </LocalizationProvider>
  </StyledEngineProvider>
);
const root = createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <Root />
  </React.StrictMode>
);