export const PENDING = 'PENDING';
export const RUNNING = 'RUNNING';

export const BOOTING = 'BOOTING';
export const TERMINATED = 'TERMINATED';
export const STOPPING = 'STOPPING';
export const STOP_REQUEST = 'REQUEST STOP';
export const ERROR = 'ERROR';
export const FAILED = 'FAILED';
export const UNRESPONSIVE = 'UNRESPONSIVE';

export const UNREACHABLE = -3;
export const UNKNOWN = -2;
export const COMPUTING = -1;

export const NO_MESSAGE_STATUS_EVENT = 'NO_MESSAGE';
