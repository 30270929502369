import { 
  ERROR_ADD_USER, 
  MachineRightsActionType, 
  RECEIVE_ADD_USER, 
  REQUEST_ADD_USER 
} from 'store/actions/manage-rights-actions';

type MachineRightState = {
  isAddingRights: boolean;
};

const initialState: MachineRightState = {
  isAddingRights: false,
};

const manageRightReducer = (state = initialState, action: MachineRightsActionType): MachineRightState => {
  switch (action.type) {
    case REQUEST_ADD_USER:
      return {
        ...state,
        isAddingRights: true,
      };
    case RECEIVE_ADD_USER:
    case ERROR_ADD_USER:
      return {
        ...state,
        isAddingRights: false,
      };
    default:
      return state;
  }
};

export default manageRightReducer;