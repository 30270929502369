import AddIcon from '@mui/icons-material/LibraryAdd';
import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Input, InputLabel, NativeSelect, Slider, TextField, Typography } from '@mui/material';
import React, { ReactNode, useEffect, useState } from 'react';
import { ConnectedProps, connect } from 'react-redux';

import { PROJECT_NAME_VALIDATION_ERROR_MESSAGE, ZONE_BACKUP_SOLUTION_ERROR_MESSAGE, ZONE_NAME_VALIDATION_ERROR_MESSAGE } from 'components/constants/error-messages';
import Loader from 'components/loader/loader';
import { ANF_NATIVE_BACKUP_POLICY, BACKUP_POLICY_DISABLED, BACKUP_TYPES } from 'constants/backup-policy';
import { ProjectType } from 'constants/project-type';
import { DEFAULT_SNAPSHOT_POLICY, SNAPSHOT_POLICY_DISABLED } from 'constants/snapshot-policy';
import { MANAGER } from 'constants/user-authority';
import { format1Decimal, round1Decimal } from 'services/float-service';
import resourcePathService from 'services/resource-path-service';
import siteService from 'services/site-service';
import vmInstanceService from 'services/vm-instance-service';
import zoneService from 'services/zone-service';
import { useAppDispatch } from 'store';
import { addProject } from 'store/actions/resource-project-actions';
import { addZone } from 'store/actions/resource-zone-actions';
import { addStorageTemplates } from 'store/actions/storage-template-actions';
import { RootState } from 'store/reducers';
import { getStorageTemplates } from 'store/selectors/storage-category-selectors';
import { CreateZoneDTO, PricingInfoDTO, StorageBackupType, StorageTemplateDTO } from 'types';
import styles from './create-resource.module.scss';

// a => not correct
// 12345 => not correct
// a-1234 => correct
// testlongname001 => not correct
//3 to 14 characters; starts with a letter; ends whith a letter or number; can contain letters, numbers and hyphens
const ZONE_REGEX = '^(?:[A-Za-z](?:[-A-Za-z0-9]{1,12}[A-Za-z0-9]))$';

// a => correct
// 12345 => not correct
// a-1234 => correct
//1 to 128 characters; starts with a letter; ends whith a letter or number; can contain letters, numbers and hyphens
const PROJECT_REGEX = '^(?:[A-Za-z](?:[_A-Za-z0-9-]{0,126}[A-Za-z0-9])?)$';

type SitesWithPricing = {
  [key: number]: TemplateWithPricing
};

type TemplateWithPricing = {
  [key: string]: PricingInfoDTO
};

const mapState = (state: RootState) => ({
  siteList: state.resourcesReducer.siteList,
  getSiteStorageTemplates: (siteId: number) => getStorageTemplates(state, siteId),
});

const connector = connect(mapState);

type ReduxProps = ConnectedProps<typeof connector>;

type OwnProps = {
  children: ReactNode | null,
  resourceType: string,
  selectedSiteId?: number,
  selectedZoneId?: number,
  selectedParentProjectId?: number,
  onClose?: () => void,
};

type Props = ReduxProps & OwnProps;

const CreateResource = ({
  children, resourceType, siteList, selectedSiteId, selectedZoneId, selectedParentProjectId, getSiteStorageTemplates, onClose,
}: Props) => {
  const dispatch = useAppDispatch();

  const [open, setOpen] = useState(false);
  const [siteId] = useState<number | undefined>(selectedSiteId);
  const [zoneId] = useState<number | undefined>(selectedZoneId);
  const [parentProjectId] = useState<number | undefined>(selectedParentProjectId);
  const [zoneFileshareSize, setZoneFileshareSize] = useState<number>(2.5);
  const [resourceName, setResourceName] = useState<string | undefined>(undefined);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const [storageTemplate, setStorageTemplate] = useState<StorageTemplateDTO | undefined>(undefined);
  const [selectedStorageTemplateIndex, setSelectedStorageTemplateIndex] = useState(-1);
  const [selectedBackupSolutionCode, setSelectedBackupSolutionCode] = useState(BACKUP_TYPES.NO_BACKUP);
  const [inProgress, setInProgress] = useState(false);
  const [siteId2ZoneFilesharePrice, setSiteId2ZoneFilesharePrice] = useState<SitesWithPricing>({});
  const [snapshotsCheckboxChecked, setSnapshotsCheckboxChecked] = useState(false);
  const [snapshotsActivable, setSnapshotsActivable] = useState(false);
  const [selectedFolderType, setSelectedFolderType] = useState(ProjectType.SISMAGE);
  const [isSamEnabledOnSite, setIsSamEnabledOnSite] = useState<boolean | undefined>(undefined);

  const handleFolderTypeChange = (event) => {
    setSelectedFolderType(event.target.value);
  };

  useEffect(() => {
    if (siteId) {
      const storedStorageTemplates: StorageTemplateDTO[] = getSiteStorageTemplates(siteId);
      if (!storedStorageTemplates) {
        vmInstanceService.getStorageTemplates(siteId).then(storageTemplates => {
          const result = { [storageTemplates.id]: storageTemplates.templates };
          dispatch(addStorageTemplates(result));
        });
      }
    }
  }, [siteId]);

  useEffect(() => {
    siteService.getSiteInfo(siteId).then(siteInfo => {
      setIsSamEnabledOnSite(siteInfo.capabilities.filter(c => c.capabilityName === 'use_sam' && c.capabilityStatus === 'true').length > 0);
    });
  }, []);

  const handleClose = (event) => {
    if (onClose) {
      onClose();
    }
    setOpen(false);
    event.stopPropagation();
  };

  const handleStorageTypeChange = (event) => {
    // target value is the index of the storage template array
    const selectedIndex: number = event.target.value;
    if (!selectedIndex || !siteId) {
      return;
    }

    const selectedStorageTemplate: StorageTemplateDTO = getSiteStorageTemplates(siteId)[selectedIndex];

    //reset storage size and snapshots/backups to avoid conflicts between ANF and AZF
    setZoneFileshareSize(round1Decimal(selectedStorageTemplate.minSize / 1024));
    setSnapshotsActivable(selectedStorageTemplate.snapshotsActivable);

    setStorageTemplate(selectedStorageTemplate);
    setSelectedStorageTemplateIndex(selectedIndex);
    updatePricingInfo(siteId, selectedStorageTemplate);

    const defaultBackupSolutionCode = getDefaultBackupSolutionCode(selectedStorageTemplate.backupsSolutions);
    setSelectedBackupSolutionCode(defaultBackupSolutionCode);
    setSnapshotsCheckboxChecked(defaultBackupSolutionCode === BACKUP_TYPES.ANF_NATIVE_BACKUP ? true : false);
  };

  const updatePricingInfo = (siteId?: number, storageTemplate?: StorageTemplateDTO) => {
    if (siteId == null || storageTemplate == null || storageTemplate.tier == null) return;
    if (!siteId2ZoneFilesharePrice[siteId] || !siteId2ZoneFilesharePrice[siteId][storageTemplate.tier]) {
      getZoneFilesharePricePerGiBPerMonth(siteId, storageTemplate);
    }
  };

  const getZoneFilesharePricePerGiBPerMonth = (siteId: number, storageTemplate: StorageTemplateDTO) => {
    const site = siteList.find(s => s.resourceId === siteId);
    if (site) {
      zoneService.getZoneFilesharePricing(site.resourceId, storageTemplate.tier).then(pricingInfo => {
        setSiteId2ZoneFilesharePrice(
          {
            ...siteId2ZoneFilesharePrice,
            [siteId]: { ...siteId2ZoneFilesharePrice[siteId], [storageTemplate.tier]: pricingInfo },
          });
      });
    }
  };

  function getDefaultBackupSolutionCode(backupsSolutions: StorageBackupType[]): string {
    if (backupsSolutions.length === 1) {
      return backupsSolutions[0].code;
    }
    return backupsSolutions.filter(bs => bs.deactivable)
      .shift()?.code ?? BACKUP_TYPES.NO_BACKUP;
  }

  const handleBackupSolutionChange = (event) => {
    setSelectedBackupSolutionCode(event.target.value);
    if (event.target.value === BACKUP_TYPES.ANF_NATIVE_BACKUP) {
      setSnapshotsCheckboxChecked(true);
    }
  };

  const handleResourceNameChange = (event) => {
    const regex = new RegExp(resourceType === 'Zone' ? ZONE_REGEX : PROJECT_REGEX);
    if (regex.test(event.target.value)) {
      setResourceName(event.target.value);
      setErrorMessage(undefined);
    } else {
      setErrorMessage(resourceType === 'Zone' ? ZONE_NAME_VALIDATION_ERROR_MESSAGE : PROJECT_NAME_VALIDATION_ERROR_MESSAGE);
    }
  };

  const handleZoneFileshareSizeChange = (event, value) => {
    setZoneFileshareSize(value);
  };

  const handInputBlurEvent = () => {
    const min = storageTemplate && storageTemplate.minSize >= 100 ? round1Decimal(storageTemplate?.minSize / 1024) : 0.1;
    const max = storageTemplate && storageTemplate.maxSize ? round1Decimal(storageTemplate.maxSize / 1024) : 100;

    const step = storageTemplate && storageTemplate.sizeIncrement >= 100 ? round1Decimal(storageTemplate.sizeIncrement / 1024) : 0.1;
    let value: number;
    if (zoneFileshareSize < min)
      value = min;
    else if (zoneFileshareSize > max)
      value = max;
    else
      value = Math.round(zoneFileshareSize / step) * step;

    setZoneFileshareSize(round1Decimal(value));
  };

  const handleInputZoneFileshareSizeChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setZoneFileshareSize(event.target.value === '' ? 0 : Number(event.target.value));
  };

  const handleMenuClick = (event) => {
    setOpen(true);
    event.stopPropagation();
  };

  function getSelectedBackupSolution(): StorageBackupType | undefined {
    return storageTemplate?.backupsSolutions
      .filter((backupSolution) => (backupSolution.code === selectedBackupSolutionCode))
      .shift();
  }

  const createResource = (event) => {
    setInProgress(true);

    if (resourceType === 'Project' && siteId && zoneId && parentProjectId && resourceName) {
      const body = {
        parentResourcePath: resourcePathService.getProjectResourcePath(siteList, parentProjectId),
        name: resourceName,
        tag: selectedFolderType,
      };

      dispatch(addProject(body)).then(() => {
        handleClose(event);
        setInProgress(false);
      });
    } else if (resourceType === 'Project' && siteId && zoneId && resourceName) {
      const body = {
        parentResourcePath: resourcePathService.getZoneResourcePath(siteList, zoneId),
        name: resourceName,
        tag: selectedFolderType,
      };

      dispatch(addProject(body)).then(() => {
        handleClose(event);
        setInProgress(false);
      });
    } else if (resourceType === 'Zone' && siteId && resourceName && storageTemplate && storageTemplate.tier) {
      // convert from TiB to GiB and round to the nearest sizeIncrement GiB
      const zoneFileshareSizeInGiB = Math.round(zoneFileshareSize * 1024 / storageTemplate.sizeIncrement) * storageTemplate.sizeIncrement;
      let snapshotPolicy = SNAPSHOT_POLICY_DISABLED;
      const backupSolution = getSelectedBackupSolution();

      if (backupSolution === undefined) {
        setErrorMessage(ZONE_BACKUP_SOLUTION_ERROR_MESSAGE);
        setInProgress(false);
        return;
      }

      if (snapshotsActivable && snapshotsCheckboxChecked) {
        snapshotPolicy = DEFAULT_SNAPSHOT_POLICY;
      }
      const body: CreateZoneDTO = {
        siteResourcePath: resourcePathService.getSiteResourcePath(siteList, siteId),
        name: resourceName,
        fileshareSize: (zoneFileshareSizeInGiB),
        category: storageTemplate.tier,
        snapshotPolicy: snapshotPolicy,
        backupPolicy: backupSolution.code === BACKUP_TYPES.ANF_NATIVE_BACKUP ? ANF_NATIVE_BACKUP_POLICY : BACKUP_POLICY_DISABLED,
      };

      dispatch(addZone(body)).then(() => {
        handleClose(event);
        setInProgress(false);
      });
    }
  };

  function isNativeBackupActivated(): boolean {
    return getSelectedBackupSolution()?.code === BACKUP_TYPES.ANF_NATIVE_BACKUP;
  }

  const disableValidate = () => (
    errorMessage
    || !resourceName
    || (resourceType === 'Zone' && (!storageTemplate || !storageTemplate.tier))
    || (resourceType === 'Project' && !zoneId)
    || (resourceType === 'Zone' && !siteId)
  );

  const handleSnapshotsEnabledChange = event => {
    setSnapshotsCheckboxChecked(event.target.checked);
  };

  return (
    <span onClick={handleMenuClick} className={styles.menu_item_content}>
      {children}

      <Dialog open={open} fullWidth maxWidth='sm' onClose={handleClose} className='dialog'>
        <DialogTitle>
          <div className='dialogTitle'>
            <AddIcon />
            <div className='modalTitle'>{`CREATE A ${resourceType === 'Zone' ? 'STORAGE' : 'PROJECT'}`}</div>
          </div>
        </DialogTitle>
        <DialogContent>
          <div>
            {resourceType === 'Zone' && (
              <>
                {siteList
                  .filter(site => site.resourceId === siteId)
                  .map(site =>
                    site.authority === MANAGER ? (
                      <InputLabel shrink className={styles.create_resource__input_field_label}>
                        Site {site.label}
                      </InputLabel>
                    ) : null
                  )}
              </>
            )}
            {resourceType !== 'Zone' && (
              <>
                <p className='warning-centered-text'>⚠️ Only new VM will see this project ⚠️</p>
                {siteList.map(site =>
                  site.children
                    .filter(zone => zone.resourceId === zoneId)
                    .map(zone =>
                      zone.authority === MANAGER ? (
                        <InputLabel shrink className={styles.create_resource__input_field_label}>
                          Storage {zone.name}
                        </InputLabel>
                      ) : null
                    )
                )}
              </>
            )}
          </div>

          <div className={styles.create_resource__resource_text_field_container}>
            <TextField
              className={styles.create_resource__resource_text_field_container__resource_text_field}
              id='resource-name'
              label={`${resourceType === 'Zone' ? 'Storage' : resourceType} Name`}
              InputLabelProps={{
                shrink: true,
                classes: {
                  root: styles.create_resource__input_field_label,
                },
              }}
              InputProps={{
                classes: {
                  root: styles.create_resource__input_resource_name,
                },
              }}
              sx={{
                boxShadow: 'none',
                '.MuiOutlinedInput-notchedOutline': { border: 0 },
                '.MuiOutlinedInput-input': { padding: 0 },
              }}
              onChange={handleResourceNameChange}
            />
            {errorMessage && <div className={styles.create_resource__error_message}>{errorMessage}</div>}
          </div>

          {resourceType !== 'Zone' && isSamEnabledOnSite && (
            <>
              <div className={styles.create_resource__resource_text_field_container}>
                <InputLabel shrink className={styles.create_resource__input_field_label}>
                  Project type
                </InputLabel>
                <NativeSelect
                  className={styles.create_resource__input_field}
                  value={selectedFolderType}
                  onChange={handleFolderTypeChange}
                >
                  <option value={ProjectType.SISMAGE}>
                    SISMAGE
                  </option>
                  <option value={ProjectType.FOLDER}>FOLDER</option>
                </NativeSelect>
              </div>
            </>
          )}

          {resourceType === 'Zone' && (
            <>
              <div className={styles.create_resource__resource_text_field_container}>
                <InputLabel shrink className={styles.create_resource__input_field_label}>
                  Storage type
                </InputLabel>
                <NativeSelect className={styles.create_resource__input_field} value={selectedStorageTemplateIndex} onChange={handleStorageTypeChange}>
                  <option value='-1' disabled>
                    Select storage
                  </option>
                  {siteId && getSiteStorageTemplates(siteId) ? (
                    getSiteStorageTemplates(siteId).map((template, index) => {
                      return (
                        <option key={index} value={index} className={styles.create_resource__input_field}>
                          {template.tier}
                        </option>
                      );
                    })
                  ) : (
                    <option value='-2' />
                  )}
                </NativeSelect>
              </div>
              {siteId && storageTemplate && storageTemplate.tier && snapshotsActivable && (
                <div>
                  <label>Snapshots:</label>
                  <Checkbox name='snapshot' disabled={selectedBackupSolutionCode === BACKUP_TYPES.ANF_NATIVE_BACKUP} checked={snapshotsCheckboxChecked} onChange={handleSnapshotsEnabledChange} />
                </div>
              )}
              {siteId && storageTemplate && storageTemplate.tier && (
                <div>
                  <label>Backups:</label>
                  <NativeSelect
                    className={styles.create_resource__input_field}
                    value={selectedBackupSolutionCode}
                    onChange={handleBackupSolutionChange}
                    disabled={storageTemplate.backupsSolutions.length <= 1}
                  >
                    {storageTemplate.backupsSolutions.map(backupSolution => {
                      return (
                        <option key={backupSolution.code} value={backupSolution.code} className={styles.create_resource__input_field}>
                          {backupSolution.label}
                        </option>
                      );
                    })}
                  </NativeSelect>
                </div>
              )}
              {siteId && storageTemplate && storageTemplate.tier && (
                <div className={styles.create_resource__slider_root}>
                  <Typography id='label' className={styles.create_resource__fileshare_size}>{`Fileshare size: ${format1Decimal(zoneFileshareSize)} TiB`}</Typography>
                  <div className={styles.create_resource__slider_and_input}>
                    <Slider
                      className={styles.create_resource__slider}
                      value={zoneFileshareSize}
                      min={storageTemplate.minSize >= 100 ? round1Decimal(storageTemplate.minSize / 1024) : 0.1}
                      max={storageTemplate.maxSize ? round1Decimal(storageTemplate.maxSize / 1024) : 100}
                      step={storageTemplate.sizeIncrement >= 100 ? round1Decimal(storageTemplate.sizeIncrement / 1024) : 0.1}
                      aria-labelledby='label'
                      onChange={handleZoneFileshareSizeChange}
                    />
                    <Input
                      value={zoneFileshareSize}
                      onChange={e => handleInputZoneFileshareSizeChange(e)}
                      onBlur={() => handInputBlurEvent()}
                      inputProps={{
                        min: storageTemplate.minSize >= 100 ? round1Decimal(storageTemplate.minSize / 1024) : 0.1,
                        max: storageTemplate.maxSize ? round1Decimal(storageTemplate.maxSize / 1024) : 100,
                        step: storageTemplate.sizeIncrement >= 100 ? round1Decimal(storageTemplate.sizeIncrement / 1024) : 0.1,
                        type: 'number',
                      }}
                    />
                  </div>
                </div>
              )}
              {siteId && storageTemplate && storageTemplate.tier && siteId2ZoneFilesharePrice[siteId] && siteId2ZoneFilesharePrice[siteId][storageTemplate.tier] && (
                <div className={styles.create_resource__filestore_price}>
                  <span>Total : </span>
                  <b>
                    {zoneService.calculateStoragePrice(zoneFileshareSize, siteId2ZoneFilesharePrice[siteId][storageTemplate.tier].price, isNativeBackupActivated())}&nbsp;
                    {siteId2ZoneFilesharePrice[siteId][storageTemplate.tier].currencyCode ? siteId2ZoneFilesharePrice[siteId][storageTemplate.tier].currencyCode : 'USD'}
                  </b>
                  <span> / Month</span>
                </div>
              )}
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Grid container alignItems='stretch' direction='row' justifyContent='flex-end' className='dialog-actions'>
            <Grid item>
              <Button onClick={handleClose} className='cancel-button' sx={{ color: 'black' }}>
                Cancel
              </Button>
            </Grid>

            <Grid item>
              {!inProgress && isSamEnabledOnSite !== undefined ? (
                <Button onClick={createResource} variant='contained' color='primary' className='validation-button' disabled={!!disableValidate()}>
                  VALIDATE
                </Button>
              ) : (
                <Loader className='loader-icon' />
              )}
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </span>
  );
};

export default connector(CreateResource);
