import { AvailableMachineTemplateDTO } from 'types';

export const SET_MACHINE_TEMPLATES_LIST = 'SET_MACHINE_TEMPLATES_LIST';
export const ADD_MACHINE_TEMPLATES = 'ADD_MACHINE_TEMPLATES';

export type SetMachineTemplates = {
  type: typeof SET_MACHINE_TEMPLATES_LIST, 
  machineTemplates: {
    [id: string]: AvailableMachineTemplateDTO[]
  }
};
export type AddMachineTemplates = {
  type: typeof ADD_MACHINE_TEMPLATES,
  machineTemplates: {
    [id: string]: AvailableMachineTemplateDTO[]
  }
};

export const setMachineTemplates = (machineTemplates): SetMachineTemplates => ({ type: SET_MACHINE_TEMPLATES_LIST, machineTemplates });
export const addMachineTemplates = (machineTemplates): AddMachineTemplates => ({ type: ADD_MACHINE_TEMPLATES, machineTemplates });

export type MachineActionType = SetMachineTemplates | AddMachineTemplates;
