import { ChangeEvent, useRef } from 'react';

import './input-file.scss';

type Props = {
  onChange: (file: File) => void,
  fileName: string | undefined
};
const InputFile = ({ onChange, fileName }: Props) => {
  const fileInput = useRef<HTMLInputElement>(null);

  const handleFileInput = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      onChange(event.target.files[0]);
    }
  };

  const handleOnClick = () => {
    fileInput.current!.click();
  };

  return (
    <>
      <div className='file'>
        <input id={Math.random() + ''} type='file' onChange={handleFileInput} ref={fileInput}/>
        <span onClick={handleOnClick} className={'file-custom'}>{fileName}</span>
      </div>
    </>
  );
};

export default InputFile;
