import { MANAGER } from 'constants/user-authority';
import { RootState } from 'store/reducers';
import { ResourceAccessNode } from 'types';

export const getZone = (state: RootState, zoneId: number): ResourceAccessNode | undefined => {
  return state.resourcesReducer.siteList.flatMap(s => s.children).find(z => z.resourceId === zoneId);
};

export const getSite = (state: RootState, siteId: number): ResourceAccessNode | undefined => {
  return state.resourcesReducer.siteList.find(s => s.resourceId === siteId);
};

export const getSiteByZoneId = (state: RootState, zoneId: number): ResourceAccessNode | undefined => {
  return state.resourcesReducer.siteList.find(s => s.children.some(z => z.resourceId === zoneId));
};

export const isZoneManager = (state: RootState, zoneId: number): boolean => {
  const zone = getZone(state, zoneId);
  const site = getSiteByZoneId(state, zoneId);
  return zone?.authority === MANAGER || site?.authority === MANAGER;
};

export const isSiteManager = (state: RootState, siteId: number): boolean => {
  const site = getSite(state, siteId);
  return site?.authority === MANAGER;
};

