import InputFile from "components/shared/input-file/input-file";
import styles from 'components/file-upload/add-file-upload-styles.module.scss';

type Props = {
  selectedFile: File | undefined,
  handleSelectedFile: (file: File) => void,
};

const BrowserUploadTab = ({selectedFile, handleSelectedFile} : Props) => {

    return (
      <div>
        <p className={styles.infoMessage}>
          Browser upload is best suited for <b>small</b> files that take less than an hour to upload.<br/>
          To upload such file, please follow these steps :<br/><br/>
          1. <b>Select</b> file you want to upload and click on upload<br/>
          2. <b>Wait</b> for completion of the upload<br/>
          3. The uploaded file is now accessible in <b>/import</b> of any session of the project<br/>
          <br/>
        </p>
        <InputFile onChange={handleSelectedFile} fileName={selectedFile ? selectedFile.name : 'Choose a File...'}/>
      </div>
    );
  };

  export default BrowserUploadTab;