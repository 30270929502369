export const format2Decimals = (value: number): string => {
  if (!value) {
    return '';
  }

  return value.toFixed(2);
};

export const format1Decimal = (value: number): string => {
  if (!value) {
    return '';
  }

  return value.toFixed(1);
};

export const round1Decimal = (value: number): number => {
  if (!value) {
    return value;
  }

  return Number.parseFloat(format1Decimal(value));
};
