import HelpIcon from '@mui/icons-material/HelpOutline';
import SessionIcon from '@mui/icons-material/OndemandVideo';
import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, NativeSelect, TextField, Tooltip } from '@mui/material';
import { MANAGER } from 'constants/user-authority';
import React, { useEffect, useState } from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import vmInstanceService from 'services/vm-instance-service';
import { useAppDispatch } from 'store';
import { addMachineTemplates } from 'store/actions/machine-template-actions';
import { initializeVMInstance } from 'store/actions/vm-instance-actions';
import { RootState } from 'store/reducers';
import { getSiteAvailableMachineTemplatesFromState } from 'store/selectors/machine-template-selectors';
import { ResourceAccessNode, SessionPropertiesDTO } from 'types';
import styles from './vm-custom-run.module.scss';

const mapState = (state: RootState) => ({
  getSiteAvailableMachineTemplatesFromState: (siteId) => getSiteAvailableMachineTemplatesFromState(state, siteId),
});

const connector = connect(mapState);

type ReduxProps = ConnectedProps<typeof connector>;

type OwnProps = {
  site: ResourceAccessNode,
  zone?: ResourceAccessNode,
  project?: ResourceAccessNode,
  children: React.ReactNode,
  onClose: () => void
};

type Props = ReduxProps & OwnProps;

const CustomRunVm = ({
  site, zone, project, onClose, children, getSiteAvailableMachineTemplatesFromState,
}: Props) => {
  const [showModal, setShowModal] = useState(false);
  const [managerMode, setManagerMode] = useState(false);
  const [fixedSize, setFixedSize] = useState(false);
  const [customType, setCustomType] = useState(false);
  const [width, setWidth] = useState(1920);
  const [height, setHeight] = useState(1040);
  const [typeToRun, setTypeToRun] = useState(0);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const availableMachineTemplates = getSiteAvailableMachineTemplatesFromState(site.resourceId);
    if (!availableMachineTemplates) {
      vmInstanceService.getMachineTemplates(site.resourceId).then((templates) => {
        const result = { [site.resourceId]: templates };
        dispatch(addMachineTemplates(result));
      });
    }
    initializeDefaultMachineTemplate();
  }, [site.resourceId, project?.resourceId]);

  const initializeDefaultMachineTemplate = () => {
    if (project) {
      setTypeToRun(project.machineTemplate.id);
    } else {
      const templateId = site.machineTemplate ? site.machineTemplate.id : findSmallestTemplateInSite(site);
      setTypeToRun(templateId);
    }
  };

  const findSmallestTemplateInSite = (site: ResourceAccessNode) => {
    //User only has partial access to multiproject site
    //Find smallest machine category in site for user
    const templateIds = site.children.flatMap(z => z.children).map(p => p.machineTemplate.id);
    if (templateIds.length == 0) return 0;

    let smallestTemplateId = templateIds[0];
    for (const id of templateIds) {
      if (id < smallestTemplateId) {
        smallestTemplateId = id;
      }
    }
    return smallestTemplateId;
  };

  const handleClickOpen = event => {
    setShowModal(true);
    event.stopPropagation();
  };

  const handleClose = () => {
    if (onClose) {
      onClose();
    }
    setShowModal(false);
  };

  const handleWidthChange = event => {
    setWidth(event.target.value);
  };

  const handleHeightChange = event => {
    setHeight(event.target.value);
  };

  const handleAutoResizeChange = event => {
    setFixedSize(event.target.checked);
  };

  const handleManagerModeChange = event => {
    setManagerMode(event.target.checked);
  };

  const handleCustomTypeChange = event => {
    setCustomType(event.target.checked);
  };

  const history = useHistory();
  const redirectToSessions = () => history.push('/');

  const preInitializeVMInstance = () => {
    const w = !fixedSize ? -1 : width.valueOf();
    const h = !fixedSize ? -1 : height.valueOf();
    const vmInstanceInit: SessionPropertiesDTO = {
      machineTemplateId: typeToRun,
      resourcePath: project ? project.resourcePath : site.resourcePath,
      resolution: {
        width: w,
        height: h,
      },
      managerMode: managerMode,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };

    dispatch(initializeVMInstance(vmInstanceInit));
    redirectToSessions();
  };

  const runVM = () => {
    preInitializeVMInstance();
    handleClose();
  };

  const handleTypeChange = e => {
    setTypeToRun(parseInt(e.target.value, 10));
  };

  const isManager = site.authority === MANAGER || zone?.authority === MANAGER || project?.authority === MANAGER;
  return (
    <div onClick={handleClickOpen} className={styles.menu_item_content}>
      <div>{children}</div>
      <Dialog
        open={showModal}
        onClose={handleClose}
        fullWidth maxWidth='sm'
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'>
        <DialogTitle className={styles.dialogTitle}>
          <SessionIcon className={styles.icon} />
          <div className={styles.customRunTitle}>Run custom session</div>
        </DialogTitle>

        <DialogContent>
          <DialogContentText>
            {!project && (
              <>
                <b>Site</b>: {site.label}
              </>
            )}

            {project && (
              <>
                <b>Project</b>: {project.name}
              </>
            )}
          </DialogContentText>
          <div>
            <div className={styles.formRunGroup}>
              <Checkbox
                id='fixedResolution'
                checked={fixedSize}
                onChange={handleAutoResizeChange} />
              <label>Fixed resolution</label>
              <br />
              <TextField
                className={styles.formWidth}
                id='vm-width'
                label='Width (px) - 640 / 4096'
                margin='normal'
                variant='outlined'
                multiline={false}
                defaultValue={width}
                onChange={handleWidthChange}
                disabled={!fixedSize} />
              <TextField
                id='vm-height'
                className={styles.formHeight}
                label='Height (px) - 480 / 2160'
                margin='normal'
                variant='outlined'
                multiline={false}
                defaultValue={height}
                onChange={handleHeightChange}
                disabled={!fixedSize} />
            </div>
            <br />
            <div className={styles.formRunGroupOneLine}>
              <Checkbox
                name='vmType'
                checked={customType}
                onChange={handleCustomTypeChange} />
              <div className={styles.labelWithTooltip}>
                <span>Type of VM : </span>
                <Tooltip
                  classes={{
                    tooltip: styles.tooltip,
                  }}
                  title={
                    <>
                      {getSiteAvailableMachineTemplatesFromState(site.resourceId) && getSiteAvailableMachineTemplatesFromState(site.resourceId).map(m => (
                        <div>{m.name + ': ' + m.description + '.' + (m.hourlyCost != 0 ? (' Price = $' + m.hourlyCost + '/hour') : '')}</div>
                      ))}
                    </>
                  }>
                  <HelpIcon fontSize='small' />
                </Tooltip>
              </div>
              <NativeSelect
                disabled={!customType}
                onChange={event => handleTypeChange(event)}>
                {getSiteAvailableMachineTemplatesFromState(site.resourceId) && getSiteAvailableMachineTemplatesFromState(site.resourceId).map(template => (
                  <option value={template.id} key={template.name} selected={template.id == typeToRun}>
                    {template.name.toUpperCase()}
                  </option>
                ))
                }
              </NativeSelect>
            </div>
            <br />
            {isManager && (
              <div className={styles.formRunGroup}>
                <Checkbox
                  name='managermode'
                  checked={managerMode}
                  onChange={handleManagerModeChange} />
                <label>Manager mode</label>
              </div>
            )}

          </div>
        </DialogContent>
        <DialogActions>
          <Grid container alignItems='stretch' direction='row' justifyContent='flex-end'
            className={styles.accessAttributionButtonGrid}>
            <Grid item>
              <Button onClick={handleClose} className={styles.cancelButton}>
                Cancel
              </Button>
            </Grid>
            <Grid item>
              <Button onClick={runVM} variant='contained' color='primary'
                className={styles.validationButton} disabled={false}>
                RUN
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default connector(CustomRunVm);
