import ExpandLess from '@mui/icons-material/ArrowDropDown';
import ExpandMore from '@mui/icons-material/ArrowRight';
import DeleteIcon from '@mui/icons-material/Delete';
import FolderIcon from '@mui/icons-material/Folder';
import AddUsersIcon from '@mui/icons-material/GroupAdd';
import InfoIcon from '@mui/icons-material/Info';
import AddProjectIcon from '@mui/icons-material/LibraryAdd';
import MoreIcon from '@mui/icons-material/MoreHoriz';
import SessionIcon from '@mui/icons-material/OndemandVideo';
import UploadIcon from '@mui/icons-material/Publish';
import { Collapse, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material';
import classNames from 'classnames';
import FileUploadButton from 'components/file-upload/file-upload-button';
import CreateResource from 'components/resource/create-resource';
import ConfirmDialog from 'components/shared/confirm-dialog/confirm-dialog';
import CustomMenu from 'components/shared/menu/custom-menu';
import CustomMenuItem from 'components/shared/menu/menu-item';
import AddUsers from 'components/user/add-users';
import CustomRunVm from 'components/vm/vm-component/vm-custom-run';
import { ProjectType } from 'constants/project-type';
import { MANAGER } from 'constants/user-authority';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import documentationService from 'services/documentation-service';
import { useAppDispatch } from 'store';
import { deleteProject } from 'store/actions/resource-project-actions';
import { ResourceAccessNode } from 'types';
import styles from './sidebar.module.scss';

type PropsType = {
  project: ResourceAccessNode,
  zone: ResourceAccessNode,
  site: ResourceAccessNode,
  preInitializeVMInstance: () => void,
};

function ProjectListItem({
  project,
  zone,
  site,
  preInitializeVMInstance,
}: PropsType) {
  const [expandProjectList, setExpandProjectList] = useState(project.expanded ? project.expanded : false);
  const [manageMenuEl, setManageMenuEl] = useState(null);
  const dispatch = useAppDispatch();

  useEffect(() => {
    setExpandProjectList(project.expanded ? project.expanded : false);
  }, [project.expanded]);

  if (!project) {
    console.info('No more subprojects to display');//We should never get here anyway
    return null;
  }

  const projectInfoLink = {
    display: true,
    to: '/project-info/:projectId',
    id: 'projectInfoLink',
    label: 'Info',
  };

  const showManageActions = event => {
    setManageMenuEl(event.currentTarget);
    event.stopPropagation();
  };

  const hideManageActions = event => {
    setManageMenuEl(null);
    if (event) {
      event.stopPropagation();
    }
  };

  const disableInstanceInitialize = false;

  const manageItems: CustomMenuItem[] = [
    {
      key: 'add-file-upload',
      display: project.isRunLevel,
      caption: (
        <FileUploadButton site={site} project={project} onClose={() => setManageMenuEl(null)}>
          <div className='justify-center' id='uploadMenuAction'>
            <UploadIcon className={styles.sidebar__action_menu_dropdown_icon} />
            <span className={styles.sidebar__item_label}>Upload file</span>
          </div>
        </FileUploadButton>
      ),
      onClick: hideManageActions,
    },
    {
      key: 'file-download',
      display: project.isRunLevel,
      caption: (
        <Link to={`/download/${project.resourceId}`} className={styles.sidebar__icon_link + ' ' + styles.menu_item_content}>
          <div className='justify-center' id='downloadMenuAction'>
            <UploadIcon className={classNames(styles.sidebar__download_icon_reversed, styles.sidebar__action_menu_dropdown_icon)} />
            <span className={styles.sidebar__item_label}>Download</span>
          </div>
        </Link>
      ),
      onClick: hideManageActions,
    },
    {
      key: 'custom-run',
      display: project.isRunLevel,
      caption: (
        <CustomRunVm site={site} zone={zone} project={project} onClose={() => setManageMenuEl(null)}>
          <div className='justify-center' id='customRunMenuAction'>
            <SessionIcon className={styles.sidebar__action_menu_dropdown_icon} />
            <span className={styles.sidebar__item_label}>Custom Run</span>
          </div>
        </CustomRunVm>
      ),
      onClick: hideManageActions,
    },
    {
      key: 'create-project',
      display: project.isChildCreationEnabled && project.authority === MANAGER,
      caption: (
        <CreateResource resourceType='Project' selectedSiteId={site.resourceId} selectedZoneId={zone.resourceId} selectedParentProjectId={project.resourceId} onClose={() => setManageMenuEl(null)}>
          <div className='justify-center'>
            <AddProjectIcon className={styles.sidebar__action_menu_dropdown_icon} />
            <span className={styles.sidebar__item_label}>Add project</span>
          </div>
        </CreateResource>
      ),
      onClick: hideManageActions,
    },
    {
      key: 'add-users',
      display: project.authority === MANAGER,
      caption: (
        <AddUsers onClose={() => setManageMenuEl(null)} selectedResource={{ site, zone, project }} withMenuStyle={true}>
          <div className='justify-center'>
            <AddUsersIcon className={styles.sidebar__action_menu_dropdown_icon} />
            <span className={styles.sidebar__item_label}>Add users</span>
          </div>
        </AddUsers>
      ),
      onClick: hideManageActions,
    },
    {
      key: 'delete',
      disabled: project.children?.length > 0,
      disabledTooltip: 'Project can not be deleted as it contains other projects',
      display: project.authority === MANAGER,
      caption: (
        <ConfirmDialog
          title='Delete'
          text={
            <>
              <p style={{ textAlign: 'center', fontSize: '1.1em' }}>⚠️ Careful ! Data stored on this storage will not be deleted ⚠️</p>
              <p>
                You need to manualy delete data stored in the storage. Please refer to the{' '}
                <a href='#' onClick={() => documentationService.downloadDatamanagerDocumentation()}>
                  documentation
                </a>{' '}
                or contact your datamanager
              </p>
              <p>
                {site.isRunLevel ? `Warning! Other people may work on project '${project.name}', are you sure you want to delete it ?` : `Are you sure you want to delete project '${project.name}'`}
              </p>
            </>
          }
          isMenuItem
          onConfirm={() => dispatch(deleteProject(site.resourceId, zone.resourceId, project))}
          onClose={() => setManageMenuEl(null)}>
          <div className='justify-center'>
            <DeleteIcon className={styles.sidebar__action_menu_dropdown_icon} />
            <span className={styles.sidebar__item_label}>Delete</span>
          </div>
        </ConfirmDialog>
      ),
      onClick: hideManageActions,
    },
    {
      key: 'info',
      display: true,
      caption: (
        <>
          <Link
            to={{ pathname: `/project-info/${project.resourceId}` }}
            className={styles.sidebar__custom_link + ' ' + styles.menu_item_content}
            style={{ textDecoration: 'none', color: 'currentColor' }}>
            <div key={projectInfoLink.label} className='justify-center'>
              <InfoIcon className={styles.sidebar__action_menu_dropdown_icon} />
              <span className={styles.sidebar__item_label}>{projectInfoLink.label}</span>
            </div>
          </Link>
        </>
      ),
      onClick: hideManageActions,
    },
  ];

  return (
    <>
      <ListItem className={classNames(styles.sidebar__list_item, styles.sidebar__project_list_item)} disableGutters button onClick={() => setExpandProjectList(!expandProjectList)}>
        {(() => {
          if (project.children?.length > 0) {
            if (expandProjectList) {
              return <ExpandLess className={styles.sidebar__icon} />;
            } else {
              return <ExpandMore className={styles.sidebar__project_arrow} />;
            }
          } else {
            return <div className={styles.sidebar__project_leaf}></div>;
          }
        })()}
        {project.nodeRepresentation === ProjectType.FOLDER && (
          <div>
            <ListItemIcon className={styles.sidebar__folder_icon}>
              <FolderIcon />
            </ListItemIcon>
          </div>
        )}
        <div className={styles.sidebar__list_item}>
          <div>
            <ListItemText className={classNames(styles.sidebar__nav_item_text, styles.sidebar__overflow_ellipsis)}>
              <Typography sx={{ fontWeight: '500', lineHeight: '1.25' }}>{project.name}</Typography>
            </ListItemText>
          </div>
          <div>
            {project.isRunLevel && (
              <ListItemIcon className={styles.sidebar__action_icon} onClick={() => !disableInstanceInitialize && preInitializeVMInstance()} id='runVMMenuAction'>
                <SessionIcon className={disableInstanceInitialize ? styles.sidebar__icon_disabled : ''} />
              </ListItemIcon>
            )}
            <ListItemIcon aria-controls='simple-menu' aria-haspopup='true' onClick={showManageActions} className={styles.sidebar__action_icon} id='actionIconMore'>
              <MoreIcon />
            </ListItemIcon>
            <CustomMenu open={Boolean(manageMenuEl)} menuItems={manageItems} anchorElement={manageMenuEl} onClose={hideManageActions} />
          </div>
        </div>
      </ListItem>
      <Collapse in={expandProjectList}>
        {project.children?.length > 0 &&
          project.children
            .sort((p1, p2) => p1.name.localeCompare(p2.name))
            .map(child => (
              <div className={styles.sidebar__indent} key={project.resourceId}>
                <ProjectListItem project={child} zone={zone} site={site} preInitializeVMInstance={preInitializeVMInstance} />
              </div>
            ))}
      </Collapse>
    </>
  );
}

export default ProjectListItem;
