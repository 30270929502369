import React from 'react';

import { Grid, List, ListItem, ListItemAvatar, ListItemText } from '@mui/material';
import UserIcon from '@mui/icons-material/AccountCircle';
import Loader from 'components/loader/loader';

import { ManagerDTO } from 'types';
import './zone-manager-list.scss';

type Props = {
  managerList: ManagerDTO[],
  isFetchingManagerList: boolean
};

const ZoneManagerList = ({ managerList, isFetchingManagerList }: Props) => {
  managerList.sort((a, b) => (a.lastname + a.firstname + a.userId).localeCompare(b.lastname + b.firstname + b.userId));

  return (
    <div className='manager-list-root'>
      <div className='manager-list-container'>
        {isFetchingManagerList ? (
          <Grid container direction='row' justifyContent='center' alignItems='center'>
            <Loader className='loader-icon' />
          </Grid>
        ) : (
            <div className='manager-list'>
              <List dense disablePadding>
                {managerList.map((manager, index) => {
                  const labelId = `checkbox-list-secondary-label-${manager.userId}`;
                  return (
                    <ListItem key={manager.userId} classes={{ root: index % 2 !== 1 ? 'list-item-odd' : '' }}>
                      <ListItemAvatar>
                        {manager.avatar ? (
                          <img src={`data:image/jpeg;base64,${manager.avatar}`} className='managers-block_content__userFilledAvatar' alt='Avatar' />
                        ) : (
                            <UserIcon className='manager-avatar' />
                        )}
                      </ListItemAvatar>
                      <ListItemText
                        id={labelId}
                        classes={{
                          primary: 'label-bold',
                        }}
                        primary={`${manager.userId} ${manager.firstname || manager.lastname ? ` - ${manager.firstname} ${manager.lastname}` : ''}`}
                        secondary={manager.email || 'Not yet connected'}/>
                    </ListItem>
                  );
                })}
              </List>
              {managerList.length === 0 && (
                <div className='noManager'>There is no manager for this zone. Check site info or project info.</div>
              )}
            </div>
        )}
      </div>
    </div>
  );
};

export default ZoneManagerList;
