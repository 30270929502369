import { RECEIVE_DELETE_PROJECT_INSTANCES, ReceiveDeleteProjectInstancesAction } from 'store/actions/resource-project-actions';
import { RECEIVE_DELETE_ZONE_INSTANCES, ReceiveDeleteZoneInstancesAction } from 'store/actions/resource-zone-actions';
import {
  ERROR_RECEIVING_CREATE_VM_INSTANCE,
  ERROR_RECEIVING_DELETE_VM_INSTANCE,
  ERROR_RECEIVING_VM_INSTANCE_LIST,
  INITIALIZE_VM_INSTANCE,
  INIT_VM_INSTANCE_LIST,
  RECEIVE_CREATE_VM_INSTANCE,
  RECEIVE_UPDATE_VM_INSTANCE_CONTRIBUTORS,
  RECEIVE_VM_INSTANCE_LIST,
  REQUEST_DELETE_VM_INSTANCE,
  REQUEST_VM_INSTANCE_LIST,
  VmInstanceAction
} from 'store/actions/vm-instance-actions';
import { VmDTO } from 'types';

type VmInstancesState = {
  isFetching: boolean,
  sessions: VmDTO[],
  currentPage: number,
  pageSize: number,
  totalElements: number
};

const initialState: VmInstancesState = {
  isFetching: false,
  sessions: [],
  currentPage: 0,
  pageSize: 25,
  totalElements: 0,
};

const vmInstanceReducer = (state = initialState, action: VmInstanceAction | ReceiveDeleteZoneInstancesAction | ReceiveDeleteProjectInstancesAction): VmInstancesState => {
  switch (action.type) {
    case INIT_VM_INSTANCE_LIST:
      return {
        ...state,
        sessions: [],
      };
    case REQUEST_VM_INSTANCE_LIST:
      return {
        ...state,
        isFetching: true,
        currentPage: action.pageNumber,
        pageSize: action.pageSize,
      };
    case RECEIVE_VM_INSTANCE_LIST:
      // Keep instances object that do not exist in backend yet (added manually by front when starting a new instance)
      const vmInstancePending = Object.values(state.sessions).filter(instance => !instance.name);
      return {
        ...state,
        isFetching: false,
        sessions: vmInstancePending.concat(action.paginatedSessionsDTO.sessions),
        currentPage: action.paginatedSessionsDTO.currentPage,
        totalElements: action.paginatedSessionsDTO.totalElements,
      };
    case ERROR_RECEIVING_VM_INSTANCE_LIST:
      return {
        ...state,
        isFetching: false,
      };
    case INITIALIZE_VM_INSTANCE: {
      //push new VM to the beginning of the session array
      state.sessions.unshift(action.initialVmInstanceData);
      // Not sure if we should remove an element from the page when creating new instance
      // If creation fails and whe remove the pending VM
      // we could get a page that is not full while there are stil more VMs to be displayed
      const newSessions = state.sessions.slice(0, state.pageSize);
      return {
        ...state,
        sessions: newSessions,
      };
    }
    case RECEIVE_CREATE_VM_INSTANCE: {
      // Update pending VM
      const firstPendingSessionIndex = state.sessions.findIndex(el => !el.name);
      if (firstPendingSessionIndex > -1) {
        state.sessions[firstPendingSessionIndex].name = action.instanceName;
        state.sessions[firstPendingSessionIndex].status = action.status;
        state.sessions[firstPendingSessionIndex].sessionId = action.sessionId;
      }
      return {
        ...state,
      };
    }
    case ERROR_RECEIVING_CREATE_VM_INSTANCE: {
      const firstPendingSessionIndex = state.sessions.findIndex(el => !el.name);//Should be 0 or -1
      if (firstPendingSessionIndex > -1) {
        state.sessions.splice(firstPendingSessionIndex, 1);//Remove first pending session
      }
      return {
        ...state,
      };
    }
    case REQUEST_DELETE_VM_INSTANCE:
    case ERROR_RECEIVING_DELETE_VM_INSTANCE:
      state.sessions.map(el => (el.name === action.instanceName ? { ...el, status: action.status } : { ...el }));
      return {
        ...state,
      };

    case RECEIVE_UPDATE_VM_INSTANCE_CONTRIBUTORS:
      state.sessions.map(instance => (instance.name === action.instanceName
        ?
        { ...instance, contributorIds: action.contributorIds } : instance));
      return {
        ...state,
      };

    // When deleting zone
    case RECEIVE_DELETE_ZONE_INSTANCES:
      const sessionsNotInDeletedZone = state.sessions.filter(el => el.zoneId !== action.zoneId);
      return {
        ...state,
        sessions: sessionsNotInDeletedZone,
      };

    // When deleting project
    case RECEIVE_DELETE_PROJECT_INSTANCES:
      const sessionsNotInDeletedProject = state.sessions.filter(el => el.projectId !== action.projectId);
      return {
        ...state,
        sessions: sessionsNotInDeletedProject,
      };

    default:
      return state;
  }
};

export default vmInstanceReducer;