import { 
  ADD_FILE_UPLOAD, 
  DELETE_FILE_UPLOAD, 
  FileUploadActionType, 
  SHOW_REDIRECTION_WARNING_MODAL 
} from 'store/actions/file-upload-actions';
import { UploadDTO } from 'types';


type FileUploadState = {
  fileUploadList: UploadDTO[], // TODO Chat is the type of this object ?
  showRedirectionWarningModal: boolean,
};

const initialState: FileUploadState = {
  fileUploadList: [],
  showRedirectionWarningModal: false,
};

const fileUploadReducer = ( state = initialState, action: FileUploadActionType): FileUploadState => {
  switch (action.type) {
    case ADD_FILE_UPLOAD:
      return {
        ...state,
        fileUploadList: [...state.fileUploadList, { siteId : action.siteId, projectId: action.projectId, uploadId: action.uploadId, file: action.file }],
      };
    case DELETE_FILE_UPLOAD:
      return {
        ...state,
        fileUploadList: state.fileUploadList.filter(el => el.uploadId !== action.uploadId),
      };
    case SHOW_REDIRECTION_WARNING_MODAL:
      return {
        ...state,
        showRedirectionWarningModal: true,
      };
    default:
      return state;
  }
};

export default fileUploadReducer;