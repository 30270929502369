import axios from 'axios';
import getAccessToken from './access-token-service';


export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL !== undefined ? process.env.REACT_APP_API_BASE_URL : '';

const instance = axios.create({
  baseURL: API_BASE_URL,
});

instance.interceptors.request.use(
  async config => {
    const token = await getAccessToken();
    // eslint-disable-next-line no-param-reassign
    config.headers = config.headers || {};
    // eslint-disable-next-line no-param-reassign
    config.headers.authorization = `Bearer ${token}`;
    return config;
  },
  error => Promise.reject(error)
);

export default instance;
