import { 
  ADD_STORAGE_TEMPLATES,
  SET_STORAGE_TEMPLATES_LIST, 
  StorageTemplateActionType 
} from 'store/actions/storage-template-actions';
import { StorageTemplateDTO } from 'types';

type StorageTemplateState = {
  storageTemplate: {
    [id: string]: StorageTemplateDTO[]
  }
};

const initialState: StorageTemplateState = {
  storageTemplate: {},
};

const storageTemplateReducer = (state = initialState, action: StorageTemplateActionType): StorageTemplateState => {
  switch (action.type) {
    case SET_STORAGE_TEMPLATES_LIST:
      return {
        ...state,
        storageTemplate: action.templates,
      };
    case ADD_STORAGE_TEMPLATES:
      return {
        ...state,
        storageTemplate: { ...state.storageTemplate, ...action.templates },
      };
    default:
      return state;
  }
};

export default storageTemplateReducer;

