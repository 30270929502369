import { ResourceAccessNode, ResourceAccessTree } from 'types';

const RERSOURCE_PATH_DELIMITER = '/';

const flatten = (nodes: ResourceAccessNode[]) => {
  return nodes.reduce((acc, node) => {
    acc.push(node);
    if (node.children) {
      acc.push(...flatten(node.children));
    }
    return acc;
  }, [] as ResourceAccessNode[]);
};

export default {
  splitPath(path: string): string[] {
    if (path == null) return [];
    return path.substring(1).split(RERSOURCE_PATH_DELIMITER);
  },
  getResource(resourceAccessTree: ResourceAccessTree, humanReadablePath: string): ResourceAccessNode | undefined {
    const nodeNames = this.splitPath(humanReadablePath);
    let currentNode = resourceAccessTree.sites.find(site => site.label ? site.label === nodeNames[0] : site.name === nodeNames[0]);
    nodeNames.shift();//Remove first element
    nodeNames.forEach(name => {
      currentNode = currentNode?.children.find(resource => resource.label ? resource.label === name : resource.name === name);
    });

    return currentNode;
  },
  getParentFromResource(sites: ResourceAccessNode[], resource: ResourceAccessNode): ResourceAccessNode | undefined {
    const nodeIds = this.splitPath(resource.resourcePath);
    let currentNode = sites.find(site => site.resourceId === parseInt(nodeIds[0]));
    nodeIds.shift();//Remove first element
    nodeIds.pop();//Remove last element
    nodeIds.forEach(id => {
      currentNode = currentNode?.children.find(resource => resource.resourceId === parseInt(id));
    });

    return currentNode;
  },
  getSiteResourcePath(sites: ResourceAccessNode[], siteId: number): string {
    const site = sites.find(site => site.resourceId === siteId);
    return site ? site.resourcePath : '';
  },
  getZoneResourcePath(sites: ResourceAccessNode[], zoneId: number): string {
    const zone = sites.flatMap(site => site.children).find(zone => zone.resourceId === zoneId);
    return zone ? zone.resourcePath : '';
  },
  getProjectResourcePath(sites: ResourceAccessNode[], projectId: number): string {
    const project = flatten(sites).find(project => project.resourceId === projectId);
    return project ? project.resourcePath : '';
  },
  getSiteIdFromResourcePath(resourcePath: string): number {
    const nodeIds = this.splitPath(resourcePath);
    return nodeIds.length > 0 ? parseInt(nodeIds[0]) : -1;
  },
  getZoneIdFromResourcePath(resourcePath: string): number {
    const nodeIds = this.splitPath(resourcePath);
    return nodeIds.length > 1 ? parseInt(nodeIds[1]) : -1;
  },
  getProjectIdFromResourcePath(resourcePath: string): number {
    const nodeIds = this.splitPath(resourcePath);
    return nodeIds.length > 2 ? parseInt(nodeIds[nodeIds.length - 1]) : -1;
  },
  getSite(sites: ResourceAccessNode[], siteId: number): ResourceAccessNode | undefined {
    return sites.find(site => site.resourceId === siteId);
  },
  getZone(sites: ResourceAccessNode[], zoneId: number): ResourceAccessNode | undefined {
    return sites.flatMap(site => site.children).find(zone => zone.resourceId === zoneId);
  },
  getHumanReadablePath(sites: ResourceAccessNode[], path: string): string {
    const nodeIds = this.splitPath(path);
    let currentNode = sites.find(site => site.resourceId === parseInt(nodeIds[0]));
    let humanReadablePath = '/' + (currentNode?.label || currentNode?.name);
    nodeIds.shift();//Remove first element
    nodeIds.forEach(id => {
      currentNode = currentNode?.children.find(resource => resource.resourceId === parseInt(id));
      humanReadablePath += `/${currentNode?.name}`;
    });

    return humanReadablePath;
  },
  getResourcePathFromHumanReadablePath(sites: ResourceAccessNode[], humanReadablePath: string): string {
    if (!humanReadablePath) return '';
    const nodeNames = this.splitPath(humanReadablePath);
    let currentNode = sites.find(site => site.label ? site.label === nodeNames[0] : site.name === nodeNames[0]);
    let resourcePath = '/' + currentNode?.resourceId;
    nodeNames.shift();//Remove first element
    nodeNames.forEach(name => {
      currentNode = currentNode?.children.find(resource => resource.name === name);
      resourcePath += `/${currentNode?.resourceId}`;
    });

    return resourcePath;
  },
};
