import vmInstanceService from 'services/vm-instance-service';
import { AppThunk } from 'store/reducers';
import { VmDTO } from 'types';

export const OPEN_VM_EXTRA_INSTALLS_MODAL = 'OPEN_VM_EXTRA_INSTALLS_MODAL';
export const CLOSE_VM_EXTRA_INSTALLS_MODAL = 'CLOSE_VM_EXTRA_INSTALLS_MODAL';
export const SET_EXTRA_INSTALLS_TO_ADD = 'SET_EXTRA_INSTALLS_TO_ADD';

export type OpenVmExtraInstallsModal = {
  type: typeof OPEN_VM_EXTRA_INSTALLS_MODAL,
  selectedVM: VmDTO
};

export type CloseVmExtraInstallsModal = {
  type: typeof CLOSE_VM_EXTRA_INSTALLS_MODAL
};

export type SetVmExtraInstallsToAddAction = {
  type: typeof SET_EXTRA_INSTALLS_TO_ADD,
  installs: string[]
};

export const openVmExtraInstallsModal = (selectedVM: VmDTO): OpenVmExtraInstallsModal => ({ type: OPEN_VM_EXTRA_INSTALLS_MODAL, selectedVM });
export const closeVmExtraInstallsModal = (): CloseVmExtraInstallsModal => ({ type: CLOSE_VM_EXTRA_INSTALLS_MODAL });
export const setVmExtraInstallsToAddAction = (installs: string[] = []): SetVmExtraInstallsToAddAction => ({
  type: SET_EXTRA_INSTALLS_TO_ADD,
  installs,
});

export const updateSmgInstallsAction = (): AppThunk<Promise<void>> => async (dispatch, getState) => {
  const { selectedVM, extraInstallsToAdd } = getState().vmExtraInstallsReducer;

  if (selectedVM) {
    const requestBody = {
      smgInstalls: selectedVM.smgInstalls || [],
    };
    requestBody.smgInstalls = requestBody.smgInstalls.concat(extraInstallsToAdd.filter(e=>requestBody.smgInstalls.indexOf(e) === -1));

    vmInstanceService
      .updateSessionSmgInstall(selectedVM.sessionId, requestBody)
      .then(() => dispatch(closeVmExtraInstallsModal()));
  }
};

export type VmExtraInstallType = OpenVmExtraInstallsModal
| CloseVmExtraInstallsModal 
| SetVmExtraInstallsToAddAction;