import { createWriteStream } from 'streamsaver';
import { HomeDirectoryDTO, PaginatedFilesDTO, ShareFileInfoDTO } from 'types';
import getAccessToken from './access-token-service';
import instance, { API_BASE_URL } from './axios';
import toastService from './toast-service';

export default {

  getSiteOrProjectUrl(siteId: number | undefined, projectId: number | undefined) {
    if (typeof projectId == "number" && projectId > 0) return `/api/cloud-storage/project/${projectId}`;
    else if (typeof siteId == "number" && siteId > 0) return `/api/cloud-storage/site/${siteId}`;
    else return '';
  },

  getUploadblobInfo(siteId: number, projectId: number, fileName: string) {
    const url = this.getSiteOrProjectUrl(siteId,projectId) + `/uploadinfo/${fileName}`;
    return instance.get(url);
  },

  uploadFile(siteId: number, projectId: number, uploadId: string, body) {
    const url = this.getSiteOrProjectUrl(siteId,projectId) + `/upload/${uploadId}`;
    return instance.post(url, body, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },

  uploadFileWithCommandLine(siteId: number, projectId: number, sourcePaths: string[], selectedOs: string, selectedProxy: string, sasTokenLifetimeInHours: number, bandwidthInMB: number) {
    let srcPathUrl = '';
    if (sourcePaths) {
      srcPathUrl = sourcePaths.map(encodeURIComponent).join('&sourcePath=');
    }

    const url = this.getSiteOrProjectUrl(siteId,projectId)
      + `/uploadScript?sourcePath=${srcPathUrl}&selectedOs=${selectedOs}&selectedProxy=${selectedProxy}`
      + `&sasTokenLifetimeInHours=${sasTokenLifetimeInHours}`
      + `&bandwidthInMb=${bandwidthInMB}`;
    return this.downloadFile(url).catch(() => toastService.error('Error while downloading script file'));
  },

  downloadFile(url) {
    return instance.get(url).then(response => {
      const contentDispositionHeader = response.headers['content-disposition'];
      // filename=\"j0000004-my-session.dcv\"
      const fileNameStartIndex = contentDispositionHeader.indexOf('filename="') + 10;
      const fileNameEndIndex = contentDispositionHeader.lastIndexOf('"');

      const fileName = contentDispositionHeader.substring(fileNameStartIndex, fileNameEndIndex);

      const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.setAttribute('download', fileName);

      document.body.appendChild(link);
      link.click();
      if (link.parentNode) link.parentNode.removeChild(link);
    });
  },

  redirectToVmURL(resolve) {
    return resolve.then(response => {
      const vmURL = response.data;
      const win = window.open(vmURL, '_blank');
      if (win) win.focus();
    });
  },

  getFiles(siteId: number | undefined, projectId: number | undefined, continuationToken: string | null, lastItemIndex: number, pageSize: number): Promise<PaginatedFilesDTO> {
    const encodedToken = continuationToken ? btoa(continuationToken) : '';

    let lastItem;
    if (lastItemIndex != null && lastItemIndex > -1) {
      lastItem = lastItemIndex;
    } else {
      lastItem = -1;
    }

    let size;
    if (pageSize != null) {
      size = pageSize;
    } else {
      size = 100;
    }

    if (siteId || projectId) return instance.get(this.getSiteOrProjectUrl(siteId, projectId) + `/files?continuationToken=${encodedToken || ''}&lastItemIndex=${lastItem}&size=${size}`)
      .then(response => response.data);
    else return Promise.reject();
  },

  getFileSignedURL(siteId: number, projectId: number, fileName: string) {
    const url = this.getSiteOrProjectUrl(siteId, projectId) + `/files/${fileName}`;
    return instance
      .get(url)
      .then(response => response.data)
      .catch(() => {
        toastService.error('File error');
        return Promise.reject();
      });
  },

  getFileBlobSignedURL(siteId: number, projectId: number, fileName: string) {
    const url =this.getSiteOrProjectUrl(siteId, projectId) + `/files/bloburl/${fileName}`;
    return instance
      .get(url)
      .then(response => response.data)
      .catch(() => {
        toastService.error('File error');
        return Promise.reject();
      });
  },

  downloadProjectFile(siteId: number, projectId: number, fileName: string) {
    return this.getFileSignedURL(siteId, projectId, fileName).then(signedURL => {
      window.open(signedURL, '_blank');
    });
  },

  downloadFileFromBlob(siteId: number, projectId: number, fileName: string) {
    return this.getFileBlobSignedURL(siteId, projectId, fileName).then(signedURL => {
      window.open(signedURL, '_blank');
    });
  },

  downloadFilesFromComamndLine(siteId: number, projectId: number, selectedOs: string, selectedProxy: string, sasTokenLifetimeInHours: number, bandwidthInMB: number) {
    const url = this.getSiteOrProjectUrl(siteId, projectId)
      + `/downloadScript?selectedOs=${selectedOs}&selectedProxy=${selectedProxy}`
      + `&sasTokenLifetimeInHours=${sasTokenLifetimeInHours}&bandwidthInMb=${bandwidthInMB}`;
    return this.downloadFile(url).catch(() => toastService.error('Error while downloading script file'));
  },

  shareFile(siteId: number, projectId: number, shareFileInfos: ShareFileInfoDTO) {
    const url = this.getSiteOrProjectUrl(siteId, projectId) + `/file/email-share`;

    return instance
      .post(url, shareFileInfos)
      .then(() => {
        toastService.success('File shared successfully');
      })
      .catch(() => {
        toastService.error('Error while sharing file');
        return Promise.reject();
      });
  },

  getSmgInstalls(siteId, search) {
    const url = `/api/cloud-storage/smginstalls?site-id=${siteId}&search=${search}`;
    return instance.get(url).then(response => response.data);
  },

  getUserHomeDirectoryInfos(): Promise<HomeDirectoryDTO> {
    const url = '/api/cloud-storage/user-home/infos';
    return instance.get(url).then(res => res.data);
  },

  async downloadUserHomeDirectory() {
    toastService.success('Downloading home directory');
    const token: string = await getAccessToken();
    const url = API_BASE_URL + '/api/cloud-storage/user-home';
    return fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
      .then(res => {
        if (res.ok) {
          // If response returns success code, go to the next step
          return res;
        } else {
          // If response returns error code, go to the catch step
          return Promise.reject();
        }
      })
      .then(res => {
        let fileName = 'home_directory.tar';
        const contentDispositionHeader = res.headers.get('Content-Disposition');
        if (contentDispositionHeader) {
          const fileNameStartIndex = contentDispositionHeader.indexOf('filename="') + 10;
          const fileNameEndIndex = contentDispositionHeader.lastIndexOf('"');
          fileName = contentDispositionHeader.substring(fileNameStartIndex, fileNameEndIndex);
        }

        const fileStream = createWriteStream(fileName);
        const writer = fileStream.getWriter();

        if (res.body) {
          if (res.body.pipeTo) {
            writer.releaseLock();
            return res.body.pipeTo(fileStream);
          }

          const reader = res.body.getReader();
          const pump = () => reader.read().then(({ value, done }) => (done ? writer.close() : writer.write(value).then(pump)));

          return pump();
        }

        return Promise.reject();
      })
      .catch(() => {
        toastService.error('Error while downloading home directory');
        return Promise.reject();
      });
  },

  deleteUserHomeDirectory(): Promise<void> {
    const url = '/api/cloud-storage/user-home';
    return instance
      .delete(url)
      .then(() => {
        toastService.success('Deleting home directory');
      })
      .catch(() => {
        toastService.error('Error while deleting home directory');
        return Promise.reject();
      });
  },
};
