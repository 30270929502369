import { SnapshotPolicyDTO } from 'types';

export const DEFAULT_SNAPSHOT_POLICY: SnapshotPolicyDTO = {
  snapshotsEnabled: true, 
  hourlySchedule: { 
    numberToKeep: 24,
    minute: 0,
  },
  dailySchedule: {
    numberToKeep: 10,
    minute: 0,
    hour: 20,
  },
};

export const SNAPSHOT_POLICY_DISABLED: SnapshotPolicyDTO = {
  snapshotsEnabled: false,
};
