import {Button} from '@mui/material';
import OsSelection, {selectedOsValue, selectedProxyValue} from 'components/shared/os-selection/os-selection';
import {useState} from 'react';
import fileService from 'services/file-service';
import 'components/download/project-download.scss';
import MaxTimeAndBandwidth from 'components/shared/max-time-and-bandwidth/max-time-and-bandwidth';

type Props = {
  projectId?: number,
  siteId?: number,
};

const CommandLineDownloadTab = ({projectId, siteId = 0}: Props) => {

  const [sasTokenLifetimeInHours, setSasTokenLifetimeInHours] = useState(12);
  const [bandwidthInMB, setBandwidthInMB] = useState(0);

  const handleSasTokenLifetimeChange = (event, newValue) => {
    setSasTokenLifetimeInHours(newValue);
  };

  const handleBandwithChange = (event, newValue) => {
    setBandwidthInMB(newValue);
  };

  const commandLineDownload = () => {
    fileService.downloadFilesFromComamndLine(siteId || -1, projectId || -1, selectedOsValue, selectedProxyValue, sasTokenLifetimeInHours, bandwidthInMB);
  };

  return (
      <div>
        <div className='description'>
          <div>To download all the files, please follow these steps :</div>
          <br/>
          <div>1. Select your OS and a proxy if necessary</div>
          <div>2. A "download-script" will be downloaded to your computer</div>
          <div>3. <strong>Execute</strong> that script and <strong>wait</strong> for completion of the download</div>
          <div>4. The downloaded files are now accessible in the directory where the script was executed</div>
        </div>
        <br/>
        <OsSelection/>
        <MaxTimeAndBandwidth sasTokenLifetimeInHours={sasTokenLifetimeInHours}
                             handleSasTokenLifetimeChange={handleSasTokenLifetimeChange}
                             bandwidthInMB={bandwidthInMB}
                             handleBandwithChange={handleBandwithChange}
                             isUpload={false}/>
        <div className='buttonContainer'>
          <Button onClick={commandLineDownload} variant='contained' color='primary' className='validationButton' disabled={false}>
            DOWNLOAD
          </Button>
        </div>
      </div>
  );
};

export default CommandLineDownloadTab;