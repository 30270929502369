'use client';

import { PureComponent } from 'react';
import {
  Area,
  ComposedChart,
  Line,
  ResponsiveContainer,
  XAxis,
  YAxis
} from 'recharts';
import './testLatency.scss';

type Props = {
  url: string;
};

type MyState = {
  data: Array<unknown>;
};

class TestLatency extends PureComponent<Props, MyState> {

  target = {
    ok: [20, 100],
    bof: [90, 150],
    nok: [120, 200],
  };

  interval: NodeJS.Timeout | null = null;

  url: string;

  //var data = Array.from(Array(100).keys()).map((item) => ({latency: 20 + Math.random() * 100}));
  //var newData = data.map((item) => ({ref: [0,90], ...item}));
  constructor(props: Props) {
    super(props);

    this.url = decodeURIComponent(props.url);

    this.state = {
      data: [{
        time: this.timeNow(),
        latency: 20,
        ...this.target,
      }],
    };
  }

  timeNow() {
    const now = new Date();
    return now.getHours() + ':' + now.getMinutes() + ':' + now.getSeconds();
  }

  componentDidMount() {
    this.interval = setInterval(() =>
      this.callLatency().then((latency) => {
        this.setState((state) => {
          const newData = Array.from(state.data);
          newData.push(
            {
              time: this.timeNow(),
              latency: latency,
              ...this.target,
            });
          return { data: newData };
        });

      })
    , 5000);
  }

  componentWillUnmount() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  async callLatency() {
    const now = new Date().getTime();
    try {
      await fetch(this.url, {
        'method': 'HEAD',
        'mode': 'no-cors',
      });
      const latency = (new Date().getTime() - now); // SSL tunnel establishment requires 3 request, plus the actual request that's 4 round trip
      return Math.round(latency);
    } catch {
      return console.error('error url ' + this.url + ' unreachable');
    }
  }

  render() {
    return (
      <div>
        <div className='section-title'>Latency calculation for : {this.url}</div>
        <ResponsiveContainer width='100%' height={400}>
          <ComposedChart
            data={this.state.data}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <defs>
              <linearGradient id='green' x1='0' y1='0' x2='0' y2='1'>
                <stop offset='0%' stopColor='#82ca9d' stopOpacity={0} />
                <stop offset='100%' stopColor='#82ca9d' stopOpacity={1} />
              </linearGradient>
              <linearGradient id='orange' x1='0' y1='0' x2='0' y2='1'>
                <stop offset='0%' stopColor='#FF9B00' stopOpacity={1} />
                <stop offset='100%' stopColor='#FF9B00' stopOpacity={0.2} />
              </linearGradient>
              <linearGradient id='red' x1='0' y1='0' x2='0' y2='1'>
                <stop offset='0%' stopColor='#FF0000' stopOpacity={1} />
                <stop offset='100%' stopColor='#FF0000' stopOpacity={0} />
              </linearGradient>
            </defs>
            <XAxis dataKey='time' />
            <YAxis />
            <Area dataKey='nok' stroke='none' fillOpacity={0.5} fill='url(#red)' />
            <Area dataKey='bof' stroke='none' fillOpacity={0.5} fill='url(#orange)' />
            <Area dataKey='ok' stroke='none' fillOpacity={1} fill='url(#green)' />
            <Line type='monotone' dataKey='latency' stroke='#0000FF' />
          </ComposedChart>
        </ResponsiveContainer>
      </div>
    );
  }
}

export default TestLatency;
