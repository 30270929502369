import userService from 'services/user-service';
import vmInstanceService from 'services/vm-instance-service';
import { AppThunk } from 'store/reducers';
import { UserDTO, VmDTO } from 'types';
import { updateVMInstanceContributorsAction } from './vm-instance-actions';

export const OPEN_VM_SHARE_MODAL = 'OPEN_VM_SHARE_MODAL';
export const CLOSE_VM_SHARE_MODAL = 'CLOSE_VM_SHARE_MODAL';
export const REQUEST_VM_INSTANCE_CONTRIBUTORS = 'REQUEST_VM_INSTANCE_CONTRIBUTORS';
export const RECEIVE_VM_INSTANCE_CONTRIBUTORS = 'RECEIVE_VM_INSTANCE_CONTRIBUTORS';
export const DELETE_VM_INSTANCE_CONTRIBUTOR = 'DELETE_VM_INSTANCE_CONTRIBUTOR';
export const DELETE_VM_INSTANCE_SHARED_EMAIL = 'DELETE_VM_INSTANCE_SHARED_EMAIL';
export const SET_CONTRIBUTORS_TO_ADD = 'SET_CONTRIBUTORS_TO_ADD';

export type OpenVmShareModal = { 
  type: typeof OPEN_VM_SHARE_MODAL, 
  selectedVM: VmDTO
};
export type CloseVmShareModal =  { 
  type: typeof CLOSE_VM_SHARE_MODAL 
};
type RequestVMInstanceContributorsAction =  {
  type: typeof REQUEST_VM_INSTANCE_CONTRIBUTORS
};
type ReceiveVmInstanceContributorListAction =  {
  type: typeof RECEIVE_VM_INSTANCE_CONTRIBUTORS, 
  contributorList: UserDTO[]
};
export type DeleteVmInstanceContributorAction =  {
  type: typeof DELETE_VM_INSTANCE_CONTRIBUTOR, 
  id: string
};
export type DeleteVmInstanceSharedEmailAction =  { 
  type: typeof DELETE_VM_INSTANCE_SHARED_EMAIL, 
  email: string
};
export type SetContributorIdsToAddAction =  { 
  type: typeof SET_CONTRIBUTORS_TO_ADD, 
  contributorIds: string[],
  sharedEmails: string[]
};

export const openVmShareModal = (selectedVM): OpenVmShareModal => ({ type: OPEN_VM_SHARE_MODAL, selectedVM });
export const closeVmShareModal = (): CloseVmShareModal => ({ type: CLOSE_VM_SHARE_MODAL });
const requestVMInstanceContributorsAction = (): RequestVMInstanceContributorsAction => ({ type: REQUEST_VM_INSTANCE_CONTRIBUTORS });
const receiveVmInstanceContributorListAction = (contributorList): ReceiveVmInstanceContributorListAction => ({ type: RECEIVE_VM_INSTANCE_CONTRIBUTORS, contributorList });
export const deleteVmInstanceContributorAction = (id): DeleteVmInstanceContributorAction => ({ type: DELETE_VM_INSTANCE_CONTRIBUTOR, id });
export const deleteVmInstanceSharedEmailAction = (email): DeleteVmInstanceSharedEmailAction => ({ type: DELETE_VM_INSTANCE_SHARED_EMAIL, email });
export const setContributorIdsToAddAction = (contributors: string[] = []): SetContributorIdsToAddAction => ({
  type: SET_CONTRIBUTORS_TO_ADD,
  contributorIds: contributors.filter(idOrMail => idOrMail.indexOf('@') === -1),
  sharedEmails: contributors.filter(idOrMail => idOrMail.indexOf('@') !== -1),
});

export const getVMInstanceContributorsAction = (contributorIds: string): AppThunk<Promise<void>> => async dispatch => {
  if (contributorIds || !contributorIds.trim()) { 
    dispatch(requestVMInstanceContributorsAction());

    userService.getUsersByIds(contributorIds).then(contributorList => dispatch(receiveVmInstanceContributorListAction(contributorList)));
  }
};

export const updateVMContributorsAction = ():  AppThunk<Promise<void>> => async (dispatch, getState) => {
  const {
    selectedVM, contributorIdsToAdd, contributorIdsToDelete, sharedEmailsToAdd, sharedEmailsToDelete, 
  } = getState().vmInstanceContributorsReducer;

  if (selectedVM) {
    const requestBody = {
      contributorIdsToAdd,
      contributorIdsToDelete,
      sharedEmailsToAdd,
      sharedEmailsToDelete,
    };

    vmInstanceService
      .shareVM(selectedVM.sessionId, requestBody)
      .then(updatedVM => dispatch(updateVMInstanceContributorsAction(selectedVM.name, updatedVM.contributorIds)))
      .then(() => dispatch(closeVmShareModal()));
  }
};

export type VmInstanceContributorActionType = OpenVmShareModal  
| RequestVMInstanceContributorsAction
| ReceiveVmInstanceContributorListAction
| CloseVmShareModal  
| DeleteVmInstanceContributorAction 
| DeleteVmInstanceSharedEmailAction  
| SetContributorIdsToAddAction  ;