import { ReactNode, useEffect, useRef, useState } from 'react';

import DeleteUserIcon from '@mui/icons-material/Close';
import AddedUserIcon from '@mui/icons-material/Person';
import { Chip, FormControl, Input } from '@mui/material';
import cx from 'classnames';
import Loader from 'components/loader/loader';
import styles from 'components/user/users-selector-input.module.scss';
import fileService from 'services/file-service';


export const defaultChipRenderer = ({
  text, handleClick, handleDelete, className, iconClassName,
}, key) => (
  <Chip
    icon={<AddedUserIcon className={iconClassName}/>}
    key={key}
    className={className}
    onClick={handleClick}
    onDelete={handleDelete}
    label={text}
    deleteIcon={<DeleteUserIcon className={iconClassName}/>}/>
);

type Props = {
  value?: string,
  siteId: number,
  newChipKeyCodes: string[],
  inputProps: { [key: string]: string | boolean },
  alreadyInstalled: string[],
  placeholder?: string,
  disableUnderline: boolean,
  onInputChange?: (value) => void,
  onChange: (values: string[]) => void,
  chipRenderer: (any, key) => ReactNode
};

const VmInstallSelectorInput = ({
  value,
  siteId,
  inputProps,
  placeholder,
  disableUnderline,
  alreadyInstalled,
  newChipKeyCodes,
  onInputChange,
  onChange,
  chipRenderer,
}: Props) => {

  const [chips, setChips] = useState<string[]>([]);
  const [inputValue, setInputValue] = useState('');
  const [error, setError] = useState<string | null>(null);
  const [isFetching, setIsFetching] = useState(false);
  const [installsAutocomplete, setInstallsAutocomplete] = useState<string[]>([]);

  const autocompleteRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    document.addEventListener('click', handleClickOutsideAutocomplete);
    return () => document.removeEventListener('click', handleClickOutsideAutocomplete);
  }, []);

  const handleClickOutsideAutocomplete = (event) => {
    if (autocompleteRef.current && !autocompleteRef.current.contains(event.target) && inputValue) {
      addChip(inputValue);
    }
  };

  const handleAddChipWithKeyPress = (event) => {
    if (newChipKeyCodes.indexOf(event.key) !== -1) {
      addChip(event.target.value);
    }
  };

  const addChip = (chip) => {
    updateInput('');
    setInstallsAutocomplete([]);

    const chipTrim = chip.trim();

    if (chipTrim.length === 0) {
      setError(null);
    } else if (chips.includes(chipTrim)) {
      setError('Version ' + chipTrim + ' already installed');
    } else {
      const newChips = [...chips, chipTrim];
      setChips(newChips);
      setError(null);
      onChange(newChips);
    }
  };

  const handleDeleteChip = (chip, i) => {
    const c = [...chips];
    const changed = c.splice(i, 1);
    if (changed) {
      setChips(c);
      onChange(changed);
    }
  };

  const handleUpdateInput = (event) => {
    if (event && event.target) {
      updateInput(event.target.value);
    }
  };

  const updateInput = (v) => {
    setInputValue(v);
    if (onInputChange) onInputChange(v);
  };

  useEffect(() => {
    let subscribed = true;
    if (!inputValue) setInstallsAutocomplete([]);
    else {
      setIsFetching(true);
      fileService.getSmgInstalls(siteId, inputValue).then(installs => {
        if (!subscribed) return;
        setInstallsAutocomplete(installs.filter(install => chips.indexOf(install) === -1 && alreadyInstalled.indexOf(install) === -1));
        setIsFetching(false);
      }).catch(() => {
        setIsFetching(false);
      });
      return () => {
        subscribed = false;
      };
    }
  }, [inputValue]);


  const _chips = value ? [value] : [...chips];
  const chipComponents = _chips.map((tag, i) => {
    return chipRenderer(
      {
        tag,
        text: tag,
        chip: tag,
        handleDelete: () => handleDeleteChip(tag, i),
        className: styles.chip,
        iconClassName: styles.icon,
      },
      i
    );
  });
  // eslint-disable-next-line no-param-reassign
  inputProps.disableUnderline = true;
  return (
    <FormControl fullWidth style={{ zIndex: 99 }}>
      <div className='autocomplete' ref={autocompleteRef}>
        <div
          className={cx(styles.chipContainer, {
            [styles.inkbar]: !disableUnderline,
          })}>
          {chipComponents}
          <Input
            id='vm-installs-selector-input'
            classes={{
              input: cx(styles.input), // TODO, classes[variant]),
              root: cx(styles.inputRoot), // TODO, classes[variant]),
            }}
            value={inputValue}
            onChange={handleUpdateInput}
            onKeyDown={handleAddChipWithKeyPress}
            {...inputProps}
            placeholder={placeholder}/>
          {isFetching && <Loader className='autocomplete-loader'/>}
        </div>
        {installsAutocomplete.length > 0 && (
          <div className='autocomplete-items'>
            {installsAutocomplete.map(install => (
              <div key={install} onClick={() => addChip(install)}>
                {install}
              </div>
            ))}
          </div>
        )}
      </div>
      {error && (
        <div id='error' className={styles.errorMessage}>
          {error}
        </div>
      )}
    </FormControl>
  );
};

VmInstallSelectorInput.defaultProps = {
  inputProps: {},
  alreadyInstalled: [],
  disableUnderline: false,
  newChipKeyCodes: ['Enter', ' '],
  placeholder: 'Search Sismage Versions',
  chipRenderer: defaultChipRenderer,
};

export default VmInstallSelectorInput;
