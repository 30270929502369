import AddUsersIcon from '@mui/icons-material/GroupAdd';
import RefreshIcon from '@mui/icons-material/Refresh';
import { IconButton, Tooltip } from "@mui/material";
import { MANAGER } from 'constants/user-authority';
import { ConnectedProps, connect } from 'react-redux';
import { RootState } from 'store/reducers';
import AddUsers from './add-users';

const mapState = (state: RootState) => ({
  isManager: state.resourcesReducer.siteList.filter(site => site.authority === MANAGER
    || site.children.some(zone => zone.authority === MANAGER
      || zone.children.some(project => project.authority === MANAGER))).length > 0,
});

const connector = connect(mapState);

type ReduxProps = ConnectedProps<typeof connector>;

type OwnProps = {
  refresh: () => void;
};

type Props = OwnProps & ReduxProps;

const CustomToolbar = ({ refresh, isManager }: Props) => {
  return (
    <>
      <IconButton onClick={refresh}>
        <Tooltip title='Refresh' placement='top'>
          <RefreshIcon />
        </Tooltip>
      </IconButton>
      {isManager && (
        <IconButton color='primary'>
          <Tooltip title='Add user' placement='top'>
            <AddUsers callBack={refresh} withMenuStyle={false}>
              <AddUsersIcon />
            </AddUsers>
          </Tooltip>
        </IconButton>
      )}
    </>
  );
};

export default connector(CustomToolbar);
