import { CircularProgress, FormControl, NativeSelect } from '@mui/material';
import { MANAGER, USER } from "constants/user-authority";
import { Row } from './manage-users-utils';
import styles from './manage-users.module.scss';

type Props = {
  localData: Row[],
  updateUser: (rowData: Row) => void,
  value: string,
  isPending: boolean,
  canEdit: boolean,
  data,
  row: Row,
};

// eslint-disable-next-line
const PermissionSelector = ({ localData, updateUser, value, isPending, canEdit, data, row }: Props) => {
  const handlePermissionChange = (data, rowData: Row, newPermission) => {
    // eslint-disable-next-line no-param-reassign
    rowData.permissionAuthority = newPermission;
    // eslint-disable-next-line no-param-reassign
    localData[data.currentTableData[data.rowIndex].index].permissionAuthority = newPermission;
    updateUser(rowData);
  };

  return (
    <>
      <FormControl>
        <NativeSelect
          value={value}
          disabled={!canEdit || isPending}
          onChange={event => handlePermissionChange(data, row, event.target.value)}
          classes={{
            root: value === MANAGER ? styles.manage_user__darkBlueOption : styles.manage_user__darkSkyBlueOption,
            select: styles.manage_user__statusLabel,
            disabled: styles.manage_user__inputDisabled,
          }}
          className='user-authority-select'>
          <option value={MANAGER}>MANAGER</option>
          <option value={USER}>USER</option>
        </NativeSelect>
      </FormControl>
      {isPending &&
        <CircularProgress size={24} style={{ marginLeft: 15, position: 'relative', top: 4 }} />
      }
    </>
  );
};
export default PermissionSelector;