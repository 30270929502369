import {
  OPEN_VM_SHARE_MODAL,
  CLOSE_VM_SHARE_MODAL,
  REQUEST_VM_INSTANCE_CONTRIBUTORS,
  RECEIVE_VM_INSTANCE_CONTRIBUTORS,
  DELETE_VM_INSTANCE_CONTRIBUTOR,
  DELETE_VM_INSTANCE_SHARED_EMAIL,
  SET_CONTRIBUTORS_TO_ADD,
  VmInstanceContributorActionType
} from 'store/actions/vm-instance-contributors-actions';
import { UserDTO, VmDTO } from 'types';


type VmInstanceContributorState = {
  selectedVM: VmDTO | undefined,
  isFetching: boolean,
  contributorList: UserDTO[],
  contributorIdsToDelete: string[],
  contributorIdsToAdd: string[],
  sharedEmailsToAdd: string[],
  sharedEmailsToDelete: string[],
  sharedEmails: string[]
};

const initialState: VmInstanceContributorState = {
  selectedVM: undefined,
  isFetching: false,
  contributorList: [],
  contributorIdsToDelete: [],
  contributorIdsToAdd: [],
  sharedEmailsToAdd: [],
  sharedEmailsToDelete: [],
  sharedEmails: [],
};

const vmInstanceContributorReducer = (state = initialState, action: VmInstanceContributorActionType): VmInstanceContributorState => {
  switch (action.type) {
    case OPEN_VM_SHARE_MODAL:
      return {
        ...initialState,
        selectedVM: action.selectedVM,
      };

    case CLOSE_VM_SHARE_MODAL:
      return {
        ...initialState,
      };

    case REQUEST_VM_INSTANCE_CONTRIBUTORS:
      return {
        ...state,
        isFetching: true,
        contributorList: [],
        contributorIdsToDelete: [],
        contributorIdsToAdd: [],
        sharedEmailsToAdd: [],
        sharedEmailsToDelete: [],
      };

    case RECEIVE_VM_INSTANCE_CONTRIBUTORS:
      return {
        ...state,
        isFetching: false,
        contributorList: action.contributorList,
      };

    case DELETE_VM_INSTANCE_CONTRIBUTOR:
      return {
        ...state,
        contributorIdsToDelete: [...state.contributorIdsToDelete, action.id],
      };

    case DELETE_VM_INSTANCE_SHARED_EMAIL:
      return {
        ...state,
        sharedEmailsToDelete: [...state.sharedEmailsToDelete, action.email],
      };

    case SET_CONTRIBUTORS_TO_ADD:
      return {
        ...state,
        contributorIdsToAdd: action.contributorIds || [],
        sharedEmailsToAdd: action.sharedEmails || [],
      };

    default:
      return {
        ...state,
      };
  }
};

export default vmInstanceContributorReducer;