import React, { useEffect } from 'react';

import './project-info.scss';

import { connect, ConnectedProps } from 'react-redux';
import { getProjectInformation, clearProjectInfoAction } from 'store/actions/resource-project-actions';

import Loader from 'components/loader/loader';
import ProjectManagerList from './project-manager-list';
import { formatDateFromISO } from 'services/date-service';
import { RootState } from 'store/reducers';
import { bindActionCreators } from 'redux';
import { useAppDispatch } from 'store';

const mapState = (state: RootState) => ({
  isFetchingProjectInfo: state.resourcesReducer.isFetchingProjectInformation,
  projectInfo: state.resourcesReducer.projectInformation,
});

const mapDispatch = (dispatch) => bindActionCreators(
  {
    clearProjectInfoAction,
  },
  dispatch
);

const connector = connect(mapState, mapDispatch);

type ReduxProps = ConnectedProps<typeof connector>;

type OwnProps = {
  projectId: number
};

type Props = ReduxProps & OwnProps;

const ProjectInfo = ({ projectId, clearProjectInfoAction, isFetchingProjectInfo, projectInfo }: Props) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getProjectInformation(projectId));

    return () => {
      clearProjectInfoAction();
    };
  }, [projectId, clearProjectInfoAction]);

  return (
    <div className='project-info-container'>
      <div className='title'>PROJECT INFORMATION</div>
      {isFetchingProjectInfo && (
        <div className='project-info-loader-container'>
          <Loader />
        </div>
      )}

      {projectInfo && (
        <>
          <div className='project-info'>
            <div className='header'>
              <div className='header-text'>{projectInfo.name}</div>
            </div>
            <div className='content'>
              <div className='section-items'>
                <div className='item'>
                  <div className='informations-label'>Site creation date</div>
                  <div className='value'>{formatDateFromISO(projectInfo.siteCreationDate)}</div>
                </div>

                <div className='item'>
                  <div className='informations-label'>Project creation date</div>
                  <div className='value'>{formatDateFromISO(projectInfo.projectCreationDate)}</div>
                </div>

                <div className='item'>
                  <div className='informations-label'>Active VM</div>
                  <div className='value'>{projectInfo.activeVM}</div>
                </div>

                <div className='item'>
                  <div className='informations-label'>Authorized users</div>
                  <div className='value'>{projectInfo.authorisedUsers}</div>
                </div>

                <div className='item'>
                  <div className='informations-label'>Last opened date</div>
                  <div className='value'>{formatDateFromISO(projectInfo.lastOpenedDate)}</div>
                </div>
              </div>
              <div className='project-managers'>
                <div className='informations-label'>Project managers</div>
              </div>
              {projectInfo.managers && <ProjectManagerList managerList={projectInfo.managers} isFetchingManagerList={isFetchingProjectInfo} />}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default connector(ProjectInfo);
