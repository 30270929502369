import {
  OPEN_VM_EXTRA_INSTALLS_MODAL,
  CLOSE_VM_EXTRA_INSTALLS_MODAL,
  SET_EXTRA_INSTALLS_TO_ADD,
  VmExtraInstallType
} from 'store/actions/vm-extra-installs-actions';
import { VmDTO } from 'types';

type VmExtraInstallState = {
  selectedVM: VmDTO | undefined,
  extraInstallsToAdd: string[],
};

const initialState: VmExtraInstallState = {
  selectedVM: undefined,
  extraInstallsToAdd: [],
};

const vmExtraInstallsReducer = (state = initialState, action: VmExtraInstallType): VmExtraInstallState => {
  
  switch (action.type) {
    case OPEN_VM_EXTRA_INSTALLS_MODAL:
      return {
        ...initialState,
        selectedVM: action.selectedVM,
      };

    case CLOSE_VM_EXTRA_INSTALLS_MODAL:
      return {
        ...initialState,
      };

    case SET_EXTRA_INSTALLS_TO_ADD:
      return {
        ...state,
        extraInstallsToAdd: action.installs || [],
      };

    default:
      return {
        ...state,
      };
  }
};

export default vmExtraInstallsReducer;