import resourcePathService from 'services/resource-path-service';
import {
  CLEAR_PROJECT_INFORMATION,
  ERROR_FETCH_PROJECT_INFORMATION,
  ProjectActionType,
  RECEIVE_ADD_PROJECT,
  RECEIVE_DELETE_PROJECT,
  RECEIVE_PROJECT_INFORMATION,
  REQUEST_PROJECT_INFORMATION
} from 'store/actions/resource-project-actions';
import {
  ERROR_FETCH_SITE_LIST,
  RECEIVE_SITE_LIST,
  REQUEST_SITE_LIST,
  SiteActions
} from 'store/actions/resource-site-actions';
import {
  RECEIVE_ADD_ZONE,
  RECEIVE_DELETE_ZONE,
  RECEIVE_ZONE_FILESHARE_FAILED,
  RECEIVE_ZONE_FILESHARE_READY,
  ZoneActions
} from 'store/actions/resource-zone-actions';
import { ProjectInfoDTO, ResourceAccessNode } from 'types';

export type SitesState = {
  siteList: ResourceAccessNode[],
  isFetching: boolean,
  projectInformation: ProjectInfoDTO | null,
  isFetchingProjectInformation: boolean,
  zoneId2IsFileshareReady: {
    [id: string]: boolean
  },
  zoneId2IsFileshareFailed: {
    [id: string]: boolean
  },
};

const initialState: SitesState = {
  siteList: [],
  isFetching: false,
  projectInformation: null,//Should be moved to its own reducer
  isFetchingProjectInformation: false,//idem
  zoneId2IsFileshareReady: {},
  zoneId2IsFileshareFailed: {},
};

function updateStateWithProject(state: SitesState, project: ResourceAccessNode, functionToApply): SitesState {
  const nodeIds = resourcePathService.splitPath(project.resourcePath).map(id => parseInt(id));
  const siteId = nodeIds[0];
  nodeIds.splice(0, 1);//Remove first elements (site id)
  return {
    ...state,
    siteList: state.siteList.map(site => site.resourceId === siteId ? updateResourceWithProject(site, nodeIds, project, functionToApply) : site
    ),
  };
};

function updateResourceWithProject(resource: ResourceAccessNode, nodeIds: number[], project: ResourceAccessNode, functionToApply): ResourceAccessNode {
  if (nodeIds.length === 1) {
    //We are in the parent of the resource to add
    return functionToApply(resource, project);
  }
  else {
    const nextId = nodeIds[0];
    nodeIds.splice(0, 1);//Remove first element and call recursively
    return {
      ...resource,
      children: resource.children.map(
        child => child.resourceId === nextId ? updateResourceWithProject(child, nodeIds, project, functionToApply) : child
      ),
    };
  }
};

function addProjectToResource(resource: ResourceAccessNode, project: ResourceAccessNode): ResourceAccessNode {
  return { ...resource, children: [...resource.children, project] };
};

function removeProjectFromResource(resource: ResourceAccessNode, project: ResourceAccessNode): ResourceAccessNode {
  return { ...resource, children: resource.children.filter(child => child.resourceId !== project.resourceId) };
};

const resourcesReducer = (state = initialState, action: ProjectActionType | ZoneActions | SiteActions): SitesState => {
  switch (action.type) {
    case REQUEST_SITE_LIST:
      return {
        ...state,
        isFetching: true,
        siteList: [],
      };
    case RECEIVE_SITE_LIST:
      return {
        ...state,
        isFetching: false,
        siteList: action.siteList,
      };
    case ERROR_FETCH_SITE_LIST:
      return {
        ...state,
        isFetching: false,
        siteList: [],
      };

    case RECEIVE_ADD_PROJECT:
      return updateStateWithProject(state, action.project, addProjectToResource);

    case RECEIVE_DELETE_PROJECT:
      return updateStateWithProject(state, action.project, removeProjectFromResource);

    case RECEIVE_ADD_ZONE:
      return {
        ...state,
        siteList: state.siteList.map(site => site.resourcePath === action.siteResourcePath
          ? {
            ...site,
            children: [...site.children, action.zone],
          }
          : site
        ),
      };
    case RECEIVE_DELETE_ZONE:
      return {
        ...state,
        siteList: state.siteList.map(site => site.resourceId !== action.siteId
          ? site
          : {
            ...site,
            children: site.children.filter(zone => zone.resourceId !== action.zoneId),
          }
        ),
      };

    case RECEIVE_ZONE_FILESHARE_FAILED:
      return {
        ...state,
        zoneId2IsFileshareFailed: { ...state.zoneId2IsFileshareFailed, [action.zoneId]: true },
      };

    case RECEIVE_ZONE_FILESHARE_READY:
      return {
        ...state,
        zoneId2IsFileshareReady: { ...state.zoneId2IsFileshareReady, [action.zoneId]: true },
      };

    //TODO Project Info related actions => create info-project-reducer
    case REQUEST_PROJECT_INFORMATION:
      return {
        ...state,
        isFetchingProjectInformation: true,
      };

    case RECEIVE_PROJECT_INFORMATION:
      return {
        ...state,
        isFetchingProjectInformation: false,
        projectInformation: action.projectInformation,
      };

    case ERROR_FETCH_PROJECT_INFORMATION:
      return {
        ...state,
        isFetchingProjectInformation: false,
        projectInformation: null,
      };

    case CLEAR_PROJECT_INFORMATION:
      return {
        ...state,
        isFetchingProjectInformation: false,
        projectInformation: null,
      };

    default:
      return state;
  }
};

export default resourcesReducer;