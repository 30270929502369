export const formatKB = (kb: number): string => {
  if (kb < 1024) return kb + ' KB';
  else if (kb < 1048576) return (kb / 1024).toFixed(2) + ' MB';
  else return (kb / 1048576).toFixed(2) + ' GB';
};

export const formatBytes = (bytes: number): string => {
  if (bytes < 1024) return bytes + ' bytes';
  else if (bytes < 1048576) return (bytes / 1024).toFixed(2) + ' KB';
  else if (bytes < 1073741824) return (bytes / 1048576).toFixed(2) + ' MB';
  else return (bytes / 1073741824).toFixed(2) + ' GB';
};

export const formatGiBtoTiB = (gib: number): string => {
  return (gib / 1024).toFixed(2) + ' TiB';
};