import React, { ReactElement } from 'react';
import { DialogContent, Grid, Button, Dialog } from '@mui/material';

const FileUploadRedirectionWarningModal = (): ReactElement => {
  const ok = () => {
    window.open(window.location.href, '_blank');
  };

  return (
    <Dialog open fullWidth maxWidth='sm'>
      <DialogContent>
        <p>Your session is expired but you have a file upload in progress.</p>
        <p>Do not close this tab to continue uploading the file. By clicking on "Ok" you will be redirected to a new tab
          to reconnect.</p>
        <Grid item xs>
          <Grid container alignItems='stretch' direction='row' justifyContent='flex-end'>
            <Grid item>
              <Button onClick={() => ok()} variant='contained' color='primary'>
                Ok
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default FileUploadRedirectionWarningModal;
