import vmInstanceService from 'services/vm-instance-service';

import { POLLING_INTERVAL } from 'constants/config';
import { ERROR, PENDING, STOP_REQUEST } from 'constants/vm-status';
import resourcePathService from 'services/resource-path-service';
import toastService from 'services/toast-service';
import { AppThunk } from 'store/reducers';
import { DcvResolution, PaginatedSessionsDTO, SessionPropertiesDTO, VmDTO } from 'types';

/*
 * Fetch VM instances list.
 */
export const INIT_VM_INSTANCE_LIST = 'INIT_VM_INSTANCE_LIST';
export const REQUEST_VM_INSTANCE_LIST = 'REQUEST_VM_INSTANCE_LIST';
export const RECEIVE_VM_INSTANCE_LIST = 'RECEIVE_VM_INSTANCE_LIST';
export const ERROR_RECEIVING_VM_INSTANCE_LIST = 'ERROR_RECEIVING_VM_INSTANCE_LIST';

export type InitVMInstanceListAction = {
  type: typeof INIT_VM_INSTANCE_LIST
};

export type RequestVMInstanceListAction = {
  type: typeof REQUEST_VM_INSTANCE_LIST,
  pageNumber: number,
  pageSize: number,
};

export type ReceiveVMInstanceListAction = {
  type: typeof RECEIVE_VM_INSTANCE_LIST,
  paginatedSessionsDTO: PaginatedSessionsDTO
};

export type ErrorReceivingVMInstanceListAction = {
  type: typeof ERROR_RECEIVING_VM_INSTANCE_LIST
};

export const initVMInstanceListAction = (): InitVMInstanceListAction => ({ type: INIT_VM_INSTANCE_LIST });
export const requestVMInstanceListAction = (pageNumber: number, pageSize: number): RequestVMInstanceListAction => ({ type: REQUEST_VM_INSTANCE_LIST, pageNumber, pageSize });
export const receiveVMInstanceListAction = (paginatedSessionsDTO: PaginatedSessionsDTO): ReceiveVMInstanceListAction => ({ type: RECEIVE_VM_INSTANCE_LIST, paginatedSessionsDTO });
export const errorReceivingVMInstanceListAction = (): ErrorReceivingVMInstanceListAction => ({ type: ERROR_RECEIVING_VM_INSTANCE_LIST });

const getVmInstanceListWithPollingAction = (): AppThunk<Promise<void | NodeJS.Timeout>> => (dispatch, getState) => {
  const { currentPage, pageSize } = getState().vmInstancesReducer;

  return vmInstanceService
    .getActiveVMInstances(currentPage, pageSize)
    .then(paginatedSessionsDTO => {
      dispatch(receiveVMInstanceListAction(paginatedSessionsDTO));
      setTimeout(() => dispatch(getVmInstanceListWithPollingAction()), POLLING_INTERVAL);
    })
    .catch((err) => {
      dispatch(errorReceivingVMInstanceListAction());
      setTimeout(() => dispatch(getVmInstanceListWithPollingAction()), POLLING_INTERVAL);
      const message = err?.response?.data?.message ? err.response.data.message : 'Error retreiving instances list';
      toastService.error(message);
    });
};

export const startPollingGetVmInstanceListAction = (pageNumber: number, pageSize: number): AppThunk<Promise<void>> => async (dispatch) => {
  dispatch(requestVMInstanceListAction(pageNumber, pageSize));//set isFetching = true, pageNumber and pageSize in redux state
  dispatch(getVmInstanceListWithPollingAction());
};

export const updateVmInstanceListPollingAction = (pageNumber: number, pageSize: number): AppThunk<Promise<void>> => async dispatch => {
  dispatch(requestVMInstanceListAction(pageNumber, pageSize));//set isFetching = true, pageNumber and pageSize in redux state
  // List VMs to avoid having to wait for POLLING_INTERVAL
  return vmInstanceService
    .getActiveVMInstances(pageNumber, pageSize)
    .then(paginatedSessionsDTO => {
      dispatch(receiveVMInstanceListAction(paginatedSessionsDTO));
    })
    .catch((err) => {
      dispatch(errorReceivingVMInstanceListAction());
      const message = err?.response?.data?.message ? err.response.data.message : 'Error retreiving instances list';
      toastService.error(message);
    });
};

/*
 * Create VM instance.
 */
export const RECEIVE_CREATE_VM_INSTANCE = 'RECEIVE_CREATE_VM_INSTANCE';
export const ERROR_RECEIVING_CREATE_VM_INSTANCE = 'ERROR_RECEIVING_CREATE_VM_INSTANCE';

export type ReceiveCreateVMInstanceAction = {
  type: typeof RECEIVE_CREATE_VM_INSTANCE,
  instanceName: string,
  status: string,
  sessionId: string,
  resourcePath: string,
};

export const receiveCreateVMInstanceAction =
  (
    instanceName: string,
    status: string,
    sessionId: string,
    resourcePath: string
  ): ReceiveCreateVMInstanceAction => ({ type: RECEIVE_CREATE_VM_INSTANCE, instanceName, status, sessionId, resourcePath });

export type ErrorReceivingCreateVMInstanceAction = {
  type: typeof ERROR_RECEIVING_CREATE_VM_INSTANCE
};

export const errorReceivingCreateVMInstanceAction = (): ErrorReceivingCreateVMInstanceAction => ({ type: ERROR_RECEIVING_CREATE_VM_INSTANCE });

export const createVMInstance = (initialVmInstanceData: SessionPropertiesDTO): AppThunk<Promise<void>> => async dispatch => vmInstanceService.createVM(initialVmInstanceData).then(
  vmInstanceData => {
    const instanceName = vmInstanceData.name ? vmInstanceData.name : '';
    const instanceId = vmInstanceData.sessionId ? vmInstanceData.sessionId : '';
    dispatch(receiveCreateVMInstanceAction(instanceName, PENDING, instanceId, initialVmInstanceData.resourcePath));
  },
  e => {
    const message = e && e.response && e.response.data ? e.response.data.message : 'Error creating instance';
    toastService.error(message);
    dispatch(errorReceivingCreateVMInstanceAction());
  }
);

/*
 * Initialize VM instance.
 */
export const INITIALIZE_VM_INSTANCE = 'INITIALIZE_VM_INSTANCE';

type SessionPropertiesDTOWithOwner = {
  ownerId: string,
  status?: string,
} & SessionPropertiesDTO;

export type InitializeVMInstanceAction = {
  type: typeof INITIALIZE_VM_INSTANCE,
  initialVmInstanceData: VmDTO
};

export const initializeVMInstanceAction = (initialVmInstanceData: SessionPropertiesDTOWithOwner): InitializeVMInstanceAction => ({
  type: INITIALIZE_VM_INSTANCE, initialVmInstanceData: {
    siteId: resourcePathService.getSiteIdFromResourcePath(initialVmInstanceData.resourcePath),
    zoneId: resourcePathService.getZoneIdFromResourcePath(initialVmInstanceData.resourcePath),
    projectId: resourcePathService.getProjectIdFromResourcePath(initialVmInstanceData.resourcePath),
    machineTemplateId: initialVmInstanceData.machineTemplateId,
    resolution: initialVmInstanceData.resolution,
    ownerId: initialVmInstanceData.ownerId,
    status: PENDING,
    // I hate having to set default values everywhere but TS won't let me have everything null/undefined
    name: '',
    sessionId: '',
    sudoer: '',
    smgInstallsRegexp: '',
    smgInstalls: [],
    vmBaseUrl: '',
    creationDate: '',
    costPerHour: 0,
    creationDateInMillis: 0,
    shutdownDateInMillis: 0,
    zoneName: '',
    siteName: '',
    siteLabel: '',
    projectName: '',
    ownerEmail: '',
    ownerFirstName: '',
    ownerLastName: '',
    sharedEmails: '',
    cpuUsage: 0,
    contributorIds: '',
    temporaryDatalocal: false,
    homeDirFull: false,
    lastEvent: {
      id: '',
      label: '',
      lastModifiedDate: '',
      creationDate: '',
    },
  },
});

export const initializeVMInstance = (initialVmInstanceData: SessionPropertiesDTO): AppThunk<Promise<void>> => async (dispatch, getState) => {
  dispatch(initializeVMInstanceAction({ ...initialVmInstanceData, ownerId: getState().loggedUserReducer.id }));
  dispatch(createVMInstance(initialVmInstanceData));
};

/*
 * Update VM instance resolution.
 */
export const ERROR_UPDATING_VM_RESOLUTION = 'ERROR_UPDATING_VM_RESOLUTION';

export type ErrorUpdatingVMResolution = {
  type: typeof ERROR_UPDATING_VM_RESOLUTION,
  vmInstanceId: string
};

export const errorUpdatingVMResolution = (vmInstanceId: string): ErrorUpdatingVMResolution => ({ type: ERROR_UPDATING_VM_RESOLUTION, vmInstanceId });
export const updateVMResolution = (sessionId: string, resolution: DcvResolution): AppThunk<Promise<void>> => async dispatch => {
  return vmInstanceService.updateVMResolution(sessionId, resolution).catch(() => {
    dispatch(errorUpdatingVMResolution(sessionId));
  });
};

/*
 * Delete VM instance.
 */
export const REQUEST_DELETE_VM_INSTANCE = 'REQUEST_DELETE_VM_INSTANCE';
export const ERROR_RECEIVING_DELETE_VM_INSTANCE = 'ERROR_RECEIVING_DELETE_VM_INSTANCE';

export type RequestDeleteVMInstanceAction = {
  type: typeof REQUEST_DELETE_VM_INSTANCE,
  instanceName: string,
  status: string
};

export type ErrorReceivingDeleteVMInstanceAction = {
  type: typeof ERROR_RECEIVING_DELETE_VM_INSTANCE,
  instanceName: string,
  status: string
};

export const requestDeleteVMInstanceAction =
  (instanceName: string, status: string): RequestDeleteVMInstanceAction => ({ type: REQUEST_DELETE_VM_INSTANCE, instanceName, status });
export const errorReceivingDeleteVMInstanceAction =
  (instanceName: string, status: string): ErrorReceivingDeleteVMInstanceAction => ({ type: ERROR_RECEIVING_DELETE_VM_INSTANCE, instanceName, status });

export const deleteVMInstance = (vmInstanceData: VmDTO): AppThunk<Promise<void>> => async dispatch => {
  const sessionName = vmInstanceData.name ? vmInstanceData.name : '';
  const sessionId = vmInstanceData.sessionId ? vmInstanceData.sessionId : '';
  dispatch(requestDeleteVMInstanceAction(sessionName, STOP_REQUEST));
  vmInstanceService.cancelListRequests();
  return vmInstanceService.deleteVM(sessionId).catch(() => {
    dispatch(errorReceivingDeleteVMInstanceAction(sessionName, ERROR));
  });
};

/*
 * Delete VM instance of entire site
 */
export const REQUEST_DELETE_VM_OF_SITE = 'REQUEST_DELETE_VM_OF_SITE';
export const ERROR_RECEIVING_DELETE_VM_OF_SITE = 'ERROR_RECEIVING_DELETE_VM_OF_SITE';

export type RequestDeleteVMOfSiteAction = {
  type: typeof REQUEST_DELETE_VM_OF_SITE,
  siteId: number
};

export type ErrorReceivingDeleteVMOfSiteAction = {
  type: typeof ERROR_RECEIVING_DELETE_VM_OF_SITE,
  siteId: number,
  status: string
};

export const requestDeleteVMOfSiteAction = (siteId: number): RequestDeleteVMOfSiteAction => ({ type: REQUEST_DELETE_VM_OF_SITE, siteId });
export const errorReceivingDeleteVMOfSiteAction = (siteId: number, status: string): ErrorReceivingDeleteVMOfSiteAction => ({ type: ERROR_RECEIVING_DELETE_VM_OF_SITE, siteId, status });

export const deleteVMOfSite = (siteId: number): AppThunk<Promise<void>> => async dispatch => {
  dispatch(requestDeleteVMOfSiteAction(siteId));
  return vmInstanceService.deleteVMSite(siteId).catch(() => {
    dispatch(errorReceivingDeleteVMOfSiteAction(siteId, ERROR));
  });
};

/*
 * Update VM instance contributors
 */
export const RECEIVE_UPDATE_VM_INSTANCE_CONTRIBUTORS = 'RECEIVE_UPDATE_VM_INSTANCE_CONTRIBUTORS';

export type UpdateVMInstanceContributorsAction = {
  type: typeof RECEIVE_UPDATE_VM_INSTANCE_CONTRIBUTORS,
  instanceName: string,
  contributorIds: string
};

export const updateVMInstanceContributorsAction = (
  instanceName: string,
  contributorIds: string
): UpdateVMInstanceContributorsAction => ({ type: RECEIVE_UPDATE_VM_INSTANCE_CONTRIBUTORS, instanceName, contributorIds });

export type VmInstanceAction = InitVMInstanceListAction
  | RequestVMInstanceListAction
  | ReceiveVMInstanceListAction
  | ErrorReceivingVMInstanceListAction
  | ReceiveCreateVMInstanceAction
  | InitializeVMInstanceAction
  | ErrorUpdatingVMResolution
  | RequestDeleteVMInstanceAction
  | ErrorReceivingDeleteVMInstanceAction
  | RequestDeleteVMOfSiteAction
  | ErrorReceivingDeleteVMOfSiteAction
  | UpdateVMInstanceContributorsAction
  | ErrorReceivingCreateVMInstanceAction;
