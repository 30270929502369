import { useEffect, useState } from 'react';

import DeleteUserIcon from '@mui/icons-material/Delete';
import { IconButton } from '@mui/material';
import Loader from 'components/loader/loader';
import userService from 'services/user-service';

import styles from './user-delete-button.module.scss';


type Props = {
  aclId: number,
  deleteCallBack: () => void
};

const UserDeleteButton = ({ aclId, deleteCallBack }: Props) => {
  const [inProgress, setInProgress] = useState(false);

  const afterDelete = () => {
    if (deleteCallBack) {
      // Do not set in progress to false, as the callback should update the status
      // If no callback, stop the progress.
      deleteCallBack();
    } else {
      setInProgress(false);
    }
  };

  const deleteActionClick = () => {
    setInProgress(true);
    userService.deleteUserAuthority(aclId).then(afterDelete);
  };

  //Reset the Loader if upadted (line moved)
  useEffect(() => {
    return () => {
      setInProgress(false);
    };
  }, [aclId]);

  return (
    !inProgress ? (
            <IconButton onClick={deleteActionClick}>
                <DeleteUserIcon className={styles.delete_button__action_icon} />
            </IconButton>
    ) : <Loader className={styles.delete_button__delete_loader_icon} />
  );
};

export default UserDeleteButton;
