import * as jwtDecode from 'jwt-decode';
import userService from 'services/user-service';
import { AppThunk } from 'store/reducers';

export const REDIRECT_TO_LOGIN = 'REDIRECT_TO_LOGIN';
export const SET_LOGGED_USER = 'SET_LOGGED_USER';
export const AGREE_DISCLAIMER = 'AGREE_DISCLAIMER';
export const RECEIVE_DISCLAIMER_AGREEMENT = 'RECEIVE_DISCLAIMER_AGREEMENT';

export const REQUEST_USER_AVATAR = 'REQUEST_USER_AVATAR';
export const RECEIVE_USER_AVATAR = 'RECEIVE_USER_AVATAR';
export const UPDATE_USER_AVATAR = 'UPDATE_USER_AVATAR';

export type RedirectToLoginAction = {
  type: typeof REDIRECT_TO_LOGIN
};

export type InitLoggedUser = {
  type: typeof SET_LOGGED_USER, 
  id: string, 
  lastname: string, 
  firstname: string, 
  email: string,
};

export type ReceiveDisclaimerAgreement = {
  type: typeof RECEIVE_DISCLAIMER_AGREEMENT,
  isDisclaimerAgreed: boolean;
};

export type AgreeDisclaimer = {
  type: typeof AGREE_DISCLAIMER
};

export type RequestUserAvatarAction = {
  type: typeof REQUEST_USER_AVATAR
};

export type ReceiveUserAvatarAction = {
  type: typeof RECEIVE_USER_AVATAR,
  userAvatar: Blob 
};

export type UpdateUserAvatarAction = {
  type: typeof UPDATE_USER_AVATAR,
  userAvatar: Blob 
};

export const redirectToLoginAction = (): RedirectToLoginAction => ({ type: REDIRECT_TO_LOGIN });

export const initLoggedUser = (token: string): InitLoggedUser => {
  if ( process.env.NODE_ENV === 'test' ) {
    return {
      type: SET_LOGGED_USER,
      id: 'J1234567',
      lastname: 'test',
      firstname: 'user',
      email: 'user.test@totalenergies.com',
    };
  }
  if (token) {
    const user = jwtDecode(token);
    const nameSplit: string[] = user.name.split(' ');
    return {
      type: SET_LOGGED_USER,
      id: user['user/IGG'],
      lastname: nameSplit[1],
      firstname: nameSplit[0],
      email: user.preferred_username,
    };
  }
  throw new Error('Token not defined');
};

export const receiveDisclaimerAgreementAction = (isDisclaimerAgreed: boolean): ReceiveDisclaimerAgreement => ({ type: RECEIVE_DISCLAIMER_AGREEMENT, isDisclaimerAgreed });

export const getDisclaimerAgreement = (): AppThunk<Promise<ReceiveDisclaimerAgreement>> => async dispatch => {
  return userService.getUserDisclaimerAgreement().then(disclaimerAgreement => dispatch(receiveDisclaimerAgreementAction(disclaimerAgreement)));
};

export const agreeDisclaimer = (): AgreeDisclaimer => {
  userService.setUserDisclaimerAgreement(true);
  return { type: AGREE_DISCLAIMER };
};

export const requestUserAvatarAction = (): RequestUserAvatarAction => ({ type: REQUEST_USER_AVATAR });
export const receiveUserAvatarAction = (userAvatar: Blob): ReceiveUserAvatarAction => ({ type: RECEIVE_USER_AVATAR, userAvatar });
export const updateUserAvatarAction = (userAvatar: Blob): UpdateUserAvatarAction => ({ type: UPDATE_USER_AVATAR, userAvatar });

export const getUserAvatar = (): AppThunk<Promise<ReceiveUserAvatarAction>> => async dispatch => {
  dispatch(requestUserAvatarAction());
  return userService.getUserAvatar().then(userAvatar => dispatch(receiveUserAvatarAction(userAvatar)));
};

export const uploadUserAvatarAction = (userAvatar: Blob): AppThunk<Promise<ReceiveUserAvatarAction>> => async dispatch => {
  const formData = new FormData();
  formData.append('file', userAvatar);

  dispatch(updateUserAvatarAction(userAvatar));

  return userService.uploadUserAvatar(formData).then(() => dispatch(getUserAvatar()));
};

export type UseActionType = 
  | RedirectToLoginAction
  | InitLoggedUser
  | AgreeDisclaimer
  | RequestUserAvatarAction
  | ReceiveUserAvatarAction
  | UpdateUserAvatarAction
  | ReceiveDisclaimerAgreement;