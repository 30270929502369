import React, { useState } from 'react';

import { Tooltip, IconButton } from '@mui/material';
import DeleteUserIcon from '@mui/icons-material/Delete';
import Loader from 'components/loader/loader';

import styles from './notification-delete-button.module.scss';

import notificationService from 'services/notification-service';

type Props = {
  notificationId?: number,
  deleteAllUserNotifications?: boolean,
  deleteCallBack: () => void
};

const NotificationDeleteButton = ({ notificationId, deleteAllUserNotifications, deleteCallBack }: Props) => {
  const [inProgress, setInProgress] = useState(false);
  const tooltipTitle = deleteAllUserNotifications ? 'Delete all notifications' : 'Delete notification';
  const testId = deleteAllUserNotifications ? 'delete-button-0' : `delete-button-${notificationId}`;

  const afterDelete = () => {
    if (deleteCallBack) {
      // Do not set in progress to false, as the callback should update the status
      // If no callback, stop the progress.
      deleteCallBack();
    }
  };

  const deleteActionClick = () => {
    setInProgress(true);
    if (deleteAllUserNotifications) {
      notificationService.deleteAllUserNotifications().then(afterDelete).finally(() => setInProgress(false));
    } else if (notificationId){
      notificationService.deleteNotification(notificationId).then(afterDelete).finally(() => setInProgress(false));
    }
  };

  return (
    !inProgress ? (
      <IconButton data-testid={testId} className={styles.manage_notification__toolbarIcon} onClick={deleteActionClick}>
        <Tooltip title={tooltipTitle} placement='bottom'>
          <DeleteUserIcon/>
        </Tooltip>
      </IconButton>
    ) : <Loader className={styles.delete_button__delete_loader_icon} />
  );
};

export default NotificationDeleteButton;
