import AccountBox from '@mui/icons-material/AccountBox';
import UserIcon from '@mui/icons-material/AccountCircle';
import ArrowDownIcon from '@mui/icons-material/ArrowDropDown';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import {AppBar, Button, ClickAwayListener, Grid, Grow, MenuItem, MenuList, Paper, Popper, Typography} from '@mui/material';
import classnames from 'classnames';
import ContactSupport from 'components/contact-support/contact-support';
import HelpPage from 'components/help/help-page';
import Notification from 'components/notification/notification';
import {useRef, useState} from 'react';
import {connect, ConnectedProps} from 'react-redux';
import {Link} from 'react-router-dom';
import {msalInstance} from 'services/authentification-service';
import {RootState} from 'store/reducers';
import styles from './header-styles.module.scss';

const mapState = (state: RootState) => ({
  loggedUser: {
    firstname: state.loggedUserReducer.firstname,
    lastname: state.loggedUserReducer.lastname,
    avatar: state.loggedUserReducer.userAvatar,
    id: state.loggedUserReducer.id,
  },
  isFetchingContributors: state.vmInstanceContributorsReducer.isFetching,
  totalCostMonth: state.userBillingReducer.totalCostMonth,
  currencyCode: state.userBillingReducer.currencyCode,
  vmInstanceList: state.vmInstancesReducer.sessions,
});

const connector = connect(mapState);

type ReduxProps = ConnectedProps<typeof connector>;

type Props = ReduxProps;

const Header = ({loggedUser, vmInstanceList, totalCostMonth, currencyCode}: Props) => {
  const [actionMenuOpen, setActionMenuOpen] = useState(false);
  const buttonRef = useRef(null);

  const handleActionsClose = () => {
    setActionMenuOpen(false);
  };

  const handleActionsButtonToggle = () => {
    setActionMenuOpen(!actionMenuOpen);
  };

  const logout = () => {
    handleActionsClose();
    msalInstance.logoutRedirect();
  };

  const getVmCost = vm => {
    if (vm && vm.costPerHour) {
      return vm.costPerHour;
    }
    return 0;
  };

  const getCost = (vmlist, userid) => {
    const ownlist = vmlist.filter(vm => vm.ownerId === userid);

    return ownlist.reduce((acc, vm) => acc + getVmCost(vm), 0);
  };

  const getCostMonth = (cost) => {
    if (cost) return cost;
    return 0;
  };

  const getCurrencyCode = (code) => {
    if (code) return code;
    return 'USD';
  };

  return (
      <div>
        <AppBar className={styles.appBar}>
          <Grid container className={styles.container} direction='row' justifyContent='flex-end' alignItems='center' spacing={1}>
            <Grid item/>
            <ContactSupport/>
            <HelpPage/>
            <Notification/>
            <Button
                className={styles.headerMenuButton}
                ref={buttonRef}
                onClick={handleActionsButtonToggle}>
              {loggedUser.avatar ? <img src={`data:image/jpeg;base64,${loggedUser.avatar}`} className={styles.filledAvatar} alt=' '/> : <UserIcon className={styles.emptyAvatar}/>}

              {loggedUser?.id &&
                  <div>
                      <span className={styles.textWelcome}>Welcome,{loggedUser.firstname ? ` ${loggedUser.firstname} ${loggedUser.lastname}` : ''}</span>
                      <span className={styles.igg}>{loggedUser.id}</span>
                      <span className={styles.textCost}>
                  {getCost(vmInstanceList, loggedUser.id).toFixed(2)} {getCurrencyCode(currencyCode)}/h - {getCostMonth(totalCostMonth).toFixed(2)} {getCurrencyCode(currencyCode)}
                </span>
                  </div>
              }
              {!loggedUser?.id &&
                  <div>
                  <span style={{display: 'inline-flex', alignItems: 'center'}}>
                  <br/>
                  <ReportProblemIcon style={{color: 'orange', paddingRight: '6px', fontSize: '300%'}}/>
                  <div>
                    <span className={styles.textWelcome}>Admin account not managed</span>
                    <span className={styles.textWelcome}>Please connect with your personal account</span>
                  </div>
                </span>
                  </div>
              }
              <ArrowDownIcon sx={{'color': 'grey'}}/>
            </Button>

            <Popper open={actionMenuOpen} anchorEl={buttonRef.current} transition disablePortal>
              {({TransitionProps, placement}) => (
                  <Grow {...TransitionProps} style={{transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'}}>
                    <Paper>
                      <ClickAwayListener onClickAway={handleActionsClose}>
                        <MenuList>
                          <Link to='/user-profile' className={styles.profileLink}>
                            <MenuItem onClick={handleActionsClose}>
                              <AccountBox/>
                              <span className={styles.menuText}> My Profile</span>
                            </MenuItem>
                          </Link>
                          <MenuItem onClick={logout}>
                            <ExitToAppIcon/>
                            <span className={styles.menuText}>Logout </span>
                          </MenuItem>
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
              )}
            </Popper>

            <Grid item hidden>
              <Typography className={classnames(styles.text, styles.link)} component='a' href='#'>
                Settings
              </Typography>
            </Grid>
          </Grid>
          <header/>
        </AppBar>
      </div>
  );
};

export default connector(Header);
