import React, { ReactElement } from 'react';

import './file-upload-container.scss';
import FileUploadComponent from './file-upload-component';

import { UploadDTO } from 'types';

type Props = {
  fileUploadList: UploadDTO[]
};

const FileUploadContainer = ({ fileUploadList }: Props): ReactElement => {

  return (
    <div className='file-upload-container'>
      {fileUploadList.map(fileUploadData => (
        <FileUploadComponent key={fileUploadData.uploadId} fileUploadData={fileUploadData}/>
      ))}
    </div>
  );
};

export default FileUploadContainer;
