import React from 'react';

import { Tooltip } from '@mui/material';
import PropTypes from 'prop-types';

const TextLimited = ({ text, limit, className = '' }) => (
  <>
    {text && text.length > limit ? (
      <Tooltip title={text}>
        <span className={className}>{`${text.substring(0, limit)}...`}</span>
      </Tooltip>
    ) : (
      <span className={className}>{ text }</span>
    )}
  </>
);

TextLimited.propTypes = {
  text: PropTypes.string,
  limit: PropTypes.number.isRequired,
  className: PropTypes.string,
};

export default TextLimited;
