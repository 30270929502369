import React, { ReactNode, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import Loader from 'components/loader/loader';

import styles from './confirm-dialog.module.scss';

type Props = {
  children: ReactNode,
  title: string,
  text: string | JSX.Element,
  isMenuItem: boolean,
  onClose?: () => void;
  onConfirm: () => Promise<void>,
};

const ConfirmDialog = ({
  title, text, isMenuItem, onConfirm, onClose, children,
}: Props) => {
  const [open, setOpen] = useState(false);
  const [confirmRequestInProgress, setConfirmRequestInProgress] = useState(false);

  const handleClickOpen = (event) => {
    setOpen(true);
    event.stopPropagation();
  };

  const handleClose = (event) => {
    if (onClose) {
      onClose();
    }
    setOpen(false);
    event.stopPropagation();
  };

  const confirm = (event) => {
    setConfirmRequestInProgress(true);
    onConfirm()
      .then(() => {
        handleClose(event);
        setConfirmRequestInProgress(false);
      })
      .catch(() => setConfirmRequestInProgress(false));
  };

  return (
    <div onClick={handleClickOpen} className={isMenuItem ? 'confirm-dialog-container' + ' ' + styles.menu_item_content : 'confirm-dialog-container'}>
      <div>{children}</div>

      <Dialog open={open} onClose={handleClose} aria-labelledby='alert-dialog-title' aria-describedby='alert-dialog-description'>
        <DialogTitle id='alert-dialog-title'>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>{text}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color='secondary' autoFocus disabled={confirmRequestInProgress}>
            No
          </Button>
          <Button onClick={confirm} color='primary' disabled={confirmRequestInProgress}>
            {confirmRequestInProgress ? <Loader className='confirm-dialog-loader-icon' /> : <span>Yes</span>}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ConfirmDialog;
