import userService from 'services/user-service';
import { AppThunk } from 'store/reducers';
import { CreateAccessDTO } from 'types';

export const REQUEST_ADD_USER = 'REQUEST_ADD_USER';
export const RECEIVE_ADD_USER = 'RECEIVE_ADD_USER';
export const ERROR_ADD_USER = 'ERROR_ADD_USER';

export type RequestAddUserAction = { 
  type: typeof REQUEST_ADD_USER 
};
export type ReceiveAddUserAction = { 
  type: typeof RECEIVE_ADD_USER 
};
export type ErrorAddUserAction = { 
  type: typeof ERROR_ADD_USER 
};

export const requestAddUserAction = (): RequestAddUserAction => ({ type: REQUEST_ADD_USER });
export const receiveAddUserAction = (): ReceiveAddUserAction => ({ type: RECEIVE_ADD_USER });
export const errorAddUserAction = (): ErrorAddUserAction => ({ type: ERROR_ADD_USER });

export const addUser = (createAccessControlRequestBody: CreateAccessDTO): AppThunk<Promise<void>> => async dispatch => {
  dispatch(requestAddUserAction());

  return userService
    .createAccessControl(createAccessControlRequestBody)
    .then(() => {
      dispatch(receiveAddUserAction());
    })
    .catch(() => {
      dispatch(errorAddUserAction());
    });
};

export type MachineRightsActionType = RequestAddUserAction
| ReceiveAddUserAction
| ErrorAddUserAction;
