import { Autocomplete, TextField } from '@mui/material';
import { ConnectedProps, connect } from 'react-redux';
import { RootState } from 'store/reducers';
import { ResourceAccessNode } from 'types';

const mapState = (state: RootState) => ({
  siteList: state.resourcesReducer.siteList,
});

const connector = connect(mapState);

type ReduxProps = ConnectedProps<typeof connector>;
type OwnProps = {
  filterList?: any[];
  index?: number;
  column?: any;
  value?: string;
  onChange: any;
};
type Props = ReduxProps & OwnProps;

const flattenSiteList = (siteList: ResourceAccessNode[]): string[] => {
  const resourcePaths: string[] = [];

  const traverse = (node: ResourceAccessNode, path = '') => {
    const currentPath = node.resourceType === 'SITE' ? `${path}/${node.label}` : `${path}/${node.name}`;
    resourcePaths.push(currentPath);

    if (node.children && node.children.length > 0) {
      node.children.forEach(child => traverse(child, currentPath));
    }
  };

  siteList.forEach(site => traverse(site));
  return resourcePaths;
};

const ResourcePathSelector = ({
  filterList,
  index,
  column,
  value,
  onChange,
  siteList,
}: Props) => {
  const optionValues = flattenSiteList(siteList);
  const initialValue = (filterList && index) ? filterList[index][0] || null : value;
  return (
    <Autocomplete
      options={optionValues}
      getOptionLabel={(option) => option}
      key={initialValue}
      value={initialValue}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Search Resource Path"
          variant='standard'
        />
      )}
      onChange={(event, value) => {
        if (filterList && index) { //when used in ManageRights filter
          //eslint-disable-next-line
          filterList[index][0] = value;
          onChange(filterList[index], index, column);
        }
        else { //when used in addUser
          onChange(value);
        }
      }}
    />
  );
};

export default connector(ResourcePathSelector);
