export interface MailTemplate {
  mailto: string[];
  ccs: string[];
  subject: string;
  body: string;
}

//double breaks line
const BREAK_LINE_IN_MAIL_BODY = '%0D%0A%0D%0A';

export default {
  createHrefTemplateMail(SismageTemplateMail: MailTemplate) {
    const href = `
        mailto:${SismageTemplateMail.mailto.join('; ')}?
        cc=${SismageTemplateMail.ccs.join('; ')}
        &subject=${SismageTemplateMail.subject}
        &body=${SismageTemplateMail.body}
    `;
    return href;
  },

  createMailBody(mailBody) {
    return `Hello,${BREAK_LINE_IN_MAIL_BODY}${Object.values(mailBody).join(BREAK_LINE_IN_MAIL_BODY)}${BREAK_LINE_IN_MAIL_BODY}Best regards.`;
  },

};
