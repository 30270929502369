import React, { ReactElement, useEffect, useState } from 'react';
import FileIcon from '@mui/icons-material/InsertDriveFile';
import IconCompleted from '@mui/icons-material/CheckCircle';
import { LinearProgress, IconButton } from '@mui/material';
import toastService from 'services/toast-service';
import { saveAs } from 'file-saver';
import instance from 'services/axios';
import './downloader.scss';

export type DownloadFile = {
  downloadId: string,
  fileUrl: string,
  filename: string, 
};

type Props = {
  files: DownloadFile[],
  remove: (id: string) => void,
};

const Downloader = ({ files = [], remove }: Props): ReactElement => {
  return (
    <div className='file-download-container'>
    <div className = 'file-download-component'>
      <div className = 'header'>
      download file
        {/* <div className = "close-container">
          <IconButton className = "icon-close">
          <IconClose />
          </IconButton>
        </div> */}
      </div>

      <div className = 'body'>
      {files.map((file) => (
            <DownloadItem
              key={file.downloadId}
              removeFile={() => remove(file.downloadId)}
              {...file}/>
      ))}
      </div>
    </div>
    </div>
  );
};

const DownloadItem = ({ fileUrl, filename, removeFile }) => {
  const [downloadInfo, setDownloadInfo] = useState({
    progress: 0,
    completed: false,
    total: 0,
    loaded: 0,
  });

  useEffect(() => {
    const options = {
      onDownloadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;

        setDownloadInfo({
          progress: Math.floor((loaded * 100) / total),
          loaded,
          total,
          completed: false,
        });
      },
    };

    instance.get(fileUrl, {
      responseType: 'blob',
      ...options,
    }).then((response) => {
      // console.log(response);
      const data = new Blob([response.data], {
        type: response.headers['content-type'],
      });

      saveAs(data, filename);

      setDownloadInfo((info) => ({
        ...info,
        completed: true,
      }));

      setTimeout(() => {
        removeFile();
      }, 4000);
    }).catch(() => {
      toastService.error('File error');
      removeFile();
      return Promise.reject();
    });
  }, []);

  const formatBytes = (bytes) => `${(bytes / (1024 * 1024)).toFixed(2)} MB`;

  return (
      <div>
        <div className = 'file-name-container'>
          <FileIcon className = 'file-icon' />
          <div className = 'file-name' > {filename} </div>
          <div>&nbsp;</div>
          <div className ='file-size'>
          {
              formatBytes(downloadInfo.total)
          }
          </div>
          <div className = 'action-icon'>
          {/* {
          !downloadInfo.completed ? (
              <IconButton onClick = {removeFile}>
              <IconCancel className = "icon-cancel" />
              </IconButton>
          ) :
          null
          } */}
          {
              downloadInfo.completed ? (
                  <IconButton disabled>
                  <IconCompleted className = 'icon-completed' />
                  </IconButton>
              ) :
                null
          }
          </div>
        </div>

        <LinearProgress variant = 'determinate' value = {downloadInfo.progress} />

        <div className = 'progress-info'>
        {
          !downloadInfo.completed && <span> {`${downloadInfo.progress.toFixed(2)}% done`}</span>
        }
        {
          downloadInfo.completed && <div className = 'download-completed'> Completed </div>
        }
        </div>

      </div>
  );

};

export default Downloader;
