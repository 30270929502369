import {
  AGREE_DISCLAIMER,
  RECEIVE_DISCLAIMER_AGREEMENT,
  RECEIVE_USER_AVATAR,
  REDIRECT_TO_LOGIN,
  REQUEST_USER_AVATAR,
  SET_LOGGED_USER,
  UPDATE_USER_AVATAR,
  UseActionType
} from 'store/actions/logged-user-actions';

type UserState = { // TODO Very similar with UserDTO
  id: string,
  firstname: string | null,
  lastname: string | null,
  email: string | null,
  agreeDisclaimer: boolean,
  userAvatar: Blob | null,
  isFetching: boolean,
  loggingIn: boolean,
};

const initialState: UserState = {
  id: '',
  firstname: null,
  lastname: null,
  email: null,
  agreeDisclaimer: true,
  userAvatar: null,
  isFetching: false,
  loggingIn: false,
};

const loggedUserReducer = (state = initialState, action: UseActionType): UserState => {
  switch (action.type) {
    case REDIRECT_TO_LOGIN:
      return {
        ...state,
        loggingIn: true,
      };
    case SET_LOGGED_USER:
      return {
        ...state,
        id: action.id,
        firstname: action.firstname,
        lastname: action.lastname,
        email: action.email,
      };
    case AGREE_DISCLAIMER:
      return {
        ...state,
        agreeDisclaimer: true,
      };
    case REQUEST_USER_AVATAR:
      return {
        ...state,
        isFetching: true,
        userAvatar: null,
      };
    case RECEIVE_USER_AVATAR:
      return {
        ...state,
        isFetching: false,
        userAvatar: action.userAvatar,
      };
    case RECEIVE_DISCLAIMER_AGREEMENT:
      return {
        ...state,
        agreeDisclaimer: action.isDisclaimerAgreed,
      };
    case UPDATE_USER_AVATAR:
      return {
        ...state,
        isFetching: true,
        userAvatar: action.userAvatar,
      };
    default:
      return state;
  }
};

export default loggedUserReducer;