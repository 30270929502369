import { useRef, useState } from 'react';
import { Button, Grow, MenuList, Paper, Popper, ClickAwayListener } from '@mui/material';
import styles from './help-page-styles.module.scss';
import HelpIcon from '@mui/icons-material/Help';
import ArrowDownIcon from '@mui/icons-material/ArrowDropDown';
import documentationService from 'services/documentation-service';

const HelpPage: React.FC = ({ children }) => {

  const buttonRef = useRef(null);
  const [actionMenuOpen, setActionMenuOpen] = useState(false);

  const handleClickOnMenuButton = () => {
    setActionMenuOpen(!actionMenuOpen);
  };

  const closeMenu = () => {
    setActionMenuOpen(false);
  };

  return (
    <>
      <Button className={styles.headerMenuButton} ref={buttonRef} onClick={handleClickOnMenuButton}>
        {children}{/* have to use children somewhere or sonar wont let me run the app */}
        <HelpIcon color='action'/>
        <span className={styles.text}>Help</span>
        <ArrowDownIcon sx={{ 'color': 'grey' }}/>
      </Button>

      <Popper open={actionMenuOpen} anchorEl={buttonRef.current} transition disablePortal>
        {({ TransitionProps, placement }) => (
            <Grow {...TransitionProps} style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}>
                <Paper>
                <ClickAwayListener onClickAway={closeMenu}>
                    <MenuList>
                  <Button className={styles.menuItemButton} onClick={() => documentationService.downloadQuickStartDocumentation(closeMenu)}>
                            <HelpIcon color='action'/>
                            <span className={styles.menuItemButtonText}>Saas Quickstart</span>
                        </Button>
                  <Button className={styles.menuItemButton} onClick={() => documentationService.downloadUserDocumentation(closeMenu)}>
                            <HelpIcon color='action'/>
                            <span className={styles.menuItemButtonText}>Documentation for Users</span>
                        </Button>
                  <Button className={styles.menuItemButton} onClick={() => documentationService.downloadManagerDocumentation(closeMenu)}>
                            <HelpIcon color='action'/>
                            <span className={styles.menuItemButtonText}>Documentation for Managers</span>
                        </Button>
                  <Button className={styles.menuItemButton} onClick={() => documentationService.downloadDatamanagerDocumentation(closeMenu)}>
                            <HelpIcon color='action'/>
                            <span className={styles.menuItemButtonText}>Documentation for Data Managers</span>
                        </Button>
                    </MenuList>
                </ClickAwayListener>
                </Paper>
            </Grow>
        )}
        </Popper>
    </>
  );

};

export default HelpPage;