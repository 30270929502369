import React, { useState } from 'react';

import EditIcon from '@mui/icons-material/Edit';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';

import { IconButton, TextField, Dialog, DialogTitle, DialogActions, DialogContent, Grid, Button, NativeSelect } from '@mui/material';

import { updateSiteInfo } from 'store/actions/resource-site-actions';
import { useAppDispatch } from 'store';
import { SiteInfoDTO } from 'types';

import './edit-site.scss';

type OwnProps = {
  siteInfo: SiteInfoDTO
};

type Props = OwnProps;

const EditSite = ({ siteInfo }: Props) => {
  const [siteDescription, setSiteDescription] = useState('');
  const [maxVmPerUser, setMaxVmPerUser] = useState(0);
  const [selectedSismageVersion, setSelectedSismageVersion] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const dispatch = useAppDispatch();

  const increaseValue = (initialValue: number) => {
    // let value = parseInt(initialValue);
    let value = isNaN(initialValue) ? 0 : initialValue;
    value++;
    return value;
  };

  const decreaseValue = (initialValue: number) => {
    // let value = parseInt(initialValue);
    let value = isNaN(initialValue) ? 0 : initialValue;
    value--;
    value = value < 0 ? 0 : value;
    return value;
  };

  const increaseMaxVmPerUser = () => {
    setMaxVmPerUser(increaseValue(maxVmPerUser));
  };
  const decreaseMaxVmPerUser = () => {
    setMaxVmPerUser(decreaseValue(maxVmPerUser));
  };

  const loadModal = () => {
    setOpenModal(true);
    setSiteDescription(siteInfo.description);
    setMaxVmPerUser(siteInfo.maxSessionPerUser);
    setSelectedSismageVersion(siteInfo.selectedSismageVersion);
  };

  const handleDescriptionChange = event => {
    setSiteDescription(event.target.value);
  };

  const handleVmPerUserChange = event => {
    setMaxVmPerUser(event.target.value);
  };

  const handleSelectedSismageVersionChange = event => {
    setSelectedSismageVersion(event.target.value);
  };

  const closeModal = () => {
    setOpenModal(false);
  };

  const handleUpdateSite = () => {
    dispatch(updateSiteInfo(siteInfo.id, siteDescription, maxVmPerUser, selectedSismageVersion));
    setOpenModal(false);
  };
  return (
    <div className='edit-site-container'>
      <div>
        <IconButton
          onClick={loadModal}
          key={siteInfo.id}
          classes={{
            root: 'edit-button-color',
          }}>
          <EditIcon className='edit-icon-site'/>
        </IconButton>
      </div>
      <Dialog classes={{ paper: 'dialog-container' }} open={openModal} maxWidth='md' onClose={closeModal} className='dialog'>
        <DialogTitle>
          <div className='dialogTitle'>
            <div className='modalTitle'>EDIT SITE</div>
          </div>
          <div className='edit-modal-site-name'>{siteInfo.label || ''}</div>
        </DialogTitle>
        <DialogContent classes={{ root: 'overflow-y-visible' }}>
          <div>
            <TextField
              id='site-description'
              label='Description'
              margin='normal'
              variant='outlined'
              multiline={true}
              rows={5}
              fullWidth={true}
              defaultValue={siteDescription}
              onChange={handleDescriptionChange}/>
            <div className='second-input-block-info-site'>
              <div>
                <span style={{ display: 'inline-flex', alignItems: 'center' }}>
                  <br/>
                  <ReportProblemIcon style={{ color: 'orange', paddingRight: '6px' }}/>
                  <b>Warning! If you allow more than 1 VM per user, datalocal may not be persisted for all sessions.</b>
                </span>
              </div>
              <div className='incr-decr-button'>
                <span className='incr-decr-button_left_label'> Max VM per user </span>
                <div className='value-button' id='decrease' onClick={decreaseMaxVmPerUser}>
                  -
                </div>
                <input type='number' id='number' value={maxVmPerUser} onChange={handleVmPerUserChange}/>
                <div className='value-button' id='increase' onClick={increaseMaxVmPerUser}>
                  +
                </div>
              </div>
            </div>
            <div className='version-selection'>
              <div className='version-selection__title'>Sismage version</div>
              <NativeSelect
                  onChange={event => handleSelectedSismageVersionChange(event)}
                  value={ selectedSismageVersion }>
                  <option value='' key='None'>latest</option>
                  {siteInfo.availableSismageVersions.map(version => (
                    <option value={version.sismageVersionNumber} key={version.sismageVersionNumber}>
                      {version.sismageVersionNumber}
                    </option>
                  ))
                  }
              </NativeSelect>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Grid container alignItems='stretch' direction='row' justifyContent='flex-end' className='dialog-actions'>
            <Grid item>
              <Button onClick={closeModal} className='cancel-button' sx={{ color: 'black' }}>
                Cancel
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant='contained'
                color='primary'
                className='validation-button'
                disabled={
                  siteInfo.description === siteDescription &&
                  Math.abs(siteInfo.maxSessionPerUser - maxVmPerUser) < Number.EPSILON &&
                  siteInfo.selectedSismageVersion === selectedSismageVersion
                }
                onClick={handleUpdateSite}>
                VALIDATE
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default EditSite;
