import React, { useEffect, useState } from 'react';
import './user-profile.scss';
import { uploadUserAvatarAction } from 'store/actions/logged-user-actions';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from 'store/reducers';
import { useAppDispatch } from 'store';
import EditIcon from '@mui/icons-material/Edit';
import UserIcon from '@mui/icons-material/AccountCircle';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid } from '@mui/material';
import UploadIcon from '@mui/icons-material/Publish';
import DeleteIcon from '@mui/icons-material/Delete';
import { HomeDirectoryDTO } from 'types';
import fileService from 'services/file-service';
import { convertUTCDateToLocalDate } from 'services/date-service';

const mapState = (state: RootState) => ({
  user: {
    id: state.loggedUserReducer.id,
    firstname: state.loggedUserReducer.firstname,
    lastname: state.loggedUserReducer.lastname,
    email: state.loggedUserReducer.email,
    userAvatar: state.loggedUserReducer.userAvatar,
  },
});

const connector = connect(mapState);

type ReduxProps = ConnectedProps<typeof connector>;

const UserProfile = ({ user }: ReduxProps) => {
  const dispatch = useAppDispatch();

  const [openModal, setOpenModal] = useState(false);
  const [searchedHomeDirectory, setsearchedHomeDirectory] = useState(false);
  const [homeDirectoryOperationRunning, setHomeDirectoryOperationRunning] = useState(false);
  const [homeDirectoryInfos, setHomeDirectoryInfos] = useState<HomeDirectoryDTO | undefined>(undefined);

  useEffect(() => {
    fileService.getUserHomeDirectoryInfos()
      .then(infos => setHomeDirectoryInfos(infos))
      .finally(() => setsearchedHomeDirectory(true));
  }, []);

  const handleSelectedFile = e => {
    e.preventDefault();
    dispatch(uploadUserAvatarAction(e.target.files[0]));
  };

  const downloadUserHomeDirectory = () => {
    setHomeDirectoryOperationRunning(true);

    fileService.downloadUserHomeDirectory()
      .finally(() => setHomeDirectoryOperationRunning(false));
  };

  const deleteUserHomeDirectory = () => {
    setHomeDirectoryOperationRunning(true);

    fileService.deleteUserHomeDirectory()
      .then(() => setHomeDirectoryInfos(undefined))
      .finally(() => setHomeDirectoryOperationRunning(false));
    
    setOpenModal(false);
  };

  const loadModal = () => {
    setOpenModal(true);
  };

  const closeModal = () => {
    setOpenModal(false);
  };

  return (
    <div className='user-profile-container'>
      <div className='user-profile-title'>MY PROFILE</div>
      <div className='top-banner'>
        <div className='profile-container'>
          <div className='user-avatar'>
            {user.userAvatar ? <img src={`data:image/jpeg;base64, ${user.userAvatar}`} alt='Avatar' /> : <UserIcon className='empty-user-avatar' />}
            <EditIcon className='change-avatar-icon-edit' />
            <span className='change-avatar-text'>Change profile picture </span>
            <input id='upload' type='file' onClick={e => e.stopPropagation()} onChange={handleSelectedFile} title=' ' accept='image/png, image/jpeg' />
          </div>
          <div className='user-info'>
            <span>{`${user.firstname} ${user.lastname}`}</span>
            <span>{user.id}</span>
            <span className='user-mail'>{user.email}</span>
          </div>
        </div>
      </div>

      <div className='home-directory-container'>
        {homeDirectoryInfos ? (
          <div className='home-directory-infos'>
            <p><b>Home (archive size is {homeDirectoryInfos.sizeInMB} MB)</b></p>
            <p><b>Last modified date : {convertUTCDateToLocalDate(homeDirectoryInfos.lastModifiedDate)}</b></p>
          </div>
        ) : (
          <div className='home-directory-infos'>
            {searchedHomeDirectory ? (
              <p><b>No Home Directory</b></p>
            ) : (
              <p><b>Searching home directory...</b></p>
            )
            }
          </div>
        )}

        <DialogActions>
          <Grid container spacing={2}>
            <Grid item>
              <Button onClick={downloadUserHomeDirectory} variant='contained' className='home-directory-download-button' disabled={!homeDirectoryInfos || homeDirectoryOperationRunning}
              startIcon={<UploadIcon className='download_icon_reversed'/>}>
                <span>Download</span>
              </Button>
            </Grid>
            <Grid item>
                <Button onClick={loadModal} variant='contained' className='home-directory-delete-button' disabled={!homeDirectoryInfos || homeDirectoryOperationRunning}
                startIcon={<DeleteIcon/>}>
                  <span>Delete</span>
                </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </div>

      <Dialog open={openModal} onClose={closeModal} aria-labelledby='alert-dialog-title' aria-describedby='alert-dialog-description'>
        <DialogTitle id='alert-dialog-title'>Delete home directory</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>Do you want to delete your home directory ?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeModal} color='secondary' autoFocus>
            No
          </Button>
          <Button onClick={deleteUserHomeDirectory} color='primary'>
            Yes
          </Button>
        </DialogActions>
      </Dialog>

    </div>
  );
};

export default connector(UserProfile);
