/* eslint-disable max-len */
import React, { ReactElement } from 'react';
import './loader-style.scss';

type Props = {
  className: string
};

const Loader = (props: Props): ReactElement => (
  <svg xmlns='https://www.w3.org/2000/svg' width='64' height='64' viewBox='0 0 64 64' className={[props.className, 'loader'].join(' ')}>
    <g fill='none' fillRule='evenodd'>
      <path
        fill='#FFF'
        d='M32 64C14.336 64 0 49.664 0 32S14.336 0 32 0s32 14.336 32 32c0 1.024-.896 1.92-1.92 1.92s-1.92-.896-1.92-1.92C60.16 16.512 47.488 3.84 32 3.84S3.84 16.512 3.84 32 16.512 60.16 32 60.16c1.024 0 1.92.896 1.92 1.92S33.024 64 32 64z'/>
      <path
        fill='#4494D0'
        d='M32 57.6C17.92 57.6 6.4 46.08 6.4 32c0-1.024.896-1.92 1.92-1.92s1.92.896 1.92 1.92c0 12.032 9.728 21.76 21.76 21.76 12.032 0 21.76-9.728 21.76-21.76 0-12.032-9.728-21.76-21.76-21.76-1.024 0-1.92-.896-1.92-1.92S30.976 6.4 32 6.4c14.08 0 25.6 11.52 25.6 25.6 0 14.08-11.52 25.6-25.6 25.6z'/>
    </g>
  </svg>
);

Loader.defaultProps = {
  className: '',
};

export default Loader;
