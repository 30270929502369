import { 
  ERROR_FETCH_ZONE_INFORMATION, 
  RECEIVE_ZONE_INFORMATION, 
  REQUEST_ZONE_INFORMATION, 
  ZoneActions 
} from 'store/actions/resource-zone-actions';
import { ZoneInfoDTO } from 'types';

export type ZoneState = {
  isFetching: boolean,
  zoneInformation: ZoneInfoDTO | null,
  isFetchingZoneInformation: boolean
};

const initialState: ZoneState = {
  isFetching: false,
  zoneInformation: null,
  isFetchingZoneInformation: false,
};

const infoZoneReducer =  (state = initialState, action: ZoneActions): ZoneState => {
  switch (action.type) {
    case REQUEST_ZONE_INFORMATION:
      return {
        ...state,
        isFetchingZoneInformation: true,
        zoneInformation: null,
      };

    case RECEIVE_ZONE_INFORMATION:
      return {
        ...state,
        isFetchingZoneInformation: false,
        zoneInformation: action.zoneInformation,
      };

    case ERROR_FETCH_ZONE_INFORMATION:
      return {
        ...state,
        isFetchingZoneInformation: false,
        zoneInformation: null,
      };

    default:
      return state;
  }
};

export default infoZoneReducer;