import { REQUEST_NOTIFICATIONS, RequestNotificationsAction } from 'store/actions/notification-actions';

type NotificationState = {
  isFetching: boolean,
};

const initialState: NotificationState = {
  isFetching: false,
};

const notificationReducer = (state = initialState, action: RequestNotificationsAction): NotificationState => {
  switch (action.type) {
    case REQUEST_NOTIFICATIONS:
      return {
        ...state,
        isFetching: true,
      };
    default:
      return state;
  }
};

export default notificationReducer;