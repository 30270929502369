export const ADD_FILE_UPLOAD = 'ADD_FILE_UPLOAD';
export const DELETE_FILE_UPLOAD = 'DELETE_FILE_UPLOAD';
export const SHOW_REDIRECTION_WARNING_MODAL = 'SHOW_REDIRECTION_WARNING_MODAL';

export type AddFileUploadAction = {
  type: typeof ADD_FILE_UPLOAD, 
  siteId: number, 
  projectId:  number,
  uploadId: string,
  file: File
};

export type DeleteFileUploadAction = {
  type: typeof DELETE_FILE_UPLOAD, 
  uploadId : string
};

export type ShowRedirectionWarningModal = {
  type: typeof SHOW_REDIRECTION_WARNING_MODAL
};

export const addFileUploadAction = (
  siteId: number, 
  projectId: number, 
  uploadId: string, 
  file: File
): AddFileUploadAction => ({ type: ADD_FILE_UPLOAD, siteId, projectId, uploadId, file });
export const deleteFileUploadAction = (
  uploadId: string
): DeleteFileUploadAction => ({ type: DELETE_FILE_UPLOAD, uploadId });
export const showRedirectionWarningModal = (): ShowRedirectionWarningModal => ({ type: SHOW_REDIRECTION_WARNING_MODAL });

export type FileUploadActionType = AddFileUploadAction
| DeleteFileUploadAction
| ShowRedirectionWarningModal;