import React, { ReactNode, useState } from 'react';
import Downloader, { DownloadFile } from './downloader';
import { v4 as uuid } from 'uuid';

const useFileDownloader = (): [(file: string) => void, ReactNode] => {
  const [files, setFiles] = useState<DownloadFile[]>(() => []);

  const download = (file) => setFiles((fileList) => [...fileList, { ...file, downloadId: uuid() }]);

  const remove = (removeId) => setFiles((files) => [
    ...files.filter((file) => file.downloadId !== removeId),
  ]);

  return [
    (e) => download(e),
    files.length > 0 ? (
      <Downloader files={files} remove={(e) => remove(e)}/>
    ) : null,
  ];
};

export default useFileDownloader;
