import React from 'react';
import { Dialog, DialogContent, Grid, Button } from '@mui/material';
import { connect, ConnectedProps } from 'react-redux';
import { agreeDisclaimer } from 'store/actions/logged-user-actions';
import { bindActionCreators } from 'redux';

const mapDispatch = (dispatch) => bindActionCreators(
  {
    agreeDisclaimer,
  },
  dispatch
);

const connector = connect(null, mapDispatch);

type ReduxProps = ConnectedProps<typeof connector>;

const UserAgreeDisclaimer = ({ agreeDisclaimer }: ReduxProps) => {
  return (
    <Dialog open fullWidth maxWidth='sm'>
      <DialogContent>
        <p>
          TOTALENERGIES SA processes your personal data in accordance with the applicable regulations.
          The data collected are : your IGG, as well as your activity logs.
          These personal data are processed for the purpose of managing and securing our Information System.
          The treatment is based on our legitimate interest and is necessary to enable us to achieve the above objective.
          Your data are kept for the duration necessary for the purpose of the processing, namely 6 months for the historical connection logs,
          and the time of your presence in the company for the IGG.
          In accordance with the regulations in force, you can request the communication of your personal data,
          you can exercise these rights by addressing the following entity: ep.sismage-saas@totalenergies.com
        </p>
        <p>
          Data input in free input fields must be appropriate, pertinent and non-excessive. 
          Comments must therefore remain objective, and not be insulting or impact a person’s dignity.
        </p>
        <p>
          We use cookies to provide the services and features offered on our site and to improve the experience of our users.
          Cookies are data that are downloaded or stored on your computer or any other device.
        </p>
        <p>
          By clicking on "I accept", you accept the use of cookies. You can always disable them later.
          If you delete or disable our cookies, you may encounter interruptions or problems accessing the site.
        </p>
        <Grid item xs>
          <Grid container alignItems='stretch' direction='row' justifyContent='flex-end'>
            <Grid item>
              <Button onClick={() => agreeDisclaimer()} variant='contained' color='primary'>
                I ACCEPT
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default connector(UserAgreeDisclaimer);
