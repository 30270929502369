import { StorageTemplateDTO } from 'types';

export const SET_STORAGE_TEMPLATES_LIST = 'SET_STORAGE_TEMPLATES_LIST';
export const ADD_STORAGE_TEMPLATES = 'ADD_STORAGE_TEMPLATES';

export type SetStorageTemplates = {
  type: typeof SET_STORAGE_TEMPLATES_LIST, 
  templates: {
    [id: string]: StorageTemplateDTO[]
  }
};

export type AddStorageTemplates = {
  type: typeof ADD_STORAGE_TEMPLATES,
  templates: {
    [id: string]: StorageTemplateDTO[]
  }
};

export const setStorageTemplates = (templates): SetStorageTemplates => ({ type: SET_STORAGE_TEMPLATES_LIST, templates });
export const addStorageTemplates = (templates): AddStorageTemplates => ({ type: ADD_STORAGE_TEMPLATES, templates });

export type StorageTemplateActionType = SetStorageTemplates | AddStorageTemplates;
