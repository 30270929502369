import ArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { MenuItem } from '@mui/material';
import { ReactElement, useState } from 'react';
import CustomMenu from './custom-menu';
import CustomMenuItem from './menu-item';

import styles from './menu-item.module.scss';

type OwnProps = {
  caption: ReactElement,
  menuItems: CustomMenuItem[]
};

type Props = OwnProps ;

const CustomSubMenu = ({ caption, menuItems }: Props) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [anchorElement, setAnchorElement] = useState(null);

  const handleItemClick = event => {
    if (!anchorElement) {
      setAnchorElement(event.currentTarget);
    }
    setMenuOpen(!menuOpen);
  };

  const handleSubMenuClose = () => {
    setMenuOpen(false);
  };

  return (
    <>
      <MenuItem onClick={handleItemClick} className={styles.subMenuItem} >
        {caption}
        <ArrowRightIcon className={styles.arrow_right_icon}/>
      </MenuItem>
      <CustomMenu
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        open={menuOpen}
        menuItems={menuItems}
        anchorElement={anchorElement}
        onClose={handleSubMenuClose}/>
    </>
  );
};

export default (CustomSubMenu);
