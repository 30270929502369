import { Menu, MenuItem, PopoverOrigin, Tooltip } from '@mui/material';
import CustomMenuItem from './menu-item';
import CustomSubMenu from './sub-menu';

import styles from './menu-item.module.scss';

type Props = {
  menuItems: CustomMenuItem[], 
  anchorElement: Element | null, 
  open: boolean, 
  onClose: (event) => void,
  anchorOrigin?: PopoverOrigin, 
  transformOrigin?: PopoverOrigin
};

const CustomMenu = ({
  menuItems, anchorElement, open, onClose, anchorOrigin, transformOrigin, 
}: Props) => {
  return (
    <Menu anchorOrigin={anchorOrigin} transformOrigin={transformOrigin} anchorEl={anchorElement} open={open} onClose={onClose}>
      {menuItems
        .filter(menuItem => menuItem?.display)
        .map(menuItem => {
          if (menuItem?.subMenuItems) {
            return <CustomSubMenu key={menuItem.key} caption={menuItem.caption} menuItems={menuItem.subMenuItems} />;
          }

          return (
            <Tooltip key={menuItem.key} title={menuItem.disabled ? menuItem.disabledTooltip : ''}>
              <div> {/* MenuItem must be wrapped in a div to display tooltip on disabled menu items */}
                <MenuItem onClick={menuItem.onClick} disabled={menuItem.disabled} onKeyDown={e => e.stopPropagation()} className={styles.menu_item}>
                  {menuItem.caption}
                </MenuItem>
              </div>
            </Tooltip>
          );
        })}
    </Menu>
  );
};

export default CustomMenu;