import { DATAMANAGER_DOCUMENTATION, MANAGER_DOCUMENTATION, QUICK_START_DOCUMENTATION, USER_DOCUMENTATION } from 'constants/documentation';

export default {
  downloadDocumentation(docName: string, postDownloadFunc?: () => void) {
    const env = process.env.REACT_APP_SAAS_ENV;
    const url = 'https://portal.' + env + '.saas.geosciences.totalenergies.com/resources/help/' + env + '/' + docName;

    const link = document.createElement('a');
    link.href = url;
    link.target = '_blank';
    link.click();
    link.remove();
    if (postDownloadFunc)
      postDownloadFunc();
  },
  downloadDatamanagerDocumentation(postDownloadFunc?: () => void): void {
    this.downloadDocumentation(DATAMANAGER_DOCUMENTATION, postDownloadFunc);
  },
  downloadQuickStartDocumentation(postDownloadFunc?: () => void): void {
    this.downloadDocumentation(QUICK_START_DOCUMENTATION, postDownloadFunc);
  },
  downloadUserDocumentation(postDownloadFunc?: () => void): void {
    this.downloadDocumentation(USER_DOCUMENTATION, postDownloadFunc);
  },
  downloadManagerDocumentation(postDownloadFunc?: () => void): void {
    this.downloadDocumentation(MANAGER_DOCUMENTATION, postDownloadFunc);
  },
};