import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material';
import SismageLogo from 'assets/images/sismage-logo.png';
import Loader from 'components/loader/loader';
import { useState } from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { useAppDispatch } from 'store';
import { closeVmExtraInstallsModal, setVmExtraInstallsToAddAction, updateSmgInstallsAction } from 'store/actions/vm-extra-installs-actions';
import { RootState } from 'store/reducers';
import { VmDTO } from 'types';
import './vm-component-share.scss';
import './vm-contributors.scss';
import styles from './vm-extra-installs.module.scss';
import VmInstallsList from './vm-installs-list';
import VmInstallSelectorInput from './vm-installs-selector-input';

const mapState = (state: RootState) => ({
  selectedVM: state.vmExtraInstallsReducer.selectedVM,
  installsToAdd: state.vmExtraInstallsReducer.extraInstallsToAdd,
});

const mapDispatch = {
  closeVmExtraInstallsModal,
  setVmExtraInstallsToAddAction,
};

const connector = connect(mapState, mapDispatch);

type ReduxProps = ConnectedProps<typeof connector>;

type OwnProps = {
  vmInstance: VmDTO
};

type Props = ReduxProps & OwnProps;

const VmExtraInstalls = ({
  vmInstance,
  selectedVM,
  installsToAdd,
  closeVmExtraInstallsModal,
  setVmExtraInstallsToAddAction,
}: Props) => {

  const dispatch = useAppDispatch();
  const [pending, setPending] = useState(false);

  const handleInstallsToAddChange = installs => {
    setVmExtraInstallsToAddAction(installs);
  };
  const updateSmgInstalls = () => {
    setPending(true);
    dispatch(updateSmgInstallsAction()).then(() => setPending(false));
  };

  const allowedSismageVersionsRegex = new RegExp(vmInstance.smgInstallsRegexp);
  const allowRelease = allowedSismageVersionsRegex.test('release/');
  const allowCd = allowedSismageVersionsRegex.test('cd/');
  const allowDaily = allowedSismageVersionsRegex.test('daily/');
  const allowChange = allowedSismageVersionsRegex.test('change/');
  const allowPR = allowedSismageVersionsRegex.test('PR/');

  return (
    <div>
      <Dialog classes={{ paper: 'dialog-container' }} open={vmInstance && vmInstance.name === (selectedVM?.name || '')} maxWidth='md' onClose={closeVmExtraInstallsModal} className='dialog'>
        <DialogTitle>
          <div className={styles.dialogTitle}>

            <DialogTitle className={styles.dialogTitle}>
              <img src={SismageLogo} alt='sismage' width='24' height='24'/>
              <div className={styles.addInstallTitle}>Sismage non-official installations for {vmInstance.name || ''}</div>
            </DialogTitle>
          </div>
        </DialogTitle>
        <DialogContent classes={{ root: 'overflow-y-visible' }}>
          <span className={styles.disclaimerMessage}>Type the first letters to trigger search, depending on your site some options may not be available.</span><br/>
          <br/>
          {allowRelease && (<div><span className={styles.disclaimerMessage}>Use <b>"release/"</b> to list all legacy releases</span><br/></div>)}
          {allowCd && (
            <div><span className={styles.disclaimerMessage}>Use <b>"cd/"</b> for all test-build of the cd and <b>"cd"</b> for all test-build of the cd <b>and</b> releases.</span><br/></div>
          )}
          {allowDaily && (<div><span className={styles.disclaimerMessage}>Use <b>"daily/"</b> for dailies</span><br/></div>)}
          {allowChange && (<div><span className={styles.disclaimerMessage}>Use <b>"change/"</b> for test-changes.</span><br/></div>)}
          {allowPR && (<div><span className={styles.disclaimerMessage}>Use <b>"pr/"</b> for custom releases post github migration.</span><br/></div>)}
          <br/>
          <span className={styles.disclaimerMessage}><b>Examples:</b></span><br/>
          {(allowRelease || allowCd) && (<div><span className={styles.disclaimerMessage}>"23", "23.4"</span><br/></div>)}
          {allowRelease && (<div><span className={styles.disclaimerMessage}>"release/", "release/23", "release/23.4", "release/23.4-20240130"</span><br/></div>)}
          {allowCd && (<div><span className={styles.disclaimerMessage}>"cd/", "cd/23", "cd/23.4", "cd/23.4/20240227", "cd", "cd23", "cd23.4"</span><br/></div>)}
          {allowDaily && (<div><span className={styles.disclaimerMessage}>"2024", "daily/", "daily/20240227"</span><br/></div>)}
          {allowChange && (<div><span className={styles.disclaimerMessage}>"511", "change/", "change/51160", "change/51160/1"</span><br/></div>)}
          {allowPR && (<div><span className={styles.disclaimerMessage}>"1100", "pr/", "pr/1339"</span><br/></div>)}
          <br/>
          <VmInstallsList smgInstalls={vmInstance.smgInstalls || []}/>
          <VmInstallSelectorInput siteId={vmInstance.siteId} onChange={handleInstallsToAddChange} alreadyInstalled={vmInstance.smgInstalls}/>
        </DialogContent>
        <DialogActions>
          <Grid container alignItems='stretch' direction='row' justifyContent='flex-end' className='dialog-actions'>
            <Grid item>
              <Button onClick={closeVmExtraInstallsModal} className={styles.cancelButton}>
                Cancel
              </Button>
            </Grid>
            <Grid item>
              <Button
                onClick={updateSmgInstalls}
                variant='contained'
                color='primary'
                className={styles.validationButton}
                disabled={pending || installsToAdd.length === 0}>
                {pending ? <Loader className='loaderUpdate'/> : 'VALIDATE'}
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </div>
  );
};


export default connector(VmExtraInstalls);
