
import UserIcon from '@mui/icons-material/AccountCircle';
import ClearIcon from '@mui/icons-material/Clear';
import { Grid, IconButton, List, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText } from '@mui/material';
import Loader from 'components/loader/loader';
import { UserDTO } from 'types';

type Props = {
  contributorList: UserDTO[],
  sharedEmailList: string[],
  isFetchingContributors: boolean,
  onDeleteContributor: (id: string) => void,
  onDeleteSharedEmail: (email: string) => any //FIXME unexpected any (see backlog item 61834)
};

const ContributorList = ({
  contributorList, sharedEmailList, isFetchingContributors, onDeleteContributor, onDeleteSharedEmail, 
}: Props) => {
  const allContributors = [...contributorList, ...sharedEmailList.map(email => ({ id: undefined, email }))];

  return (
    <div className='contributor-list-root'>
      <div className='label-bold'>Session shared with :</div>
      <div className='contributor-list-container'>
        {isFetchingContributors ? (
          <Grid container direction='row' justifyContent='center' alignItems='center'>
            <Loader className='loader-icon' />
          </Grid>
        ) : (
          <div className='contributor-list'>
            <List dense disablePadding>
              {
              allContributors.map((contributor, index) => {
                const labelId = `checkbox-list-secondary-label-${contributor.id || contributor.email}`;
                return (
                  <ListItem key={contributor.id || contributor.email} className={index % 2 !== 0 ? 'list-item-even' : ''}>
                    <ListItemAvatar>
                      <UserIcon className='contributor-avatar' />
                    </ListItemAvatar>
                    {contributor.id ? (
                      <ListItemText
                        id={labelId}
                        classes={{
                          primary: 'label-bold',
                        }}
                        primary={`${contributor.id}${contributor.firstname || contributor.lastname ? ` - ${contributor.firstname} ${contributor.lastname}` : ''}`}
                        secondary={contributor.email || 'Not yet connected'}/>
                    ) : (
                      <ListItemText
                        id={labelId}
                        classes={{
                          primary: 'label-bold',
                        }}
                        primary={contributor.email}
                        secondary='Shared by email'/>
                    )}
                    <ListItemSecondaryAction>
                      <IconButton
                        edge='end'
                        aria-label='delete'
                        onClick={() => (contributor.id ? onDeleteContributor(contributor.id) : onDeleteSharedEmail(contributor.email))}
                        className='icon-delete'>
                        <ClearIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                );
              })
              }
            </List>
          </div>
        )}
      </div>
    </div>
  );
};

export default ContributorList;
