import React from 'react';

import './project-manager.scss';

import { Grid, List, ListItem, ListItemAvatar, ListItemText } from '@mui/material';
import UserIcon from '@mui/icons-material/AccountCircle';
import Loader from 'components/loader/loader';
import { ManagerDTO } from 'types';

type Props = {
  isFetchingManagerList: boolean,
  managerList: ManagerDTO[]
};

const ProjectManagerList = ({ managerList, isFetchingManagerList }: Props) => {
  managerList.sort((a, b) => (a.lastname + a.firstname + a.userId).localeCompare(b.lastname + b.firstname + b.userId));

  return (
    <div className='project-manager-list-root'>
      <div className='project-manager-list-container'>
        {isFetchingManagerList ? (
          <Grid container direction='row' justifyContent='center' alignItems='center'>
            <Loader className='loader-icon' />
          </Grid>
        ) : (
            <div className='project-manager-list'>
              <List dense disablePadding>
                {managerList.map((manager, index) => {
                  const labelId = `checkbox-list-secondary-label-${manager.userId}`;
                  return (
                    <ListItem key={manager.userId} classes={{ root: index % 2 !== 1 ? 'list-item-odd' : '' }}>
                      <ListItemAvatar>
                        {manager.avatar ? (
                          <img src={`data:image/jpeg;base64,${manager.avatar}`} className='project-managers-block_content__userFilledAvatar' alt='Avatar' />
                        ) : (
                            <UserIcon className='project-manager-avatar' />
                        )}
                      </ListItemAvatar>
                      <ListItemText
                        id={labelId}
                        classes={{
                          primary: 'label-bold',
                        }}
                        primary={`${manager.userId}${manager.firstname || manager.lastname ? ` - ${manager.firstname} ${manager.lastname}` : ''}`}
                        secondary={manager.email || 'Not yet connected'}/>
                    </ListItem>
                  );
                })}
              </List>
              {managerList.length === 0 && (
                <div className='noManager'>There is no manager for this project. Check site info or zone info.</div>
              )}
            </div>
        )}
      </div>
    </div>
  );
};

export default ProjectManagerList;
