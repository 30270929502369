import { AnyAction, combineReducers } from 'redux';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import fileDownloadReducer from './file-download-reducers';
import fileShareReducer from './file-share-reducers';
import fileUploadReducer from './file-upload-reducers';
import infoSiteReducer from './info-site-reducers';
import infoZoneReducer from './info-zone-reducers';
import loggedUserReducer from './logged-user-reducers';
import machineTemplateReducer from './machine-template-reducers';
import manageRightsReducer from './manage-rights-reducers';
import modalReducer from './modal-reducers';
import notificationReducer from './notification-reducers';
import resourcesReducer from './resources-reducers';
import storageTemplateReducer from './storage-template-reducers';
import userBillingReducer from './user-billing-reducers';
import vmExtraInstallsReducer from './vm-extra-installs-reducers';
import vmInstanceContributorsReducer from './vm-instance-contributors-reducers';
import vmInstancesReducer from './vm-instance-reducers';

export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, AnyAction>;
export type AppDispatch = ThunkDispatch<RootState, unknown, AnyAction>;

const rootReducer = combineReducers({
  fileDownloadReducer,
  fileShareReducer,
  fileUploadReducer,
  infoSiteReducer,
  infoZoneReducer,
  loggedUserReducer,
  machineTemplateReducer,
  manageRightsReducer,
  modalReducer,
  notificationReducer,
  resourcesReducer,
  storageTemplateReducer,
  userBillingReducer,
  vmExtraInstallsReducer,
  vmInstanceContributorsReducer,
  vmInstancesReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
