import React from 'react';

import './site-info.scss';
import { ListItem, ListItemAvatar, ListItemText, List } from '@mui/material';
import UserIcon from '@mui/icons-material/AccountCircle';
import { SiteInfoDTO } from 'types';


type Props = {
  siteInfo: SiteInfoDTO;
};

const SiteManagersInfo = ({ siteInfo }: Props) => {
  siteInfo.siteManagers.sort((a, b) => (a.lastname + a.firstname + a.userId).localeCompare(b.lastname + b.firstname + b.userId));

  return (
    <>
      <div className='site-managers-block'>
        <div className='site-managers-block__title'>Site managers</div>
        <div className='site-managers-block_content'>
          <List dense disablePadding>
            {siteInfo.siteManagers.map((manager, index) => {
              const labelId = `checkbox-list-secondary-label-${manager.userId}`;
              return (
                <ListItem key={manager.userId} className={index % 2 !== 0 ? 'list-item-even' : ''}>
                  <ListItemAvatar>
                    {manager.avatar ? (
                      <img src={`data:image/jpeg;base64,${manager.avatar}`} className='site-managers-block_content__userFilledAvatar' alt='' />
                    ) : (
                        <UserIcon className='site-managers-block_content__userEmptyAvatar' />
                    )}
                  </ListItemAvatar>
                  <ListItemText
                    id={labelId}
                    classes={{
                      primary: 'label-bold',
                    }}
                    primary={`${manager.userId}${manager.firstname || manager.lastname ? ` - ${manager.firstname} ${manager.lastname}` : ''}`}
                    secondary={manager.email || 'Not yet connected'}/>
                </ListItem>
              );
            })}
          </List>
        </div>
        {siteInfo.siteManagers.length === 0 && (
          <div className='noSiteManager'>There is no manager for this site. Check zone info or project info.</div>
        )}
      </div>
    </>
  );
};

export default SiteManagersInfo;
