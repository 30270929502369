import { configureStore, MiddlewareArray } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';
import rootReducer, { AppDispatch } from './reducers';
import { useDispatch } from 'react-redux';

// use this in local instead of configureStore if you want to activate redux devtools in your browser
// import { MiddlewareArray } from '@reduxjs/toolkit';
// import { createStore, compose, applyMiddleware } from 'redux';

// const store = createStore(
//   rootReducer,
//   compose(
//     applyMiddleware(...(new MiddlewareArray().concat(thunk))),
//     (window as any).__REDUX_DEVTOOLS_EXTENSION__ && (window as any).__REDUX_DEVTOOLS_EXTENSION__()
//   )
// );

const store = configureStore({
  reducer: rootReducer,
  middleware: new MiddlewareArray().concat(thunk),
});


export type RootState = ReturnType<typeof store.getState>;
export const useAppDispatch = () => useDispatch<AppDispatch>();

export default store;
