import documentationService from 'services/documentation-service';
import './welcome-message.scss';

type Props = {
  loggedUser: {
    id: string,
    firstname: string,
    lastname: string,
  },
};

const WelcomeMessage = ({ loggedUser }: Props) => {
  return (
        <div className='welcome-message'>
            <div className='title'>{`WELCOME ${loggedUser.firstname} ${loggedUser.lastname}`}</div>
            <div className='message'>
                <p>
                    <br/>
                    <br/>
                    <span>Congrats ! You are successfully connected to <strong>SISMAGE-SaaS</strong>.</span>
                    <br/>
                    <span>However, it looks like you have not been allowed to use a sismage-project yet and/or that no one has shared a session with you.</span>
                    <br/>
                    <br/>
                    <span>Get in touch with your data-manager/project-manager or whoever sent you here to be added on a sismage-project.</span>
                    <br/>
                    <span>Or if you simply want to test or play with Sismage-Saas, please contact us </span>
                    <a className='link-style' href='mailto: ep.sismage-saas@totalenergies.com'>ep.sismage-saas@totalenergies.com</a>
                    <br/>
                    <br/>
                    <span>Please contact someone (with manager role) who can grant you a user access to a study.</span>
                    <br/>
                    <br/>
                    <span>In the mean time, please consider having a quick look at the user-guide </span>
                    <a href='#' onClick={() => documentationService.downloadQuickStartDocumentation()}>here</a>
                    <br/>
                    <br/>
                    <span>Have a nice day !</span>
                    <br/>
                    <br/>
                    <strong>The SISMAGE_SaaS team</strong>
                </p>
            </div>
        </div>
  );
};

export default WelcomeMessage;
