import {
  ADD_MACHINE_TEMPLATES,
  MachineActionType,
  SET_MACHINE_TEMPLATES_LIST
} from 'store/actions/machine-template-actions';
import { AvailableMachineTemplateDTO } from 'types';

type MachineState = {
  machineTemplates: {
    [id: string]: AvailableMachineTemplateDTO[]
  }
};

const initialState: MachineState  = {
  machineTemplates: {},
};

const machineTemplateReducer = (state = initialState, action: MachineActionType): MachineState => {
  switch (action.type) {
    case SET_MACHINE_TEMPLATES_LIST:
      return {
        ...state,
        machineTemplates: action.machineTemplates,
      };
    case ADD_MACHINE_TEMPLATES:
      return {
        ...state,
        machineTemplates: { ...state.machineTemplates, ...action.machineTemplates },
      };
    default:
      return state;
  }
};

export default machineTemplateReducer;

