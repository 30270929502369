import { MANAGER } from "constants/user-authority";
import resourcePathService from "services/resource-path-service";
import { AccessMgtDataDTO, FilterAclObject, ResourceAccessNode } from 'types';

export type Row = {
  aclId: number,
  userId: string,
  siteId: number,
  userFirstname: string,
  userLastname: string,
  resourcePath: string,
  permissionAuthority: string,
  expirationDate: string,
  isExpired: string,
  willExpireWithin30Days: string,
  machineTemplateId: number,
  lastModifiedDate: number,
  lastModifiedBy: string,
};

//FIXME I hate having to do this
//but customRender from MuiDataTable does not return the Row type
//it returns an Array instead
export const parseRowData = (rowData): Row => {
  return {
    aclId: rowData[0],
    userId: rowData[1],
    siteId: rowData[2],
    userFirstname: rowData[3],
    userLastname: rowData[4],
    resourcePath: rowData[5],
    permissionAuthority: rowData[6],
    expirationDate: rowData[7],
    isExpired: rowData[8],
    willExpireWithin30Days: rowData[9],
    machineTemplateId: rowData[10],
    lastModifiedDate: rowData[11],
    lastModifiedBy: rowData[12],
  };
};


export const parseFilterData = (rowData): FilterAclObject => {
  const row = parseRowData(rowData);
  return {
    resourcePath: typeof row.resourcePath !== 'undefined' ? row.resourcePath[0] : '',
    permissionAuthority: typeof row.permissionAuthority !== 'undefined' ? row.permissionAuthority[0] : '',
    expired: typeof row.isExpired != 'undefined' && row.isExpired.length > 0,
    expireSoon: typeof row.willExpireWithin30Days != 'undefined' && row.willExpireWithin30Days.length > 0,
  };
};

export const transform = (rights: AccessMgtDataDTO): Row[] => {
  if (rights === null || rights.accessRows === null) {
    return [];
  }
  //The names here are used in the backend for sorting so they should not be changed
  return rights.accessRows.map(right => ({
    'aclId': right.accessControlId,
    'userId': right.userId,
    'userFirstname': right.firstName,
    'userLastname': right.lastName,
    'siteId': right.machineTemplate.siteId,
    'resourcePath': right.humanReadablePath,
    'permissionAuthority': right.authority,
    'expirationDate': right.expirationDate.toString(),
    'machineTemplateId': right.machineTemplate.id,
    'lastModifiedDate': right.lastModifiedDate,
    'lastModifiedBy': right.lastModifiedBy,
    'isExpired': '', //Only for filter
    'willExpireWithin30Days': '', //Only for filter
  }));
};

//export for tests
export const isConnectedUserManager = (resourcePath: string, siteList: ResourceAccessNode[]) => {
  const resource = resourcePathService.getResource({ sites: siteList }, resourcePath);
  return resource ? resource.authority === MANAGER : false;
};

//export for tests
export const canEditStatusOrDeleteAccess = (rowData: Row, siteList: ResourceAccessNode[], loggedUserId: string) => {
  return isConnectedUserManager(rowData.resourcePath, siteList) && loggedUserId !== rowData.userId;
};