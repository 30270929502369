import fileService from 'services/file-service';
import { AppThunk } from 'store/reducers';
import { PaginatedFilesDTO } from 'types';

export const RECEIVE_PROJECT_FILES = 'RECEIVE_PROJECT_FILES';
export const CLEAR_PROJECT_FILES = 'CLEAR_PROJECT_FILES';

export type ReceiveProjectFilesAction = {
  type: typeof RECEIVE_PROJECT_FILES,
  projectFiles: PaginatedFilesDTO,
};

export type ClearProjectFilesAction = {
  type: typeof CLEAR_PROJECT_FILES,
};

export const receiveProjectFilesAction = (projectFiles: PaginatedFilesDTO): ReceiveProjectFilesAction => ({
  type: RECEIVE_PROJECT_FILES,
  projectFiles,
});
export const clearProjectFilesAction = (): ClearProjectFilesAction => ({ type: CLEAR_PROJECT_FILES });

export const getProjectFilesAction = (
  siteId: number | undefined,
  projectId: number | undefined,
  continuationToken : string | null,
  lastItemIndex : number, pageSize : number
): AppThunk<Promise<void>> => async (dispatch) => {
  fileService.getFiles(siteId, projectId, continuationToken, lastItemIndex, pageSize).then(projectFiles => dispatch(receiveProjectFilesAction(projectFiles)));
};

export type FileDownloadActionType = ReceiveProjectFilesAction | ClearProjectFilesAction;