import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, TextField, DialogTitle, Grid } from '@mui/material';
import SessionIcon from '@mui/icons-material/OndemandVideo';
import Loader from 'components/loader/loader';
import { updateVMResolution } from 'store/actions/vm-instance-actions';
import { useAppDispatch } from 'store';
import { DcvResolution, VmDTO } from 'types';
import styles from './vm-edit-resolution.module.scss';

type Props = {
  children: React.ReactNode,
  vmInstance: VmDTO,
  onUpdate: (width: number, hieght: number) => void
};

const CustomResolutionVm = ({ vmInstance, children, onUpdate }: Props) => {

  const [showModal, setShowModal] = useState(false);
  const [width, setWidth] = useState(vmInstance.resolution.width);
  const [height, setHeight] = useState(vmInstance.resolution.height);
  const [waitingExpandingLifeTime, setWaitingExpandingLifeTime] = useState(false);
  const dispatch = useAppDispatch();

  const handleClickOpen = event => {
    setShowModal(true);
    event.stopPropagation();
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const handleCloseWithEvent = event => {
    setShowModal(false);
    event.stopPropagation();
  };

  const handleWidthChange = event => {
    setWidth(event.target.value);
  };

  const handleHeightChange = event => {
    setHeight(event.target.value);
  };

  const updateVM = () => {
    const resolution: DcvResolution = {
      width,
      height,
    };

    setWaitingExpandingLifeTime(true);
    return dispatch(updateVMResolution(vmInstance.sessionId, resolution))
      .then(() => {
        setTimeout(() => setWaitingExpandingLifeTime(false), 5000);
      })
      .catch(() => setWaitingExpandingLifeTime(false))
      .finally(() => {
        /** Update parent component (for tooltip display) */
        if (onUpdate) {
          onUpdate(height, width);
        }
        /** Close popup */
        handleClose();
      });
  };


  return (
    <div onClick={handleClickOpen}>
      <div>{children}</div>
      <Dialog open={showModal} onClose={handleCloseWithEvent} fullWidth maxWidth='sm' aria-labelledby='alert-dialog-title' aria-describedby='alert-dialog-description'>
        <DialogTitle className={styles.dialogTitle}>
          <SessionIcon className={styles.icon}/>
          <div className={styles.customRunTitle}>Modify resolution</div>
        </DialogTitle>

        <DialogContent>
          <DialogContentText>
            <b>VM</b>: {vmInstance.name}
          </DialogContentText>
          <TextField id='vm-width' label='Width (px) - 640 / 4096' margin='normal' variant='outlined' multiline={false} fullWidth defaultValue={width} onChange={handleWidthChange}/>
          <TextField id='vm-height' label='Height (px) - 480 / 2160' margin='normal' variant='outlined' multiline={false} fullWidth defaultValue={height} onChange={handleHeightChange}/>
        </DialogContent>
        <DialogActions>
          <Grid container alignItems='stretch' direction='row' justifyContent='flex-end' className={styles.accessAttributionButtonGrid}>
            <Grid item>
              <Button onClick={handleCloseWithEvent} className={styles.cancelButton}>
                Cancel
              </Button>
            </Grid>
            <Grid item>
              <Button onClick={updateVM} variant='contained' color='primary' className={styles.validationButton} disabled={waitingExpandingLifeTime}>
                {waitingExpandingLifeTime ? <Loader className={styles.loaderSave}/> : null}
                Save
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CustomResolutionVm;
