import { SHOW_MODAL, ModalActionType } from 'store/actions/modal-actions';
import { ReactNode } from 'react';


type ModalState = {
  component: ReactNode | undefined,
};

const initialState: ModalState = {
  component: undefined,
};

const modalReducer = (state = initialState, action: ModalActionType): ModalState => {
  switch (action.type) {
    case SHOW_MODAL:
      return {
        ...state,
        component: action.component,
      };
    default:
      return state;
  }
};

export default modalReducer;