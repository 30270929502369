import React, { createRef, Ref, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Tooltip } from '@mui/material';
import SharingIcon from '@mui/icons-material/Share';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import Loader from 'components/loader/loader';
import UsersSelectorInput, { ValidateInput } from 'components/user/users-selector-input';
import ContributorList from './vm-contributor-list';
import { TOOLTIP_DELAY, TOOLTIP_LEAVE_DELAY } from 'constants/config';
import { VmDTO } from 'types';

import './vm-component-share.scss';

import {
  openVmShareModal,
  closeVmShareModal,
  getVMInstanceContributorsAction,
  deleteVmInstanceContributorAction,
  deleteVmInstanceSharedEmailAction,
  setContributorIdsToAddAction,
  updateVMContributorsAction
} from 'store/actions/vm-instance-contributors-actions';

import './vm-contributors.scss';
import { RootState } from 'store/reducers';
import { bindActionCreators } from 'redux';
import { useAppDispatch } from 'store';

const mapState = (state: RootState) => ({
  selectedVM: state.vmInstanceContributorsReducer.selectedVM,
  isFetchingContributors: state.vmInstanceContributorsReducer.isFetching,
  contributorList: state.vmInstanceContributorsReducer.contributorList.filter(contributor => state.vmInstanceContributorsReducer.contributorIdsToDelete.indexOf(contributor.id) === -1),
  sharedEmailList: state.vmInstanceContributorsReducer.selectedVM?.sharedEmails
    ? state.vmInstanceContributorsReducer.selectedVM.sharedEmails.split(',').filter(email => state.vmInstanceContributorsReducer.sharedEmailsToDelete.indexOf(email) === -1)
    : [],
  contributorIdsToAdd: state.vmInstanceContributorsReducer.contributorIdsToAdd,
  contributorIdsToDelete: state.vmInstanceContributorsReducer.contributorIdsToDelete,
  sharedEmailsToAdd: state.vmInstanceContributorsReducer.sharedEmailsToAdd,
  sharedEmailsToDelete: state.vmInstanceContributorsReducer.sharedEmailsToDelete,
});

const mapDispatch = (dispatch) => bindActionCreators(
  {
    openVmShareModal,
    closeVmShareModal,
    deleteVmInstanceContributorAction,
    deleteVmInstanceSharedEmailAction,
    setContributorIdsToAddAction,
  },
  dispatch
);

const connector = connect(mapState, mapDispatch);

type ReduxProps = ConnectedProps<typeof connector>;

type OwnProps = {
  vmInstance: VmDTO,
  contributorIds: string
};

type Props = ReduxProps & OwnProps;

const VmComponentShare = ({
  vmInstance,
  selectedVM,
  contributorIds,
  isFetchingContributors,
  contributorList,
  sharedEmailList,
  contributorIdsToAdd,
  contributorIdsToDelete,
  sharedEmailsToAdd,
  sharedEmailsToDelete,
  openVmShareModal,
  closeVmShareModal,
  deleteVmInstanceContributorAction,
  deleteVmInstanceSharedEmailAction,
  setContributorIdsToAddAction,
}: Props) => {
  const [pending, setPending] = useState(false);
  const dispatch = useAppDispatch();
  const [userInput, setUserInput] = useState('');
  const usersSelectorInputRef: Ref<ValidateInput> = createRef();

  const openModal = () => {
    openVmShareModal(vmInstance);
    dispatch(getVMInstanceContributorsAction(contributorIds));
  };

  const handleUsersSelectorInputChange = inputFieldValue => setContributorIdsToAddAction(inputFieldValue);
  const updateVMContributors = () => {
    // Validate current input in component before sendind request
    const isValidInput = usersSelectorInputRef.current?.validateInput();
    if (isValidInput) {
      setPending(true);
      dispatch(updateVMContributorsAction()).then(() => setPending(false)).catch(() => setPending(false));
    }
  };
  const handleUserInputChange = (value) => {
    setUserInput(value);
  };

  const formatTooltipMessage = (cIds: string, sharedEmails: string) => {
    let msg = 'Shared with ';
    const sharedEmailsList = sharedEmails ? sharedEmails.split(',') : [];
    const contributorIdsList = cIds ? cIds.split(',') : [];
    const allSharedUsers = [...sharedEmailsList, ...contributorIdsList].filter(el => !!el);

    msg += allSharedUsers.pop();

    if (allSharedUsers.length > 0) {
      msg += ` and ${allSharedUsers.length} other persons`;
    }

    return msg;
  };

  return (
    <div>
      <div className='instancesharing__icons'>
        {(vmInstance.contributorIds && vmInstance.contributorIds.length > 0) || (vmInstance.sharedEmails && vmInstance.sharedEmails.length > 0) ? (
          <Tooltip title={formatTooltipMessage(vmInstance.contributorIds, vmInstance.sharedEmails)} enterDelay={TOOLTIP_DELAY} enterNextDelay={TOOLTIP_DELAY} leaveDelay={TOOLTIP_LEAVE_DELAY}>
            <IconButton onClick={openModal} key={vmInstance.name} className='instancesharing__owned'>
              <GroupAddIcon className='instancesharing__alreadyShared' />
            </IconButton>
          </Tooltip>
        ) : (
            <Tooltip title='Give access to someone else' enterDelay={TOOLTIP_DELAY} enterNextDelay={TOOLTIP_DELAY} leaveDelay={TOOLTIP_LEAVE_DELAY}>
              <IconButton onClick={openModal} key={vmInstance.name} className='instancesharing__owned'>
                <PersonAddIcon />
              </IconButton>
            </Tooltip>
        )}
      </div>
      <Dialog classes={{ paper: 'dialog-container' }} open={vmInstance.name === selectedVM?.name} maxWidth='md' onClose={closeVmShareModal} className='dialog'>
        <DialogTitle>
          <div className='dialogTitle'>
            <SharingIcon className='icon' />
            <div className='modalTitle'>Share session</div>
          </div>
          <div className='instancesharing-name'>{vmInstance.name || ''}</div>
        </DialogTitle>
        <DialogContent classes={{ root: 'overflow-y-visible' }}>
          <div className='invite-users-label'>Invite users</div>
          <div className='invite-users-info'>Please note that adding, removing or just re-sending the invite will deactivate previously sent invitations.</div>
          <UsersSelectorInput ref={usersSelectorInputRef} onChange={handleUsersSelectorInputChange} onInputChange={handleUserInputChange} placeholder='User Name or Email' />
          {(isFetchingContributors || contributorList.length > 0 || sharedEmailList.length > 0) && (
            <ContributorList
              contributorList={contributorList}
              sharedEmailList={sharedEmailList}
              isFetchingContributors={isFetchingContributors}
              onDeleteContributor={deleteVmInstanceContributorAction}
              onDeleteSharedEmail={deleteVmInstanceSharedEmailAction}/>
          )}
        </DialogContent>
        <DialogActions>
          <Grid container alignItems='stretch' direction='row' justifyContent='flex-end' className='dialog-actions'>
            <Grid item>
              <Button onClick={closeVmShareModal} className='cancel-button' sx={{ color: 'black' }}>
                Cancel
              </Button>
            </Grid>
            <Grid item>
              <Button
                onClick={updateVMContributors}
                variant='contained'
                color='primary'
                className='validation-button'
                disabled={pending || (contributorIdsToAdd.length === 0 && contributorIdsToDelete.length === 0 
                                      && sharedEmailsToAdd.length === 0 && sharedEmailsToDelete.length === 0
                                      && !userInput)}>
                {pending ? <Loader className='loaderUpdate' /> : 'VALIDATE'}
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </div>
  );
};


export default connector(VmComponentShare);
