import React, { useState } from 'react';
import { NativeSelect } from '@mui/material';
import styles from './os-selection-styles.module.scss';

const windowsOs = 'Windows';
const linuxOs = 'Linux';
const macOs = 'MacOs';
const defaultOs = getOperatingSystem(window);

export let selectedOsValue = defaultOs;
export let selectedProxyValue = '';

function getOperatingSystem(window) {
  let operatingSystem = '';
  if (window.navigator.appVersion.indexOf('Win') !== -1) {
    operatingSystem = windowsOs;
  }
  if (window.navigator.appVersion.indexOf('Linux') !== -1) {
    operatingSystem = linuxOs;
  }
  if (window.navigator.appVersion.indexOf('Mac') !== -1) {
    operatingSystem = macOs;
  }

  return operatingSystem;
}

export { windowsOs };

const OsSelection: React.FC = () => {

  const [selectedOs, setSelectedOs] = useState(defaultOs);
  selectedOsValue = selectedOs;

  const [selectedProxy, setSelectedProxy] = useState('');
  selectedProxyValue = selectedProxy;

  const osList = [
    { label: windowsOs, value: windowsOs },
    { label: linuxOs, value: linuxOs },
    { label: macOs, value: macOs },
  ];

  const linuxProxyList = [
      { label: 'none', value: '' },
      { label: 'FR HQ-On-premise', value: 'http://azpfrceF5pxy-prod.corp.local:8080' },
      { label: 'Brazil 1-On-premise (cloudproxy-amas)', value: 'http://cloudproxy-amas.corp.local:8080' },
      { label: 'Brazil 2-On-premise (10.16.152.93)', value: 'http://10.16.152.93:8080' },
    ];
    const windowsProxyList = [
      { label: 'none', value: '' },
      { label: 'FR HQ-On-premise', value: 'http://azpfrceF5pxy-prod.corp.local:8080/' },
      { label: 'Brazil 1-On-premise (cloudproxy-amas)', value: 'http://cloudproxy-amas.corp.local:8080' },
      { label: 'Brazil 2-On-premise (10.16.152.93)', value: 'http://10.16.152.93:8080' },
    ];
    const macOsProxyList = [
      { label: 'none', value: '' },
      { label: 'FR HQ-On-premise', value: 'http://azpfrceF5pxy-prod.corp.local:8080/' },
      { label: 'Brazil 1-On-premise (cloudproxy-amas)', value: 'http://cloudproxy-amas.corp.local:8080' },
      { label: 'Brazil 2-On-premise (10.16.152.93)', value: 'http://10.16.152.93:8080' },
    ];

  const handleSelectOsChange = e => {
    setSelectedOs(e.target.value);
    e.stopPropagation();
  };

  const handleProxyChange = e => {
      setSelectedProxy(e.target.value);
      e.stopPropagation();
    };

  return (
    <>
      <p>OS and proxy selection:</p>
      <div className='row' style={{ display: 'flex' }}>
        <div className='column' style={{ flex: '50%' }}>
          <NativeSelect
            className={styles.selectProxy}
            value={selectedOs}
            onChange={handleSelectOsChange}
            defaultValue={{ label: defaultOs, value: defaultOs }}
          >
            {osList.map((os) => (
              <option key={os.label} value={os.value}>
                {os.label}
              </option>
            ))}
          </NativeSelect>
        </div>
        <div className='column' style={{ flex: '50%' }}>
          <NativeSelect
            className={styles.selectProxy}
            value={selectedProxy}
            onChange={handleProxyChange}
          >
            {(selectedOs === windowsOs ? windowsProxyList : selectedOs === macOs ? macOsProxyList : linuxProxyList).map((proxy) => (
              <option key={proxy.label} value={proxy.value}>
                {proxy.label}
              </option>
            ))}
          </NativeSelect>
        </div>
      </div>
    </>
  );
};

export default OsSelection;