import SessionIcon from '@mui/icons-material/OndemandVideo';
import ManageUsersIcon from '@mui/icons-material/People';
import SiteIcon from '@mui/icons-material/Public';
import SearchIcon from '@mui/icons-material/Search';
import { Avatar, Button, Divider, IconButton, InputAdornment, List, ListItem, TextField } from '@mui/material';
import { ReactElement, useState } from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { Link } from 'react-router-dom';

import Loader from 'components/loader/loader';
import ResizableDiv from 'components/shared/resizable-div/resizable-div';
import { MANAGER } from 'constants/user-authority';
import 'flag-icon-css/css/flag-icon.min.css';
import { RootState } from 'store/reducers';
import { ResourceAccessNode } from 'types';
import Site from './menu-site';

import styles from './sidebar.module.scss';

const sidebarStyle = {
  backgroundColor: '#2a3b5a',
  zIndex: 1101,
  position: 'fixed',
  height: '100%',
};

const mapState = (state: RootState) => {
  const vmInstanceList = state.vmInstancesReducer.sessions;

  return (
    {
      siteList: state.resourcesReducer.siteList,
      isFetchingSiteList: state.resourcesReducer.isFetching,
      nbCurrentUserInstances: vmInstanceList.length,
    });
};

const connector = connect(mapState);

type ReduxProps = ConnectedProps<typeof connector>;

type Props = ReduxProps ;

const Sidebar = ({ siteList, isFetchingSiteList, nbCurrentUserInstances }: Props): ReactElement => {

  const [searchSiteList, setSearchSiteList] = useState<ResourceAccessNode[] | undefined>(undefined);

  const preSearchResource = (event) => {
    const searchWord = event.target.value.toLowerCase();
    return searchResourceTree(searchWord);
  };

  const searchResource = (resource: ResourceAccessNode, searchWord: string): ResourceAccessNode | null => {
    const matches = resource.resourceType === 'SITE' ? resource.label.toLowerCase().includes(searchWord) : resource.name.toLowerCase().includes(searchWord);
    const children = resource.children
      .map(child => searchResource(child, searchWord))
      .filter(child => child !== null) as ResourceAccessNode[];

    if (matches || children.length > 0) {
      return { ...resource, children: matches ? resource.children : children, expanded: !matches }; // Expand only up to the match
    }
    return null;
  };

  const searchResourceTree = (searchWord: string) => {
    if (searchWord.length === 0) {
      setSearchSiteList(siteList);
      return;
    }
    const searchSiteListResult = siteList.map(site => searchResource(site, searchWord)).filter(site => site !== null) as ResourceAccessNode[];
    setSearchSiteList(searchSiteListResult);
  };

  const siteListToDisplay = searchSiteList ? searchSiteList : siteList;

  const links = [
    {
      display: true,
      to: '/',
      label: 'Sessions',
      icon: <SessionIcon/>,
      id: 'sessionsLink',
      nbSessions: true,
      divider: true,
    },
    {
      display: true,
      to: '/manage-users',
      label: 'Rights',
      icon: <ManageUsersIcon/>,
      id: 'manageUsersLink',
    },
    {
      display: siteList.length > 0 && isSiteManager(siteList),
      to: '/manage-sites',
      label: 'Sites',
      icon: <SiteIcon/>,
      id: 'manageSitesLink',
    },
  ];

  return (
    <ResizableDiv style={sidebarStyle} minWidth={400}>
      <div className={styles.sidebar}>
        <Sidebar.Title style={styles} />

        <Divider className={styles.sidebar__divider} />

        {siteList.length > 0 && <Sidebar.Search preSearchResource={preSearchResource} style={styles} />}

        {isFetchingSiteList ? (
          <Loader className={styles.project_list_loader} />
        ) : (
          <List className={styles.sidebar__site_list}>
            {siteListToDisplay
              .sort((s1, s2) => s1.label.localeCompare(s2.label))
              .map(site => (
                <Site key={site.resourceId} site={site}/>
              ))}
          </List>
        )}

        <Divider className={styles.sidebar__divider} />

        {links
          .filter(link => link.display)
          .map(link => (
            <div key={link.label + '-parent'}>
              <div key={link.label}>
                <Link to={link.to} className={styles.sidebar__link}>
                  <Button className={styles.sidebar__link__button_link} id={link.id}>
                    {link.icon}
                    <span className={styles.sidebar__link__button_link__link_label}>{link.label}</span>
                    {link.nbSessions ? <Avatar className={styles.sidebar__link__button_link__sessions_number}>{nbCurrentUserInstances}</Avatar> : null}
                  </Button>
                </Link>
              </div>
              {link.divider ? <Divider className={styles.sidebar__divider} /> : null}
            </div>
          ))
        }
      </div>
    </ResizableDiv>
  );
};


Sidebar.Title = ({ style }) => (
  <Link to='/' className={style.sidebar__titleLink}>
    <div className={style.sidebar__titleLink__titleVersionContainer}>
      <div className={style.sidebar__titleLink__sismageTitle}>SISMAGE-SaaS</div>
      <div className={style.sidebar__titleLink__sismageVersion}>{`v${process.env.REACT_APP_VERSION}`}</div>
    </div>
  </Link>
);

Sidebar.Search = ({ preSearchResource, style }) => (
  <ListItem>
    <TextField
      id='standard-name'
      data-testid='searchbarTestId'
      label='Search...'
      className={style.sidebar__inputSearch}
      margin='normal'
      variant='standard'
      autoComplete='off'
      InputLabelProps={{
        classes: {
          root: style.sidebar__inputSearch__inputSearchLabel,
          focused: style.sidebar__inputSearch__inputSearchFocused,
          outlined: style.sidebar__inputSearch__inputSearchLabel,
        },
      }}
      InputProps={{
        classes: {
          root: style.sidebar__inputSearch__input,
          underline: style.sidebar__inputSearch__inputSearchUnderline,
        },
        endAdornment: (
          <InputAdornment position='end'>
            <IconButton>
              <SearchIcon className={style.sidebar__inputSearch__searchIcon}/>
            </IconButton>
          </InputAdornment>
        ),
      }}
      onChange={preSearchResource}/>
  </ListItem>
);

export default connector(Sidebar);

//Export for unit tests
export const isSiteOrZoneOrProjectManager = (siteList: ResourceAccessNode[]) => {
  return (
    siteList.some(site => site.authority === MANAGER) ||
    siteList.some(site => site.children.some(zone => zone.authority === MANAGER)) ||
    siteList.some(site => site.children.some(zone => zone.children.some(project => project.authority === MANAGER)))
  );
};

//Export for unit tests
export const isSiteOrZoneManager = (siteList: ResourceAccessNode[]) => {
  return siteList.some(site => site.authority === MANAGER) || siteList.some(site => site.children.some(zone => zone.authority === MANAGER));
};

//Export for unit tests
export const isSiteManager = (siteList: ResourceAccessNode[]) => {
  return siteList.some(site => site.authority === MANAGER);
};