import DeleteUserIcon from '@mui/icons-material/Close';
import AddedUserIcon from '@mui/icons-material/Person';
import { Chip, FormControl, Input } from '@mui/material';
import { ReactNode, forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';

import Loader from 'components/loader/loader';

import cx from 'classnames';

import userService from 'services/user-service';

import './user-selector-input.scss';

import styles from './users-selector-input.module.scss';

import { EMAIL_REGEX, IGG_REGEX } from 'constants/config';
import { UserDTO } from 'types';

export const defaultChipRenderer = ({
  text, handleClick, handleDelete, className, iconClassName,
}, key) => (
  <Chip
    icon={<AddedUserIcon className={iconClassName}/>}
    key={key}
    className={className}
    onClick={handleClick}
    onDelete={handleDelete}
    label={text}
    deleteIcon={<DeleteUserIcon className={iconClassName}/>}/>
);

type OwnProps = {
  value?: string,
  newChipKeyCodes?: string[],
  onInputChange?: (value) => void,
  onChange: (values: string[]) => void,
  emailOnly?: boolean,
  iggOnly?: boolean,
  placeholder?: string,
  disableUnderline?: boolean,
  inputProps?: { [key: string]: string | boolean }
  chipRenderer?: (any, key) => ReactNode
};

type Props = OwnProps;
export type ValidateInput = {
  validateInput: () => boolean,
};

const useDebouncedValue = (inputValue, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(inputValue);
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(inputValue);
    }, delay);
    return () => {
      clearTimeout(handler);
    };
  }, [inputValue, delay]);
  return debouncedValue;
};

const UsersSelectorInput = forwardRef<ValidateInput, Props>((props, ref) => {

  const [chips, setChips] = useState<string[]>([]);
  const [inputValue, setInputValue] = useState('');
  const debouncedInputValue = useDebouncedValue(inputValue, 300);// Not sure if 300ms is a good delay
  // It seems too short when typing an email address, especially around '.' and '@' characters
  // But a longer delay might feel laggy in the portal
  const [error, setError] = useState<string | null>(null);
  const [usersAutocomplete, setUsersAutocomplete] = useState<UserDTO[]>([]);
  const [isFetching, setIsFetching] = useState(false);

  const autocompleteRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    document.addEventListener('click', handleClickOutsideAutocomplete);
    return () => document.removeEventListener('click', handleClickOutsideAutocomplete);
  }, []);

  useEffect(() => {
    userService.searchUsers(debouncedInputValue)
      .then((users = []) => users.filter(user => chips.indexOf(user.id) === -1))
      .then(users => {
        setUsersAutocomplete(users);
        setIsFetching(false);
      })
      .catch(() => {
        setIsFetching(false);
      });
  }, [debouncedInputValue]);

  const handleClickOutsideAutocomplete = (event) => {
    if (autocompleteRef.current && !autocompleteRef.current.contains(event.target) && inputValue) {
      addChip(inputValue);
    }
  };

  const handleAddChipWithKeyPress = (event) => {
    if (props.newChipKeyCodes?.indexOf(event.key) !== -1) {
      addChip(event.target.value);
    }
  };

  const addChip = (chip) : boolean => {
    let withoutError = false;
    updateInput('');
    setUsersAutocomplete([]);

    const chipTrim = chip.trim();
    const isEmail = EMAIL_REGEX.test(chipTrim);
    const isIGG = IGG_REGEX.test(chipTrim);
    const chipTrimUp = isEmail ? chipTrim : chipTrim.toUpperCase();
    if (chipTrim.length === 0) {
      setError(null);
      withoutError = true;
    } else if (props.emailOnly && !isEmail) {
      setError(chipTrim + ' : Wrong email format');
    } else if (props.iggOnly && !isIGG) {
      setError(chipTrim + ' : Wrong format for username (J/L0212121 expected)');
    } else if (chips.includes(chipTrim)) {
      setError('Username ' + chipTrim + ' already exists');
    } else if (!isIGG && !isEmail) {
      setError(chipTrim + ' : Wrong format for username (J/L0212121 expected)');
    } else {
      const c = [...chips, chipTrimUp];
      setChips(c);
      setError(null);
      props.onChange(c);
      withoutError = true;
    }

    return withoutError;
  };

  const handleDeleteChip = (chip, i) => {
    const c = [...chips];
    const changed = c.splice(i, 1);
    if (changed) {
      setChips(c);
      props.onChange(changed);
    }
  };

  const handleUpdateInput = (event) => {
    if (event && event.target) {
      updateInput(event.target.value);
    }
  };

  const updateInput = (v) => {
    setInputValue(v);
    updateAutocomplete(v);
    if (props.onInputChange) props.onInputChange(v);
  };

  const updateAutocomplete = (v) => {
    if (!v) {
      setUsersAutocomplete([]);
      return;
    }
    setIsFetching(true);
    //User search is now handled by useEffect with debouncedInputValue
  };

  const displayUserAutomplete = (user) => {
    return `${user.id} ${user.firstname && user.lastname ? `(${user.firstname} ${user.lastname})` : ''}`;
  };

  const _chips = props.value ? [props.value] : [...chips];
  const chipComponents = _chips.map((tag, i) => {
    if (props.chipRenderer) {
      return props.chipRenderer(
        {
          tag,
          text: tag,
          chip: tag,
          handleDelete: () => handleDeleteChip(tag, i),
          className: styles.chip,
          iconClassName: styles.icon,
        },
        i
      );
    }
  });


  useImperativeHandle(ref, () => ({
    validateInput(): boolean {
      const inputValueTrim = inputValue ? inputValue.trim() : undefined;
      if (inputValueTrim && inputValueTrim.length > 0) {
        return addChip(inputValueTrim);
      }
      return true;
    },
  }), []);

  if (props.inputProps) {
    // eslint-disable-next-line no-param-reassign
    props.inputProps.disableUnderline = true;
  }
  
  return (
    <FormControl fullWidth style={{ zIndex: 99 }}>
      <div className='autocomplete' ref={autocompleteRef}>
        <div
          className={cx(styles.chipContainer, {
            [styles.inkbar]: !props.disableUnderline,
          })}>
          {chipComponents}
          <Input
            id='users-selector-input'
            classes={{
              input: cx(styles.input), // TODO, classes[variant]),
              root: cx(styles.inputRoot), // TODO, classes[variant]),
            }}
            value={inputValue}
            onChange={handleUpdateInput}
            onKeyDown={handleAddChipWithKeyPress}
            {...props.inputProps}
            placeholder={props.placeholder}
            autoComplete='off'/>
          {!props.emailOnly && isFetching && <Loader className='autocomplete-loader'/>}
        </div>
        {!props.emailOnly && usersAutocomplete.length > 0 && (
          <div className='autocomplete-items'>
            {usersAutocomplete.map(user => (
              <div key={user.id} onClick={() => addChip(user.id)}>
                {displayUserAutomplete(user)}
              </div>
            ))}
          </div>
        )}
      </div>
      {error && (
        <div id='error' className={styles.errorMessage}>
          {error}
        </div>
      )}
    </FormControl>
  );
});

UsersSelectorInput.defaultProps = {
  disableUnderline: false,
  newChipKeyCodes: ['Enter', ' '],
  placeholder: 'User Name or IGG (prefer IGG for user first connection)',
  emailOnly: false,
  iggOnly: false,
  inputProps: {},
  chipRenderer: defaultChipRenderer,
};

export default UsersSelectorInput;
