import {
  RECEIVE_USER_BILLING_LIGHT,
  UserBillingActionType
} from 'store/actions/user-billing-actions';

type UserBillingState = {
  isFetching: boolean,
  totalCostMonth: number,
  currencyCode: string,
};

const initialState: UserBillingState = {
  isFetching: false,
  totalCostMonth: 0,
  currencyCode: 'USD',
};

const billingReducer = (state = initialState, action: UserBillingActionType): UserBillingState => {
  switch (action.type) {
    case RECEIVE_USER_BILLING_LIGHT:
      return {
        ...state,
        totalCostMonth: action.userCost.totalMonthlyCost,
        currencyCode: action.userCost.currencyCode,
      };
    default:
      return {
        ...state,
      };
  }
};

export default billingReducer;