import { ReactNode, Ref, createRef, useEffect, useState } from 'react';
import { ConnectedProps, connect } from 'react-redux';

import DatePickerIcon from '@mui/icons-material/CalendarToday';
import AddUsersIcon from '@mui/icons-material/GroupAdd';
import HelpIcon from '@mui/icons-material/HelpOutline';
import KeyBoardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyBoardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, Input, InputLabel, NativeSelect, Tooltip } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';

import Loader from 'components/loader/loader';
import { MANAGER, USER } from 'constants/user-authority';
import { DateTime } from 'luxon';
import resourcePathService from 'services/resource-path-service';
import vmInstanceService from 'services/vm-instance-service';
import { useAppDispatch } from 'store';
import { addMachineTemplates } from 'store/actions/machine-template-actions';
import { addUser } from 'store/actions/manage-rights-actions';
import { RootState } from 'store/reducers';
import { getSiteAvailableMachineTemplatesFromState } from 'store/selectors/machine-template-selectors';
import { AvailableMachineTemplateDTO, ResourceAccessNode } from 'types';
import styles from './add-users.module.scss';
import ResourcePathSelector from './resource-path-selector';
import UsersSelectorInput, { ValidateInput } from './users-selector-input';

type SelectedResource = {
  site: ResourceAccessNode,
  zone?: ResourceAccessNode,
  project?: ResourceAccessNode
};

const initResourcePath = (selectedResource?: SelectedResource) => {
  if (selectedResource) {
    if (selectedResource.project) {
      return selectedResource.project.resourcePath;
    } else if (selectedResource.zone) {
      return selectedResource.zone.resourcePath;
    } else {
      return selectedResource.site.resourcePath;
    }
  }
  return '';
};

const mapState = (state: RootState) => ({
  siteList: state.resourcesReducer.siteList,
  getSiteAvailableMachineTemplatesFromState: (siteId) => getSiteAvailableMachineTemplatesFromState(state, siteId),
});

const connector = connect(mapState);

type ReduxProps = ConnectedProps<typeof connector>;

type OwnProps = {
  children: ReactNode | null;
  selectedResource?: SelectedResource;
  onClose?: () => void;
  callBack?: () => void;
  withMenuStyle: boolean;
};

type Props = ReduxProps & OwnProps;

const AddUsers = ({
  selectedResource, siteList, onClose, callBack, getSiteAvailableMachineTemplatesFromState, children, withMenuStyle,
}: Props) => {
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [userIdList, setUserIdList] = useState<string[]>([]);
  const [userIdInput, setUserIdInput] = useState(''); // Hack to add userId in input if validate clicked and chip is not in chip list
  const [status, setStatus] = useState(USER);
  const [machineTemplateId, setMachineTemplateId] = useState(0);
  const [availableMachineTemplates, setAvailableMachineTemplates] = useState<AvailableMachineTemplateDTO[]>([]);
  const [expirationDate, setExpirationDate] = useState(DateTime.local().plus({ months: 3 }));
  const [resourcePath, setResourcePath] = useState(initResourcePath(selectedResource));

  const usersSelectorInputRef: Ref<ValidateInput> = createRef();

  useEffect(() => {
    const siteId = resourcePathService.getSiteIdFromResourcePath(resourcePath);
    if (siteId !== -1) {
      const availableMachineTemplates = getSiteAvailableMachineTemplatesFromState(siteId);
      if (!availableMachineTemplates) {
        vmInstanceService.getMachineTemplates(siteId).then((availableMachineTemplates) => {
          const result = { [siteId]: availableMachineTemplates };
          updateMachineTemplates(availableMachineTemplates);
          dispatch(addMachineTemplates(result));
        });
      }
      updateMachineTemplates(availableMachineTemplates);
    }
  }, [resourcePath]);

  const openModal = (event) => {
    setOpen(true);
    event.stopPropagation();
  };

  const handleClose = (event?) => {
    if (event) {
      event.stopPropagation();
    }
    setOpen(false);
    setUserIdList([]);
    setResourcePath('');
    setExpirationDate(DateTime.local().plus({ months: 3 }));
    setStatus(USER);
    setMachineTemplateId(0);

    if (onClose) {
      onClose();
    }
  };

  const updateMachineTemplatesWithSiteId = (siteId: number) => {
    const templates = getSiteAvailableMachineTemplatesFromState(siteId);
    updateMachineTemplates(templates);
  };

  const updateMachineTemplates = (templates: AvailableMachineTemplateDTO[]) => {
    setAvailableMachineTemplates(templates);
    setMachineTemplateId(templates && templates.length > 0 ? templates[0].id : 0);
  };

  const handleResourcePathChange = (humanReadablePath) => {
    setResourcePath(resourcePathService.getResourcePathFromHumanReadablePath(siteList, humanReadablePath));
    const siteId = resourcePathService.getSiteIdFromResourcePath(resourcePath);
    const selectedSite = siteList.find(site => site.resourceId === siteId);
    updateMachineTemplatesWithSiteId(selectedSite ? selectedSite.resourceId : 0); // Here we keep the 0 as siteId in case of undefined site
  };

  const handleStatusChange = (event) => {
    const { value } = event.target;
    setStatus(value);
  };

  const handleMachineTemplateChange = (event) => {
    const { value } = event.target;
    setMachineTemplateId(value);
  };

  const handleUserIdListChange = (inputFieldValue) => {
    setUserIdList(inputFieldValue);
  };

  const handleUserIdInputChange = (value) => {
    setUserIdInput(value);
  };

  const handleDateChange = (date) => {
    setExpirationDate(date);
  };

  const grantUserCallback = () => {
    setIsLoading(false);
    if (onClose) {
      onClose();
    }
    handleClose();
    if (callBack) {
      callBack();
    }
  };

  const createAccessControl = () => {
    // Validate current input in component before sendind request
    const isValidInput = usersSelectorInputRef.current?.validateInput();
    if (isValidInput && resourcePath) {
      setIsLoading(true);
      const completeUserIdList = userIdList.map(applicant => applicant.toUpperCase());
      const userIdInputTrim = userIdInput ? userIdInput.trim() : undefined;
      if (userIdInputTrim && userIdInputTrim.length > 0) {
        completeUserIdList.push(userIdInputTrim.toUpperCase());
      }
      const createAccessControlRequestBody = {
        resourcePath: resourcePath,
        applicants: completeUserIdList,
        authority: status,
        machineTemplateId: machineTemplateId,
        expirationDate: DateTime.fromISO(expirationDate).toFormat('dd/LL/yyyy'),
      };
      dispatch(addUser(createAccessControlRequestBody)).finally(() => grantUserCallback());
    }
  };

  const getAuthorityErrorMessage = () => {
    const selectedSiteId = resourcePathService.getSiteIdFromResourcePath(resourcePath);
    const selectedZoneId = resourcePathService.getZoneIdFromResourcePath(resourcePath);
    const selectedProjectId = resourcePathService.getProjectIdFromResourcePath(resourcePath);

    if (selectedProjectId !== -1) {
      return '';
    }

    if (selectedZoneId !== -1) {
      const selectedZone = resourcePathService.getZone(siteList, selectedZoneId);
      if (selectedZone && selectedZone.authority !== MANAGER) {
        return `You do not have manager access on this storage: '${selectedZone.name}'`;
      }
    } else {
      const selectedSite = resourcePathService.getSite(siteList, selectedSiteId);
      if (selectedSite && selectedSite.authority !== MANAGER) {
        return `You do not have manager access on this site: '${selectedSite.label}'`;
      }
    }

    return '';
  };

  const disableButton = () => {
    return (userIdList.length === 0 && !userIdInput) || !resourcePath || isLoading;
  };

  return (
    <div onClick={openModal} className={withMenuStyle ? styles.menu_item_content : styles.add_user_button} data-testid={'add-user-button'} >
      {children}

      <Dialog open={open} maxWidth='lg' onClose={handleClose}>
        <DialogTitle className={styles.dialogTitle}>
          <AddUsersIcon className={styles.icon} />
          <div className={styles.addUsersTitle}>Add users</div>
        </DialogTitle>
        <DialogContent className={styles.dialogContent}>
          <ResourcePathSelector value={resourcePath && resourcePathService.getHumanReadablePath(siteList, resourcePath)} onChange={handleResourcePathChange}></ResourcePathSelector>

          <div className={styles.usersSelectorContainer}>
            <InputLabel shrink className={styles.largerInputFieldLabel}>
              <div className={styles.labelWithTooltip}>
                <span>User name</span>
                <Tooltip
                  classes={{
                    tooltip: styles.tooltip,
                  }}
                  title='Type a name to find a user. If they are not yet known in SaaS, use their IGG.' >
                  <HelpIcon />
                </Tooltip>
              </div>
            </InputLabel>
            <UsersSelectorInput ref={usersSelectorInputRef} iggOnly onChange={handleUserIdListChange} onInputChange={handleUserIdInputChange} />
          </div>

          <Grid container className={styles.accessAttributionGrid} spacing={1} alignItems='center' direction='row' justifyContent='center'>
            <Grid item xs>
              <FormControl>
                <InputLabel shrink className={styles.inputFieldLabel}>
                  Status
                </InputLabel>
                <NativeSelect className={styles.inputField} value={status} onChange={handleStatusChange} input={<Input name='status' />}>
                  <option value='USER'>User</option>
                  <option value='MANAGER' className={styles.inputField}>
                    Manager
                  </option>
                </NativeSelect>
              </FormControl>
            </Grid>
            <Grid item xs>
              <DatePicker
                slots={{
                  openPickerIcon: DatePickerIcon,
                  leftArrowIcon: KeyBoardArrowLeft,
                  rightArrowIcon: KeyBoardArrowRight,
                }}
                onChange={handleDateChange}
                value={expirationDate}
                minDate={DateTime.local().plus({ days: 1 })}
                maxDate={DateTime.local().plus({ months: process.env.REACT_APP_MAX_RIGHTS_EXPIRATION_TIME_MONTH ? +process.env.REACT_APP_MAX_RIGHTS_EXPIRATION_TIME_MONTH : 6 })}
                format='yyyy/MM/dd'
                slotProps={{
                  textField: {
                    label: 'End access date',
                    classes: { root: styles.inputFieldDate },
                    InputLabelProps: { shrink: true, classes: { root: styles.inputFieldDateLabel } },
                    sx: {
                      boxShadow: 'none',
                      '.MuiOutlinedInput-notchedOutline': { border: 0 },
                      '.MuiOutlinedInput-input': { padding: '10px 0 10px' },
                    },
                  },
                }} />
            </Grid>
            <Grid item xs className={styles.machineCategory}>
              <FormControl>
                <InputLabel shrink className={styles.inputFieldLabel}>
                  <div className={styles.labelWithTooltip}>
                    <span>Machine category</span>
                    <Tooltip
                      classes={{
                        tooltip: styles.tooltip,
                      }}
                      title={
                        <>
                          {availableMachineTemplates &&
                            availableMachineTemplates.map((m) => (
                              <div>
                                {m.name}: {m.description}. Price = ${m.hourlyCost}/hour
                              </div>
                            ))}
                        </>
                      } >
                      <HelpIcon />
                    </Tooltip>
                  </div>
                </InputLabel>
                <NativeSelect className={styles.inputField} value={machineTemplateId} onChange={handleMachineTemplateChange} input={<Input name='machineCategory' />}>
                  {availableMachineTemplates &&
                    availableMachineTemplates.map((m) => (
                      <option value={m.id} key={m.id}>
                        {m.name.toUpperCase()}
                      </option>
                    ))}
                </NativeSelect>
                <InputLabel className={styles.tooltipContainer} />
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container alignItems='stretch' direction='row' justifyContent='flex-end' className={styles.accessAttributionButtonGrid}>
            <Grid item>
              <Button onClick={handleClose} className={styles.cancelButton}>
                Cancel
              </Button>
            </Grid>
            <Grid item>
              <Tooltip title={getAuthorityErrorMessage()} disableHoverListener={!getAuthorityErrorMessage()}>
                <div>
                  <Button onClick={createAccessControl} variant='contained' color='primary' className={styles.validationButton} disabled={disableButton()}>
                    {isLoading ? <Loader className={styles.loadericon} /> : <>VALIDATE</>}
                  </Button>
                </div>
              </Tooltip>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </div>
  );
};


export default connector(AddUsers);
