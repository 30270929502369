import React from 'react';

import { List, ListItem, ListItemAvatar, ListItemText } from '@mui/material';
import ExtensionIcon from '@mui/icons-material/Extension';

type Props = {
  smgInstalls: string[]
};

const VmInstallsList = ({ smgInstalls }: Props) => {
  const allInstalls = smgInstalls || [];

  return (
    <div className='vm-installs-list-root'>
      <div className='label-bold'>{allInstalls.length > 0 ? 'Version already installed :' : ''}</div>
      <div className='vm-installs-list-container'>
        <div className='vm-installs-list'>
          <List dense disablePadding>
            {allInstalls.map((install, index) => {
              const labelId = `checkbox-list-secondary-label-${install}`;
              return (
                <ListItem key={install} className={index % 2 !== 0 ? 'list-item-even' : ''}>
                  <ListItemAvatar>
                    <ExtensionIcon/>
                  </ListItemAvatar>
                  <ListItemText
                    id={labelId}
                    classes={{
                      primary: 'label-bold',
                    }}
                    primary={install}/>
                </ListItem>
              );
            })}
          </List>
        </div>

      </div>
    </div>
  );
};

export default VmInstallsList;
