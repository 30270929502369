import { AppThunk } from 'store/reducers';

export const REQUEST_NOTIFICATIONS = 'REQUEST_NOTIFICATIONS';

export type RequestNotificationsAction = {
  type: typeof REQUEST_NOTIFICATIONS
};

export const requestNotificationsAction = (): RequestNotificationsAction => ({ type: REQUEST_NOTIFICATIONS });

export const startPollingGetNotificationsAction = (): AppThunk<Promise<void>> => async dispatch => {
  dispatch(requestNotificationsAction());//set isFetching = true in redux state
};